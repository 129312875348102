export enum DeviceState {
    CREATED, ACTIVE, INACTIVE
}

function names(): Array<string> {
    const keys = Object.keys(DeviceState);
    return keys.slice(keys.length / 2, keys.length - 1);
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DeviceState {
    names();
}
