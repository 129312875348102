import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CasLoginService, Principal} from '@balrog/core';
import {
    faAsterisk,
    faBars,
    faBook,
    faClock,
    faEnvelope,
    faHeart,
    faHome,
    faList,
    faMailBulk,
    faMicrochip,
    faRoad,
    faSignOutAlt,
    faTachometerAlt,
    faTasks,
    faUser,
    faUserPlus,
    faWrench
} from '@fortawesome/free-solid-svg-icons';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiLanguageService} from 'ng-jhipster';

import {VERSION} from '../../app.constants';

import {ProfileService} from '../profiles/profile.service';

@Component({
    selector: 'jhi-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: [
        'navbar.scss'
    ]
})
export class NavbarComponent implements OnInit {
    faRoad = faRoad;
    faTachometerAlt = faTachometerAlt;
    faHeart = faHeart;
    faList = faList;
    faTasks = faTasks;
    faBook = faBook;
    faUserPlus = faUserPlus;
    faUser = faUser;
    faWrench = faWrench;
    faClock = faClock;
    faEnvelope = faEnvelope;
    faAsterisk = faAsterisk;
    faSignOutAlt = faSignOutAlt;
    faHome = faHome;
    faMicrochip = faMicrochip;
    faBars = faBars;
    faMailBulk = faMailBulk;

    isNavbarCollapsed: boolean;
    modalRef: NgbModalRef;
    version: string;

    constructor(
        private loginService: CasLoginService,
        private languageService: JhiLanguageService,
        private principal: Principal,
        private profileService: ProfileService,
        private router: Router
    ) {
        this.version = VERSION ? 'v' + VERSION : '';
        this.isNavbarCollapsed = true;
    }

    ngOnInit() {
    }

    changeLanguage(languageKey: string) {
      this.languageService.changeLanguage(languageKey);
    }

    collapseNavbar() {
        this.isNavbarCollapsed = true;
    }

    reindex() {
        this.profileService.reindex();
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    logout() {
        this.collapseNavbar();
        this.loginService.logout();
        const subscription = this.principal.getAuthenticationState().subscribe(() => {
            this.router.navigate(['']).then(() => subscription.unsubscribe());
        });
    }

    toggleNavbar() {
        this.isNavbarCollapsed = !this.isNavbarCollapsed;
    }
}
