import {Component, Input} from '@angular/core';

import {StatisticsInterface} from '../statistics.service';
import {faMicrochip} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-statistics-devices',
    templateUrl: './statistics-devices.component.html',
    styleUrls: ['./statistics-devices.scss']
})
export class StatisticsDevicesComponent {
    faMicrochip = faMicrochip;

    @Input() stats: StatisticsInterface;

    getValue(key) {
        if (!this.stats || (!this.stats[key] && this.stats[key] !== 0)) {
            return '-';
        }
        return this.stats[key];
    }

}
