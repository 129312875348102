import {HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceAccessService} from '@balrog/core';
import {faPlus, faTimes, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs';
import {DeviceAccessDialogComponent} from '../../entities/device-access/device-access-dialog.component';
import {DeviceAccessPopupService} from '../../entities/device-access/device-access-popup.service';
import {DeviceAccess} from '../../entities/device-access/device-access.model';
import {AccessPermissionService} from '../../shared/auth/access-permission.service';
import {UserService} from '../../shared/user/user.service';
import {UserModalService} from './user-modal.service';

@Component({
    selector: 'jhi-user-management-devices',
    templateUrl: './user-management-devices.component.html'
})
export class UserManagementDevicesComponent implements OnInit, OnDestroy {
    faTimes = faTimes;
    faPlus = faPlus;
    faClose = faWindowClose;

    userId: number;
    deviceAccesses: any;
    accessPermissions: any;
    routeSub: any;
    eventSubscriber: Subscription;

    tableHeadConfig = {
        deviceName: {
            translation: 'userManagement.devices.deviceName'
        },
        deviceSerialNo: {
            translation: 'userManagement.devices.deviceSerialNo'
        },
        userEmail: {
            translation: 'userManagement.devices.userEmail'
        },
        accessLevel: {
            translation: 'userManagement.devices.accessLevel'
        }
    };

    constructor(
        public activeModal: NgbActiveModal,
        private accessPermissionService: AccessPermissionService,
        private userService: UserService,
        private deviceAccessService: DeviceAccessService,
        private deviceAccessPopupService: DeviceAccessPopupService,
        private eventManager: JhiEventManager,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.updateAccessPermissionsList();
    }

    ngOnDestroy() {
        if (this.eventSubscriber) {
            this.eventSubscriber.unsubscribe();
        }
    }

    updateList() {
        this.userService.getDeviceAccessesByUserId(this.userId).subscribe((res: HttpResponse<DeviceAccess[]>) => {
            this.deviceAccesses = res.body;
        })
    }

    updateAccessPermissionsList() {
        this.accessPermissionService.getAll().subscribe((accessPermissions) => {
            this.accessPermissions = accessPermissions;
        });
    }

    updateAccessLevel($event, deviceAccess) {
        deviceAccess.accessLevel = $event.target.value;
        this.deviceAccessService.update(deviceAccess).subscribe();
    }

    addNew() {
        const modalRef = this.deviceAccessPopupService.deviceAccessModalRef(DeviceAccessDialogComponent as Component, {
            userId: this.userId
        });
        const userField = modalRef.componentInstance.form.nativeElement.querySelector('#field_user');
        // Prevent user from changing the selected device.
        userField.setAttribute('readonly', 'readonly');
        userField.querySelector('input').tabIndex = -1; // prevent from change with TAB

        modalRef.result.then((result) => {
            this.updateList();
        }, (reason) => {
            if (reason !== 'cancel') {
                this.updateList();
            }
        });
    }

    deleteAccess(deviceAccess) {
        if (this.eventSubscriber) {
            this.eventSubscriber.unsubscribe();
        }

        this.eventSubscriber = this.eventManager.subscribe('deviceAccessListModification', (response) => {
            this.updateList();
        });

        this.router.navigate(['/', { outlets: { popup: 'device-access/' + deviceAccess.id + '/delete'} }])
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    navigateToDevice(deviceId) {
        /* Workaround for not working outlets while using routerlink directive */
        this.router.navigate(['/device', deviceId]);
        setTimeout(() => {
            this.clear();
        }, 0);
    }
}

@Component({
    selector: 'jhi-user-management-devices-popup',
    template: ''
})
export class UserManagementDevicesPopupComponent implements OnInit, OnDestroy {

    routeSub: any;
    routeData: any;

    constructor(
        private route: ActivatedRoute,
        private userModalService: UserModalService
    ) {}

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.routeData = this.route.data.subscribe((data) => {
                const modalRefPromise = this.userModalService.open(UserManagementDevicesComponent as Component);
                modalRefPromise.then((modalRef) => {
                    modalRef.componentInstance.userId = Number(params.id);
                    modalRef.componentInstance.deviceAccesses = data.accessesHttp.body;
                });
            });
        });

    }

    ngOnDestroy() {
        this.routeData.unsubscribe();
    }
}
