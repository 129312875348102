import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';

import {DeviceType} from './device-type.model';
import {DeviceTypePopupService} from './device-type-popup.service';
import {DeviceTypeService} from './device-type.service';
import {faBan, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-device-type-delete-dialog',
    templateUrl: './device-type-delete-dialog.component.html'
})
export class DeviceTypeDeleteDialogComponent {
    faTimes = faTimes;
    faBan = faBan;
    deviceType: DeviceType;

    constructor(
        private deviceTypeService: DeviceTypeService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.deviceTypeService.delete(id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'deviceTypeListModification',
                content: 'Deleted an deviceType'
            });
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'jhi-device-type-delete-popup',
    template: ''
})
export class DeviceTypeDeletePopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private deviceTypePopupService: DeviceTypePopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.deviceTypePopupService
                .open(DeviceTypeDeleteDialogComponent as Component, params['id']);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
