import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';

import {Device} from './device.model';
import {DevicePopupService} from './device-popup.service';
import {DeviceService} from './device.service';
import {faBan, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-device-delete-dialog',
    templateUrl: './device-delete-dialog.component.html'
})
export class DeviceDeleteDialogComponent {
    faBan = faBan;
    faTimes = faTimes;

    device: Device;
    force = false;
    error = false;

    constructor(
        private deviceService: DeviceService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.deviceService.delete(id, this.force).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'deviceListModification',
                content: 'Deleted an device'
            });
            this.activeModal.dismiss(true);
        }, () => {
            this.error = true;
        });
    }
}

@Component({
    selector: 'jhi-device-delete-popup',
    template: ''
})
export class DeviceDeletePopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private devicePopupService: DevicePopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.devicePopupService
                .open(DeviceDeleteDialogComponent as Component, params['id']);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
