import {Component, Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NotificationModel, NotificationService} from 'app/management';
import {NotificationStatusChangeDialogComponent} from 'app/management/notification/components/notification-status-change-dialog.component';

@Injectable()
export class NotificationPopupService {
    private ngbModalRef: NgbModalRef;

    constructor(
        private modalService: NgbModal,
        private notificationService: NotificationService

    ) {
        this.ngbModalRef = null;
    }

    openChangeStatusPopup(notification: NotificationModel): Promise<NgbModalRef>  {
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null;
            if (isOpen) {
                resolve(this.ngbModalRef);
            }
            this.ngbModalRef = this.modalService.open(NotificationStatusChangeDialogComponent as Component, { size: 'sm', backdrop: 'static'});
            this.ngbModalRef.componentInstance.notification = notification;
            this.ngbModalRef.result.then((result) => {
                this.ngbModalRef = null;
            }, (reason) => {
                this.ngbModalRef = null;
            });
            resolve(this.ngbModalRef);
        });
    }
}
