import {Component, OnInit} from '@angular/core';
import {Principal} from '@balrog/core';
import {User} from '@balrog/core/model';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'jhi-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: [
        'welcome.scss'
    ]

})
export class WelcomeComponent implements OnInit {
    account: User;
    modalRef: NgbModalRef;

    constructor(
        private principal: Principal
    ) {}

    ngOnInit() {
        this.principal.identity().then((account) => {
            this.account = account;
        });
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }
}
