import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {interval as observableInterval, Observable} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';

import {CORE_CONFIG, CoreConfig} from '../core.config';
import {CORE_API_URL} from '../core.constants';
import {Device, DeviceAccess, DeviceDiagnostic, DeviceLog, DeviceStatus} from '../model/device';
import {createRequestOption} from '../utils/request-util';

type EntityResponseType = HttpResponse<Device>;
type EntityArrayResponseType = HttpResponse<Device[]>;
type DeviceAccessArrayResponseType = HttpResponse<DeviceAccess[]>;

@Injectable()
export class DeviceService {
  private readonly coreApiUrl: string = CORE_API_URL;
  private DEVICE_ACCESS_RESOURCE_URL = this.coreApiUrl + '/v1/device-accesses';

  private resourceUrl = this.coreApiUrl + '/v1/devices';
  private eventsResourceUrl = this.coreApiUrl + '/v1/events';

  constructor(
    private http: HttpClient,
    @Optional() @Inject(CORE_CONFIG) config: CoreConfig,
  ) {
    if (config) {
      this.coreApiUrl = config.coreApiUrl;
    }
  }

  create(device: Device): Observable<EntityResponseType> {
    const copy = this.convert(device);
    return this.http.post(this.resourceUrl, copy, {observe: 'response'});
  }

  update(device: Device): Observable<EntityResponseType> {
    const copy = this.convert(device);
    return this.http.put(this.resourceUrl, copy, {observe: 'response'});
  }

  deviceStatus(serialNo: string): Observable<DeviceStatus> {
    return observableInterval(5000).pipe(
      startWith(0),
      switchMap(() => this.http.get<DeviceStatus>(this.resourceUrl + '/status/' + serialNo)),
      map((response: DeviceStatus) => {
        if (!response) {
          return new DeviceStatus('CONF_ERROR', null, null);
        }
        if (!response.status) {
          response.status = 'CONF_ERROR';
        }
        return response;
      })
    );
  }

  deviceFirmwareUpdate(device: Device, dev: boolean): Observable<EntityResponseType> {
    if (dev) {
      return this.http.get(`${this.resourceUrl}/${device.serialNo}/firmware/update?build=dev`, {observe: 'response'});
    } else {
      return this.http.get(`${this.resourceUrl}/${device.serialNo}/firmware/update?build=stable`, {observe: 'response'});
    }
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  getUserAccessesByDeviceId(id: number): Observable<DeviceAccessArrayResponseType> {
    return this.http.get<DeviceAccess[]>(`${this.DEVICE_ACCESS_RESOURCE_URL}?deviceId=${id}`, {observe: 'response'});
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<Device[]>(this.resourceUrl, {params: options, observe: 'response'});
  }

  delete(id: number, force: boolean): Observable<any> {
    let queryString = '';
    if (force) {
      queryString = '?force=true';
    }
    return this.http.delete(`${this.resourceUrl}/${id}${queryString}`);
  }

  getDeviceLogs(serialNo: string): Observable<HttpResponse<DeviceLog[]>> {
    return this.http.get<DeviceLog[]>(`${this.eventsResourceUrl}/${serialNo}`, {observe: 'response'});
  }

  getDeviceDiagnostic(serialNo: string): Observable<HttpResponse<DeviceDiagnostic>> {
    return this.http.get<DeviceDiagnostic>(`${this.resourceUrl}/${serialNo}/diagnose`, {observe: 'response'});
  }

  /**
   * Convert a Device to a JSON which can be sent to the server.
   */
  private convert(device: Device): Device {
    return Object.assign({}, device);
  }
}
