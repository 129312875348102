import {forkJoin as observableForkJoin} from 'rxjs';

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export const ACCESS_PERMISSIONS = [10, 20, 30, 40, 50, 60];

@Injectable()
export class AccessPermissionService {
    constructor(private translateService: TranslateService) {}

    getAll() {
        const translations = ACCESS_PERMISSIONS.map((permission) => this.translateService.get(`accessPermission.${permission}`))

        return observableForkJoin(translations).pipe(map(
                (t) => ACCESS_PERMISSIONS.map((access, idx) => ({
                    access,
                    name: t[idx]
                }))
        ));
    }
}
