import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgJhipsterModule} from 'ng-jhipster';
import {CookieModule} from 'ngx-cookie';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbAlertModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [
        NgJhipsterModule.forRoot({
            // set below to true to make alerts look like toast
            alertAsToast: false,
            i18nEnabled: true,
            defaultI18nLang: 'pl'
        }),
        CookieModule.forRoot()
    ],
    exports: [
        FormsModule,
        CommonModule,
        NgJhipsterModule,
        NgSelectModule,
        NgxDatatableModule,
        NgbAlertModule,
        NgbTooltipModule,
        TranslateModule,
        FontAwesomeModule
    ]
})
export class BalrogadminSharedLibsModule {}
