import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataService, DeviceSchemaService} from '@balrog/core';
import {DataPackage} from '@balrog/core/model/data';
import {Device, DeviceSchema} from '@balrog/core/model/device';
import {ListboardConfigInterface, ProfileService} from '@balrog/profile';
import {Observable} from 'rxjs';
import {skip} from 'rxjs/operators';

@Component({
    selector: 'jhi-list-configuration-tab',
    templateUrl: './list-configuration-tab.component.html'
})
export class ListConfigurationTabComponent implements OnInit, OnDestroy {
    @Input() device: Device;

    public data$: Observable<DataPackage>;
    public schema: DeviceSchema;
    public config: ListboardConfigInterface;

    constructor(
        private dataService: DataService,
        private deviceSchemaService: DeviceSchemaService,
        private profileService: ProfileService
    ) {
    }

    ngOnInit(): void {
        if (this.device) {
            this.dataService.stopDataPulling();
            this.dataService.startDataPulling(this.device.serialNo);
        }
        this.data$ = this.dataService.data$.pipe(skip(1));
        this.deviceSchemaService.getForDevice(this.device.serialNo).pipe(
        ).subscribe((schema) => {
                this.schema = schema;
                console.warn(this.device);

                this.config = this.profileService.listBoard(this.schema, 100); // TODO change that access level to user one
            });
    }

    ngOnDestroy(): void {
        this.dataService.stopDataPulling();
    }

}
