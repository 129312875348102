import {Component, OnInit} from '@angular/core';

import {NotificationService} from './notification.service';

@Component({
    selector: 'jhi-management-notification',
    templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {

    constructor(
        private notificationService: NotificationService
    ) {
    }


    ngOnInit() {
    }
}
