import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DeviceAccessService} from '@balrog/core';
import {faBan, faSave} from '@fortawesome/free-solid-svg-icons';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';

import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators'
import {AccessPermissionService, User, UserService} from '../../shared';
import {ITEMS_PER_PAGE} from '../../shared/constants/pagination.constants';
import {Device, DeviceService} from '../device';
import {DeviceAccessPopupService} from './device-access-popup.service';

import {DeviceAccess} from './device-access.model';

@Component({
    selector: 'jhi-device-access-dialog',
    templateUrl: './device-access-dialog.component.html'
})
export class DeviceAccessDialogComponent implements OnInit {
    faBan = faBan;
    faSave = faSave;
    @ViewChild('form', {static: false}) form: ElementRef;

    deviceAccess: DeviceAccess;
    isSaving: boolean;

    users: User[];

    devices: Device[];
    accessPermissions: any;

    userField: any = {
        isLoading: false,
        isSearching: false,
        page: 0,
        totalItems: null,
        itemsPerPage: ITEMS_PER_PAGE,
        typeaheadSubject: new Subject<string>()
    };

    deviceField: any = {
        isLoading: false,
        isSearching: false,
        page: 0,
        totalItems: null,
        itemsPerPage: ITEMS_PER_PAGE,
        typeaheadSubject: new Subject<string>()
    };

    constructor(
        public activeModal: NgbActiveModal,
        private jhiAlertService: JhiAlertService,
        private deviceAccessService: DeviceAccessService,
        private userService: UserService,
        private deviceService: DeviceService,
        private eventManager: JhiEventManager,
        private accessPermissionService: AccessPermissionService
    ) {}

    ngOnInit() {
        this.isSaving = false;
        this.userService
            .query()
            .subscribe((res: HttpResponse<User[]>) => {
                if (!this.deviceAccess.userId) {
                    this.users = res.body;
                } else {
                    this.userService
                        .find(this.deviceAccess.userId)
                        .subscribe((subRes: HttpResponse<User>) => {
                            this.users = [subRes.body].concat(res.body);
                            this.userField.totalItems = res.headers.get('X-Total-Count');
                        }, (subRes: HttpErrorResponse) => this.onError(subRes));
                }
            }, (res: HttpErrorResponse) => this.onError(res));
        this.deviceService.query()
            .subscribe((res: HttpResponse<any>) => {
                this.devices = res.body;
                this.deviceField.totalItems = res.headers.get('X-Total-Count');
            }, (res: HttpErrorResponse) => this.onError(res));

        this.accessPermissionService.getAll().subscribe((accessPermissions) => {
            this.accessPermissions = accessPermissions;
        });

        this.deviceTypeaheadLoad();
        this.userTypeaheadLoad();
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    userFetchMore() {
        if (this.userField.totalItems <= this.users.length || this.userField.isSearching) {
            return;
        }

        this.userField.isLoading = true;
        this.userField.page++;
        this.userService.query({
            page: this.userField.page,
            size: this.userField.itemsPerPage
        }).subscribe(
            (res: HttpResponse<User[]>) => {
                this.users = [...this.users, ...res.body];
                this.userField.isLoading = false;
            },
            (res: HttpErrorResponse) => this.onError(res)
        );
    }

    deviceFetchMore() {
        if (this.deviceField.totalItems <= this.devices.length || this.deviceField.isSearching) {
            return;
        }

        this.deviceField.isLoading = true;
        this.deviceField.page++;
        this.deviceService.query({
            page: this.deviceField.page,
            size: this.userField.itemsPerPage
        }).subscribe(
            (res: HttpResponse<Device[]>) => {
                this.devices = [...this.devices, ...res.body];
                this.deviceField.isLoading = false;
            },
            (res: HttpErrorResponse) => this.onError(res)
        );
    }

    userTypeaheadLoad() {
        this.userField.typeaheadSubject.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => {
                this.userField.isLoading = true;
            }),
            switchMap((term) => {
                if (term) {
                    this.userField.isSearching = true;
                    return this.userService.query({
                        query: term
                    });
                } else {
                    this.userField.isSearching = false;
                    return this.userService.query();
                }
            })
        ).subscribe((res) => {
            this.users = res.body;
            this.userField.isLoading = false;
            this.userField.page = 0;
        })
    }

    deviceTypeaheadLoad() {
        this.deviceField.typeaheadSubject.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => {
                this.deviceField.isLoading = true;
            }),
            switchMap((term) => {
                if (term) {
                    this.deviceField.isSearching = true;
                    return this.deviceService.query({
                        query: term
                    });
                } else {
                    this.deviceField.isSearching = false;
                    return this.deviceService.query();
                }
            })
        ).subscribe((res) => {
            this.devices = res.body;
            this.deviceField.isLoading = false;
            this.deviceField.page = 0;
        })
    }

    save() {
        this.isSaving = true;
        if (this.deviceAccess.id !== undefined) {
            this.subscribeToSaveResponse(
                this.deviceAccessService.update(this.deviceAccess));
        } else {
            this.subscribeToSaveResponse(
                this.deviceAccessService.create(this.deviceAccess));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<DeviceAccess>>) {
        result.subscribe((res: HttpResponse<DeviceAccess>) =>
            this.onSaveSuccess(res.body), (res: HttpResponse<any>) => this.onSaveError());
    }

    private onSaveSuccess(result: DeviceAccess) {
        this.eventManager.broadcast({ name: 'deviceAccessListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError() {
        this.isSaving = false;
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null);
    }
// // TODO approve
//     getSelected(selectedVal: number, option: any) {
//         if (option.id === selectedVal.id) {
//             return selectedVal;
//         }
//         return option;
//     }

    trackUserById(index: number, item: User) {
        return item.id;
    }

    trackDeviceById(index: number, item: Device) {
        return item.id;
    }
}

@Component({
    selector: 'jhi-device-access-popup',
    template: ''
})
export class DeviceAccessPopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private deviceAccessPopupService: DeviceAccessPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if ( params['id'] ) {
                this.deviceAccessPopupService
                    .open(DeviceAccessDialogComponent as Component, params['id']);
            } else {
                this.deviceAccessPopupService
                    .open(DeviceAccessDialogComponent as Component);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
