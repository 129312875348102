import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BalrogadminSharedModule } from '../../shared';
import {
    DeviceSchemaService,
    DeviceSchemaPopupService,
    DeviceSchemaComponent,
    DeviceSchemaDetailComponent,
    DeviceSchemaDialogComponent,
    DeviceSchemaPopupComponent,
    DeviceSchemaDeletePopupComponent,
    DeviceSchemaDeleteDialogComponent,
    deviceSchemaRoute,
    deviceSchemaPopupRoute,
    DeviceSchemaResolvePagingParams,
} from './';
import {NgxTableTextFieldComponent} from './ngx-table-text-field.component';
import {DeviceSchemaAdapterDialogComponent} from './device-schema-adapter-dialog.component';
import {ParameterListModule} from '../../partial-components/parameter-list/parameter-list.module';
import {NgxTableAccessFieldComponent} from './ngx-table-access-field.component';

const ENTITY_STATES = [
    ...deviceSchemaRoute,
    ...deviceSchemaPopupRoute,
];

@NgModule({
    imports: [
        BalrogadminSharedModule,
        ParameterListModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        DeviceSchemaComponent,
        DeviceSchemaDetailComponent,
        DeviceSchemaDialogComponent,
        DeviceSchemaDeleteDialogComponent,
        DeviceSchemaPopupComponent,
        DeviceSchemaDeletePopupComponent,
        NgxTableTextFieldComponent,
        NgxTableAccessFieldComponent,
        DeviceSchemaAdapterDialogComponent,
    ],
    entryComponents: [
        DeviceSchemaComponent,
        DeviceSchemaDialogComponent,
        DeviceSchemaPopupComponent,
        DeviceSchemaDeleteDialogComponent,
        DeviceSchemaDeletePopupComponent,
        NgxTableTextFieldComponent,
        NgxTableAccessFieldComponent,
        DeviceSchemaAdapterDialogComponent,
    ],
    providers: [
        DeviceSchemaService,
        DeviceSchemaPopupService,
        DeviceSchemaResolvePagingParams,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BalrogadminDeviceSchemaModule {}
