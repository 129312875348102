import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CityService} from '@balrog/core/api/city.service';
import {City} from '@balrog/core/model';

import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'jhi-city-select',
    template: `<ng-select id="field_city"
                          class="custom"
                          [items]="cities"
                          name="city"
                          bindLabel="name"
                          bindValue="id"
                          clearable="false"
                          [loading]="cityField.isLoading"
                          [(ngModel)]="selectedId"
                          [placeholder]="placeholder"
                          (change)="handleCityChanged($event)"
                          [typeahead]="cityField.typeaheadSubject">
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
            {{item.name}} ({{item.regionName}})
        </ng-template>
    </ng-select>`,
    styleUrls: []
})
export class CitySelectComponent implements OnInit {
    private ITEMS_PER_PAGE: 20;

    @Input()
    set value(city: City) {
        if (city) {
            this.cities = [city];
            this.selectedId = city.id;
        }
    }
    @Input() placeholder: string;
    @Output() onChange = new EventEmitter<City>();

    cities: City[] = [];

    selectedId: number;

    cityField = {
        isLoading: false,
        isSearching: false,
        page: 0,
        totalItems: null,
        itemsPerPage: this.ITEMS_PER_PAGE,
        typeaheadSubject: new Subject<string>()
    };

    constructor(
        private cityService: CityService
    ) {
    }

    ngOnInit() {
        this.cityTypeaheadLoad();
    }

    cityTypeaheadLoad() {
        this.cityField.typeaheadSubject.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => {
                this.cityField.isLoading = true;
            }),
            switchMap((term) => {
                if (term) {
                    this.cityField.isSearching = true;
                    return this.cityService.search({
                        name: term
                    });
                } else {
                    this.cityField.isSearching = false;
                    return this.cityService.search();
                }
            })
        ).subscribe((res) => {
            this.cities = res.body.data;
            this.cityField.isLoading = false;
            this.cityField.page = 0;
        })
    }

    handleCityChanged(city: City) {
        this.onChange.next(city);
    }
}
