import {HttpResponse} from '@angular/common/http';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {faArrowLeft, faDownload, faMinus, faPencilAlt, faPlus, faUpload} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs';
import {DeviceSchemaAdapterDialogComponent} from './device-schema-adapter-dialog.component';
import {DeviceSchemaField} from './device-schema-field.model';

import {DeviceSchema} from './device-schema.model';
import {DeviceSchemaService} from './device-schema.service';

@Component({
    selector: 'jhi-device-schema-detail',
    templateUrl: './device-schema-detail.component.html'
})
export class DeviceSchemaDetailComponent implements OnInit, OnDestroy {

    faPencilAlt = faPencilAlt;
    faArrowLeft = faArrowLeft;
    faMinus = faMinus;
    faPlus = faPlus;
    faUpload = faUpload;
    faDownload = faDownload;

    @ViewChild('ngxtable', {static: false}) ngxtable: any;

    deviceSchema: DeviceSchema;
    private subscription: Subscription;
    private eventSubscriber: Subscription;

    columns = [
        { name: 'Parametr' , prop: 'name' },
        { name: 'Nazwa ', prop: 'displayName' },
        { name: 'accessPermission' },
        { name: 'description' },
        { name: 'option' },
        { name: 'lowAlertLevel' },
        { name: 'highAlertLevel' },
        { name: 'min' },
        { name: 'max' },
        { name: 'adapter' },
        { name: 'Company' }
    ];

    constructor(
        private eventManager: JhiEventManager,
        private deviceSchemaService: DeviceSchemaService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private elRef: ElementRef,
        private alertService: JhiAlertService
    ) {
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        this.registerChangeInDeviceSchemas();
    }

    load(id) {
        this.deviceSchemaService.find(id).subscribe((res: HttpResponse<DeviceSchema>) => {
            this.deviceSchema = res.body;
        });
    }
    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInDeviceSchemas() {
        this.eventSubscriber = this.eventManager.subscribe(
            'deviceSchemaListModification',
            (response) => this.load(this.deviceSchema.id)
        );
    }

    addRow() {
        const rows = this.deviceSchema.fields.splice(0);
        rows.push(new DeviceSchemaField());
        this.deviceSchema.fields = rows;
    }

    updateValue(value, cell, rowIndex) {
        this.deviceSchema.fields[rowIndex][cell] = value ? value : null;
        this.deviceSchema.fields = [...this.deviceSchema.fields];
    }

    save() {
        if (this.validate()) {
            this.deviceSchemaService.update(this.deviceSchema).subscribe(() => {
                this.eventManager.broadcast({ name: 'deviceSchemaListModification', content: 'OK'});
            });
        }
    }

    private validate(): boolean {
        const nameList = this.deviceSchema.fields.map(field => field.name);
        const duplicatedNames = nameList.filter((name, index) => nameList.indexOf(name) !== index );
        if (duplicatedNames.length) {
            this.alertService.error('Duplicate name for schema', duplicatedNames.join(','), null);
            return false;
        }
        return true;
    }

    import(target) {
        const file: File = target.files[0];
        const myReader: FileReader = new FileReader();
        myReader.onloadend = () => {
            const schema = JSON.parse(myReader.result as string);
            this.deviceSchema.fields = schema.fields;
        };
        myReader.readAsText(file);
    }

    export() {
        this.download(this.deviceSchema, this.deviceSchema.name + '.json', 'text/plain');
    }

    download(content, fileName, contentType) {
        const a = document.createElement('a');
        const file = new Blob([JSON.stringify(content, (key, value) => { if (value !== null) {return value}}, ' ')], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    editAdapter(rowIndex) {
        // hack for https://github.com/ng-bootstrap/ng-bootstrap/issues/1604
        const renderElement = this.elRef.nativeElement.ownerDocument.activeElement;
        renderElement['blur'].apply(renderElement)

        const modalRef = this.modalService.open(DeviceSchemaAdapterDialogComponent, { size: 'lg', backdrop: 'static'});
        modalRef.componentInstance.adapter = this.deviceSchema.fields[rowIndex].adapter;
        modalRef.result.then((result) => {
            this.deviceSchema.fields[rowIndex].adapter = result ? result : null;
            this.deviceSchema.fields = [...this.deviceSchema.fields];
        }, (reason) => {
        });
        return modalRef;
    }

    removeRow(rowIndex) {
        const rows = this.deviceSchema.fields.splice(0);
        rows.splice(rowIndex, 1);
        this.deviceSchema.fields = rows;
    }

    prepareTitle(obj) {
        if (!obj) {
            return '_'
        }
        const values = obj.values ? Object.keys(obj.values).map((key) => obj.values[key]) : [];
        const options = obj.options ? Object.keys(obj.options).map((key) => obj.options[key]) : [];
        return obj.name + '(' + values.join()  + '|' + options.join() + ')';
    }
}
