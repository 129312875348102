import {Organization} from 'app/entities/organization';

export enum TargetType {
    DEVICE='DEVICE',
    DEVICE_TYPE='DEVICE_TYPE'
}

export enum NotificationType {
    ONCE='ONCE',
    PERIODICALLY='PERIODICALLY'
}

export enum NotificationStatus {
    CREATED='CREATED',
    ACTIVE='ACTIVE',
    INACTIVE='INACTIVE',
    DELETED='DELETED',
    ERROR='ERROR',
    FINISHED='FINISHED'
}

export class NotificationModel {
    constructor(
        public id?: string,
        public name?: string,
        public status?: NotificationStatus,
        public type?: NotificationType,
        public targetType?: TargetType,
        public target?: string,
        public startDate?: any,
        public endDate?: any,
        public runDate?: any,
        public period?: string,
        public runTime?: string,
        public runCondition?: string,
        public errorMsg?: string,

        public createdDate?: any,
        public createdBy?: string,
        public modifiedDate?: any,
        public modifiedBy?: string,
        public organization?: Organization,

        public emailEnabled?: boolean,
        public emailContent?: string,
        public smsEnabled?: boolean,
        public smsContent?: string,
        public pushEnabled?: boolean,
        public pushContent?: string
    ) {
    }
}
