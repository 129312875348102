import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '@balrog/core/api';
import {Setpoint} from '@balrog/core/model/data';
import {InteractiveButtonConfigurationInterface} from './interactive-button-configuration.interface';
import {InteractiveButtonInterface} from './interactive-button.interface';

@Component({
    // eslint-disable-next-line @typescript-eslint/tslint/config
    selector: 'blg-interactive-button',
    // templateUrl: 'interactive-button.component.html',
    template: `<button class="mini-btn" (click)="onClick()" [ngClass]="config ? config.class : '' + ' ' + class">
    <span class="text">{{title}}</span>
</button>`
})
export class InteractiveButtonComponent implements OnInit {
    @Input()
    config?: InteractiveButtonConfigurationInterface;

    state?: string;
    title?: string;
    class?: string;

    constructor(private harryService: DataService) {

    }

    ngOnInit(): void {
        this.title = this.config?.defaultTitle;
        if (this.config?.init) {
            this.config.init(this.createEvent());
        }
    }

    onClick() {
        if (this.state && this.isStateCorrect()) {
            this.config?.diagramState[this.state].onClick(this.createEvent());
        }
    }

    private isStateCorrect(): boolean {
        return !!(this.state && this.config?.diagramState[this.state]);
    }

    private changeState(state: string) {
        this.state = state;
        this.title = this.isStateCorrect() ? this.config?.diagramState[this.state].title : '---err---';
        this.class = this.isStateCorrect() ? this.config?.diagramState[this.state].class : '';
    }

    private createEvent(): InteractiveButtonInterface {
        return {
            data$: this.harryService.data$,
            setState: (state: string) => {this.changeState(state); },
            setTitle: (title: string) => {this.title = title; },
            setClass: (styleClass: string) => {this.class = styleClass; },
            sendConfiguration(setpoints: Setpoint[]) {
                // this.harryService.sendConfigurationForCurrent(change);
            }
        };
    }
}
