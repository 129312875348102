import {ModuleWithProviders, NgModule} from '@angular/core';
import {AccountService, CasLoginService, DataService, DeviceAccessService, Principal} from '@balrog/core';
import {UserService} from '@balrog/core/api/user.service';
import {DeviceSchemaService, DeviceService, EventService} from './api';
import {CORE_CONFIG, CoreConfig} from './core.config';
import {WINDOW_PROVIDERS} from './internal/window.provider';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [
        WINDOW_PROVIDERS
    ]
})
export class CoreModule {
    static forRoot(config: CoreConfig): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                {provide: CORE_CONFIG, useValue: config},
                CasLoginService,
                AccountService,
                UserService,
                Principal,
                DataService,
                EventService,
                DeviceService,
                DeviceAccessService,
                DeviceSchemaService
            ]
        };
    }
}
