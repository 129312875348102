import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';

import {DeviceType} from './device-type.model';
import {DeviceTypePopupService} from './device-type-popup.service';
import {DeviceTypeService} from './device-type.service';
import {DeviceSchema, DeviceSchemaService} from '../device-schema';
import {Group, GroupService} from '../group';
import {Organization} from '../organization/organization.model';
import {OrganizationService} from '../organization/organization.service';
import {faBan, faSave} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-device-type-dialog',
    templateUrl: './device-type-dialog.component.html'
})
export class DeviceTypeDialogComponent implements OnInit {
    faBan = faBan;
    faSave = faSave;

    deviceType: DeviceType;
    isSaving: boolean;

    deviceschemas: DeviceSchema[];

    balroggroups: Group[];

    organizations: Organization[];

    constructor(
        public activeModal: NgbActiveModal,
        private jhiAlertService: JhiAlertService,
        private deviceTypeService: DeviceTypeService,
        private deviceSchemaService: DeviceSchemaService,
        private organizationService: OrganizationService,
        private groupService: GroupService,
        private eventManager: JhiEventManager
    ) {
    }

    ngOnInit() {
        this.isSaving = false;
        this.deviceSchemaService.query({size:50})
            .subscribe((res: HttpResponse<DeviceSchema[]>) => { this.deviceschemas = res.body; }, (res: HttpErrorResponse) => this.onError(res));
        this.groupService.query()
            .subscribe((res: HttpResponse<Group[]>) => { this.balroggroups = res.body; }, (res: HttpErrorResponse) => this.onError(res));
        this.organizationService.query()
            .subscribe((res: HttpResponse<Organization[]>) => { this.organizations = res.body; }, (res: HttpErrorResponse) => this.onError(res));
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        if (this.deviceType.id !== undefined) {
            this.subscribeToSaveResponse(
                this.deviceTypeService.update(this.deviceType));
        } else {
            this.subscribeToSaveResponse(
                this.deviceTypeService.create(this.deviceType));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<DeviceType>>) {
        result.subscribe((res: HttpResponse<DeviceType>) =>
            this.onSaveSuccess(res.body), (res: HttpErrorResponse) => this.onSaveError());
    }

    private onSaveSuccess(result: DeviceType) {
        this.eventManager.broadcast({ name: 'deviceTypeListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError() {
        this.isSaving = false;
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null);
    }

    trackDeviceSchemaById(index: number, item: DeviceSchema) {
        return item.id;
    }

    trackGroupById(index: number, item: Group) {
        return item.id;
    }

    trackOrganizationById(index: number, item: Organization) {
        return item.id;
    }

    getSelected(selectedVals: Array<any>, option: any) {
        if (selectedVals) {
            for (let i = 0; i < selectedVals.length; i++) {
                if (option.id === selectedVals[i].id) {
                    return selectedVals[i];
                }
            }
        }
        return option;
    }
}

@Component({
    selector: 'jhi-device-type-popup',
    template: ''
})
export class DeviceTypePopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private deviceTypePopupService: DeviceTypePopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if ( params['id'] ) {
                this.deviceTypePopupService
                    .open(DeviceTypeDialogComponent as Component, params['id']);
            } else {
                this.deviceTypePopupService
                    .open(DeviceTypeDialogComponent as Component);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
