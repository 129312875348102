import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {createRequestOption, DEVICE_ACCESS_RESOURCE_URL} from '@balrog/core';
import {CORE_API_URL} from '@balrog/core/core.constants';
import {Observable, of as observableOf} from 'rxjs';
import {User} from '../model';
import {DeviceAccess} from '../model/admin';
import {AUTHORITIES} from '../model/const';

type EntityResponseType = HttpResponse<User>;
type EntityArrayResponseType = HttpResponse<User[]>;
type DeviceAccessArrayResponseType = HttpResponse<DeviceAccess[]>;

@Injectable()
export class UserService {
    private resourceUrl = CORE_API_URL + '/v1/users';

    constructor(private http: HttpClient) { }

    create(user: User): Observable<EntityResponseType> {
        return this.http.post(this.resourceUrl, user, { observe: 'response' });
    }

    update(user: User): Observable<EntityResponseType> {
        return this.http.put(this.resourceUrl, user, { observe: 'response' });
    }

    find(userId: any): Observable<EntityResponseType> {
        return this.http.get(`${this.resourceUrl}/${userId}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<User[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(login: string): Observable<Object> {
        return this.http.delete(`${this.resourceUrl}/${login}`);
    }

    authorities(): Observable<string[]> {
        return observableOf(AUTHORITIES);
    }

    getDeviceAccessesByUserId(id: number): Observable<DeviceAccessArrayResponseType>  {
        return this.http.get<DeviceAccess[]>(`${DEVICE_ACCESS_RESOURCE_URL}?userId=${id}`, { observe: 'response' });
    }

}
