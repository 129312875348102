import {Component, Input} from '@angular/core';
import {StatisticsInterface} from '../statistics.service';
import {faUsers} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-statistics-users',
    templateUrl: './statistics-users.component.html',
    styleUrls: ['./statistics-users.scss']
})
export class StatisticsUsersComponent {
    faUsers = faUsers;
    @Input() stats: StatisticsInterface;

    getValue(key) {
        if (!this.stats || (!this.stats[key] && this.stats[key] !== 0)) {
            return '-';
        }
        return this.stats[key];
    }
}
