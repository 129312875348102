import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {JhiEventManager} from 'ng-jhipster';

import {DeviceType} from './device-type.model';
import {DeviceTypeService} from './device-type.service';
import {HttpResponse} from '@angular/common/http';
import {faArrowLeft, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-device-type-detail',
    templateUrl: './device-type-detail.component.html'
})
export class DeviceTypeDetailComponent implements OnInit, OnDestroy {
    faPencilAlt = faPencilAlt;
    faArrowLeft = faArrowLeft;
    deviceType: DeviceType;
    private subscription: Subscription;
    private eventSubscriber: Subscription;

    constructor(
        private eventManager: JhiEventManager,
        private deviceTypeService: DeviceTypeService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        this.registerChangeInDeviceTypes();
    }

    load(id) {
        this.deviceTypeService.find(id).subscribe((res: HttpResponse<DeviceType>) => {
            this.deviceType = res.body;
        });
    }
    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInDeviceTypes() {
        this.eventSubscriber = this.eventManager.subscribe(
            'deviceTypeListModification',
            (response) => this.load(this.deviceType.id)
        );
    }
}
