import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'jhi-server-chooser',
    template: '<div class="form-group">\n' +
        '        <label class="form-control-label" for="diagnosticHost">Server</label>\n' +
        '        <select class="form-control" id="diagnosticHost" name="deviceType" [(ngModel)]="server" (change)="onChange()">\n' +
        '            <option [ngValue]="\'core\'">Core</option>\n' +
        '            <option [ngValue]="\'\'">Portal Admin</option>\n' +
        '            <option [ngValue]="\'demo-portal\'">Portal Demo</option>\n' +
        '            <option [ngValue]="\'uua\'">Server UUA</option>\n' +
        '        </select>\n' +
        '    </div>'
})
export class JhiServerChooserComponent implements OnInit {
    server = 'core';

    @Output() onSelect = new EventEmitter(true);

    constructor() {
    }

    onChange() {
        this.onSelect.emit(this.server);
    }

    ngOnInit(): void {
        this.onSelect.emit(this.server);
    }
}
