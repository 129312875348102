import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {CORE_API_URL} from '../../app.constants';

@Injectable()
export class ProfileService {

    constructor(private http: HttpClient) { }

    reindex(): Promise<any> {
        return this.http.get(CORE_API_URL + '/v1/account/reindex').toPromise();
    }
}
