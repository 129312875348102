import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Principal} from '@balrog/core';
import {faBan, faSave} from '@fortawesome/free-solid-svg-icons';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {City, CityService} from '../../entities/city';
import {Country} from '../../entities/country/country.model';
import {CountryService} from '../../entities/country/country.service';
import {Organization} from '../../entities/organization/organization.model';
import {OrganizationService} from '../../entities/organization/organization.service';
import {Region} from '../../entities/region/region.model';
import {RegionService} from '../../entities/region/region.service';
import {ITEMS_PER_PAGE, JhiLanguageHelper, User, UserService} from '../../shared';

import {UserModalService} from './user-modal.service';

@Component({
    selector: 'jhi-user-mgmt-dialog',
    templateUrl: './user-management-dialog.component.html'
})
export class UserMgmtDialogComponent implements OnInit {
    faBan = faBan;
    faSave = faSave;

    user: User;
    cities: City[] = [];
    languages: any[];
    authorities: any[];
    isSaving: Boolean;
    countries: Country[];
    regions: Region[];
    organizations: Organization[];

    cityField: any = {
        isLoading: false,
        isSearching: false,
        page: 0,
        totalItems: null,
        itemsPerPage: ITEMS_PER_PAGE,
        typeaheadSubject: new Subject<string>()
    };

    constructor(
        public activeModal: NgbActiveModal,
        private alertService: JhiAlertService,
        private languageHelper: JhiLanguageHelper,
        private userService: UserService,
        private cityService: CityService,
        private countryService: CountryService,
        private regionService: RegionService,
        private organizationService: OrganizationService,
        private eventManager: JhiEventManager,
        private principal: Principal
    ) {}

    ngOnInit() {
        this.isSaving = false;
        this.authorities = [];
        this.userService.authorities().subscribe((authorities) => {
            if (!this.principal.hasHierarchyAccess('ROLE_ADMIN')) {
                authorities = authorities.filter((authority) => authority !== 'ROLE_ADMIN');
            }
            this.authorities = authorities;
        });
        this.languageHelper.getAll().then((languages) => {
            this.languages = languages;
        });

        this.countryService.query()
            .subscribe((res: HttpResponse<any>) => { this.countries = res.body; }, (res: HttpErrorResponse) => this.onError(res));
        this.regionService.query()
            .subscribe((res: HttpResponse<any>) => { this.regions = res.body; }, (res: HttpErrorResponse) => this.onError(res));
        if (this.principal.hasHierarchyAccess('ROLE_ADMIN')) {
            this.organizationService.query()
                .subscribe((res: HttpResponse<any>) => { this.organizations = res.body; }, (res: HttpErrorResponse) => this.onError(res));
        }
        this.cityTypeaheadLoad();
        this.cities.push({id: this.user.cityId, name: this.user.cityName, regionName: this.user.regionName});
    }

    cityTypeaheadLoad() {
        this.cityField.typeaheadSubject.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            tap(() => {
                this.cityField.isLoading = true;
            }),
            switchMap((term) => {
                if (term) {
                    this.cityField.isSearching = true;
                    return this.cityService.search({
                        name: term
                    });
                } else {
                    this.cityField.isSearching = false;
                    return this.cityService.search();
                }
            })
        ).subscribe((res) => {
            this.cities = res.body.data;
            this.cityField.isLoading = false;
            this.cityField.page = 0;
        })
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        if (this.user.id !== null) {
            this.userService.update(this.user).subscribe((res: HttpResponse<User>) => this.onSaveSuccess(res.body), () => this.onSaveError());
        } else {
            this.userService.create(this.user).subscribe((res: HttpResponse<User>) => this.onSaveSuccess(res.body), () => this.onSaveError());
        }
    }

    private onSaveSuccess(result) {
        this.eventManager.broadcast({ name: 'userListModification', content: 'OK' });
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError() {
        this.isSaving = false;
    }

    private onError(error) {
        this.alertService.error(error.error, error.message, null);
    }

    trackOrganizationById(index: number, item: Organization) {
        return item.id;
    }

    trackCountryById(index: number, item: Country) {
        return item.id;
    }

    trackRegionById(index: number, item: Region) {
        return item.id;
    }

    handleCityChanged(city) {
        this.user.regionName = city.regionName;
        this.user.regionId = city.regionId;
    }
}

@Component({
    selector: 'jhi-user-dialog',
    template: ''
})
export class UserDialogComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private userModalService: UserModalService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if ( params['login'] ) {
                this.userModalService.open(UserMgmtDialogComponent as Component, params['login']);
            } else {
                this.userModalService.open(UserMgmtDialogComponent as Component);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
