import {Component, OnInit} from '@angular/core';

import {ContactMessage} from './message-message.model';
import {MessageService} from './message.service';

@Component({
    selector: 'jhi-contact',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {
    error: string;
    success: string;
    message: ContactMessage;
    sending = false;
    messageTo;
    messageTitle;
    messageBody;
    count: number;

    constructor(
        private contactService: MessageService,
    ) {
    }

    ngOnInit() {
    }

    sendMessage() {
        if (this.messageTitle.length < 5) {
            this.error = 'Tytuł wiadomości musi zawierać conajmniej 5 znaków';
            return;
        }
        this.sending = true;
        this.error = null;
        this.message = new ContactMessage([this.messageTo], this.messageTitle, this.messageBody);
        this.contactService.sendMessage(this.message).subscribe(() => {
            this.success = 'ok';
            this.sending = false;
        }, (() =>  {
            this.error = 'Nieoczekiwany błąd połączenia, prosimy spróbować ponownie';
            this.sending = false;
        }));
    }
}
