import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccessPermissionService} from '../../shared';

@Component({
    selector: 'jhi-ngx-table-access-field',
    template: `<div
                style="width: 100%;"
                title="Double click to edit"
                (dblclick)="editing = true"
                *ngIf="!editing">
                {{ value && value !== '' ? accessName() : '_'}}
            </div>
            <select class="form-control"
                    id="field_accessLevel"
                    name="accessLevel"
                    [(ngModel)]="value"
                    (blur)="handleUpdate()"
                    *ngIf="editing"
                    required>
                <option value="" >---</option>
                <option [ngValue]="accessPermission.access" *ngFor="let accessPermission of accessPermissions">{{accessPermission.name}}</option>
            </select>`
})
export class NgxTableAccessFieldComponent implements OnInit {

    editing = false;
    @Input() value: string;
    @Output() update: EventEmitter<any> = new EventEmitter();

    accessPermissions: any;

    constructor(
        private accessPermissionService: AccessPermissionService
    ) {}

    ngOnInit(): void {
        this.accessPermissionService.getAll().subscribe((accessPermissions) => {
            this.accessPermissions = accessPermissions;
        });
    }

    handleUpdate() {
        this.editing = false;
        this.update.emit(this.value ? this.value : null);
    }

    accessName() {
        let name = 'Unknown';
        this.accessPermissions.forEach((p) => {
            if (p.access === Number(this.value)) {
                name = p.name;
            }
        });
        return name;
    }
}
