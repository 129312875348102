import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CORE_API_URL} from '@balrog/core/core.constants';
import {User} from '@balrog/core/model';
import {Device} from '@balrog/core/model/device';
import {Observable} from 'rxjs';

@Injectable()
export class AccountService  {
    baseUrl = CORE_API_URL + '/v1/account';

    constructor(private http: HttpClient) { }

    getCredentialsData(): Observable<User> {
        return this.http.get(this.baseUrl);
    }

    get(): Observable<User> {
        return this.http.get(this.baseUrl);
    }

    save(account: User): Observable<User> {
        return this.http.put(this.baseUrl, account);
    }

    listDevices(): Observable<Device[]> {
        return this.http.get<Device[]>(this.baseUrl + '/devices')
    }

    updateWelcomeCode(welcomeCode: string): Observable<any> {
        return this.http.put(this.baseUrl + '/welcome', {welcomeCode});
    }

}
