export enum ComponentType {
    'REKU_MAIN_PANEL',
    'HABA_MAIN_PANEL',
    'COBRA_MAIN_PANEL',
    'SOLAR_MAIN_PANEL',
    'DASHBOARD_SIMPLE',
    'DASHBOARD_CHART',
    'DASHBOARD_CIRCLE',
    'DASHBOARD_GAUGE',
    'DASHBOARD_STATUS',
    'DASHBOARD_SLIDER',
    'MINISTER_CO'
}
