import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

@Pipe({name: 'authorityTranslate'})
export class AuthorityTranslatePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {}

    transform(role: string): Observable<string> {
        return this.translateService.get(`userManagement.roles.${role}`);
    }
}
