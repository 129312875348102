import {MdlListModule, MdlProgressModule} from '@angular-mdl/core';
import {MdlSelectModule} from '@angular-mdl/select';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonComponentModule} from '@balrog/component/common/common-component.module';
import {DeviceConfigurationListComponent} from './device-configuration-list.component';
import {SaveSetpointsDialogComponent} from './dialog/save-setpoints.dialog';
import {ListBoardLevelComponent} from './list-board-level.component';
import {ListBoardRowArrayRangeComponent} from './list-board-row-array-range.component';
import {ListBoardRowComponent} from './list-board-row.component';

@NgModule({
    imports: [
        CommonComponentModule,
        MdlListModule,
        MdlSelectModule,
        MdlProgressModule,
    ],
    declarations: [
        DeviceConfigurationListComponent,
        ListBoardRowComponent,
        ListBoardLevelComponent,
        ListBoardRowArrayRangeComponent,
        SaveSetpointsDialogComponent,
    ],
    entryComponents: [
        DeviceConfigurationListComponent,
        ListBoardRowComponent,
        ListBoardLevelComponent,
        ListBoardRowArrayRangeComponent,
        SaveSetpointsDialogComponent
    ],
    exports: [
        DeviceConfigurationListComponent,
        ListBoardRowComponent,
        ListBoardLevelComponent,
        ListBoardRowArrayRangeComponent,
        SaveSetpointsDialogComponent
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DeviceConfigurationListModule {}
