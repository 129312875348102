import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DEVICE_ACCESS_RESOURCE_URL} from '@balrog/core';
import {Observable, of as observableOf} from 'rxjs';

import {CORE_API_URL} from '../../app.constants';
import {DeviceAccess} from '../../entities/device-access/device-access.model';
import {createRequestOption} from '../model/request-util';
import {User} from './user.model';

type EntityResponseType = HttpResponse<User>;
type EntityArrayResponseType = HttpResponse<User[]>;
type DeviceAccessArrayResponseType = HttpResponse<DeviceAccess[]>;

export const AUTHORITIES = [
    'ROLE_USER',
    'ROLE_ORG_USER',
    'ROLE_ORG_MANAGER',
    'ROLE_ORG_ADMIN',
    'ROLE_ADMIN'
];

@Injectable()
export class UserService {
    private resourceUrl = CORE_API_URL + '/v1/users';

    constructor(private http: HttpClient) { }

    create(user: User): Observable<EntityResponseType> {
        return this.http.post(this.resourceUrl, user, { observe: 'response' });
    }

    update(user: User): Observable<EntityResponseType> {
        return this.http.put(this.resourceUrl, user, { observe: 'response' });
    }

    find(userId: any): Observable<EntityResponseType> {
        return this.http.get(`${this.resourceUrl}/${userId}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<User[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(login: string): Observable<Object> {
        return this.http.delete(`${this.resourceUrl}/${login}`);
    }

    authorities(): Observable<string[]> {
        return observableOf(AUTHORITIES);
    }

    getDeviceAccessesByUserId(id: number): Observable<DeviceAccessArrayResponseType>  {
        return this.http.get<DeviceAccess[]>(`${DEVICE_ACCESS_RESOURCE_URL}?userId=${id}`, { observe: 'response' });
    }

}
