import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CORE_API_URL} from '@balrog/core/core.constants';
import {Observable} from 'rxjs';
import {DeviceAccess} from '../model/admin';
import {createRequestOption} from '../utils';

type EntityResponseType = HttpResponse<DeviceAccess>;
type EntityArrayResponseType = HttpResponse<DeviceAccess[]>;

export const DEVICE_ACCESS_RESOURCE_URL = CORE_API_URL + '/v1/device-accesses';

@Injectable()
export class DeviceAccessService {

    private resourceUrl = DEVICE_ACCESS_RESOURCE_URL;

    constructor(private http: HttpClient) { }

    create(deviceAccess: DeviceAccess): Observable<EntityResponseType> {
        const copy = this.convert(deviceAccess);
        return this.http.post(this.resourceUrl, copy, { observe: 'response' });
    }

    update(deviceAccess: DeviceAccess): Observable<EntityResponseType> {
        const copy = this.convert(deviceAccess);
        return this.http.put(this.resourceUrl, copy, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<DeviceAccess[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    /**
     * Convert a DeviceAccess to a JSON which can be sent to the server.
     */
    private convert(deviceAccess: DeviceAccess): DeviceAccess {
        const copy: DeviceAccess = Object.assign({}, deviceAccess);
        return copy;
    }
}
