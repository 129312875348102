export const spinnerComponentHtml = `
<div class="spinner-container" [ngClass]="{'dimmed': dimmed}" *ngIf="show">
    <div class="spinner-center" [ngStyle]="style()">
        <svg class="lds-spin" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="shape-rendering: auto; animation-play-state: running; animation-delay: 0s; background: none;"><g transform="translate(80,50)" style="animation-play-state: running; animation-delay: 0s;">
            <g transform="rotate(0)" style="animation-play-state: running; animation-delay: 0s;">
                <circle cx="0" cy="0" r="6" fill="#153e50" fill-opacity="1" style="animation-play-state: running; animation-delay: 0s;" transform="scale(1.07709 1.07709)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.9s" values="1.1 1.1;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.9s" style="animation-play-state: running; animation-delay: 0s;"></animate>
                </circle>
            </g>
        </g><g transform="translate(74.27050983124843,67.6335575687742)" style="animation-play-state: running; animation-delay: 0s;">
            <g transform="rotate(36)" style="animation-play-state: running; animation-delay: 0s;">
                <circle cx="0" cy="0" r="6" fill="#153e50" fill-opacity="0.9" style="animation-play-state: running; animation-delay: 0s;" transform="scale(1.08709 1.08709)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.8s" values="1.1 1.1;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.8s" style="animation-play-state: running; animation-delay: 0s;"></animate>
                </circle>
            </g>
        </g><g transform="translate(59.270509831248425,78.53169548885461)" style="animation-play-state: running; animation-delay: 0s;">
            <g transform="rotate(72)" style="animation-play-state: running; animation-delay: 0s;">
                <circle cx="0" cy="0" r="6" fill="#153e50" fill-opacity="0.8" style="animation-play-state: running; animation-delay: 0s;" transform="scale(1.09709 1.09709)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.7s" values="1.1 1.1;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.7s" style="animation-play-state: running; animation-delay: 0s;"></animate>
                </circle>
            </g>
        </g><g transform="translate(40.72949016875158,78.53169548885461)" style="animation-play-state: running; animation-delay: 0s;">
            <g transform="rotate(108)" style="animation-play-state: running; animation-delay: 0s;">
                <circle cx="0" cy="0" r="6" fill="#153e50" fill-opacity="0.7" style="animation-play-state: running; animation-delay: 0s;" transform="scale(1.00709 1.00709)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.6s" values="1.1 1.1;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.6s" style="animation-play-state: running; animation-delay: 0s;"></animate>
                </circle>
            </g>
        </g><g transform="translate(25.72949016875158,67.6335575687742)" style="animation-play-state: running; animation-delay: 0s;">
            <g transform="rotate(144)" style="animation-play-state: running; animation-delay: 0s;">
                <circle cx="0" cy="0" r="6" fill="#153e50" fill-opacity="0.6" style="animation-play-state: running; animation-delay: 0s;" transform="scale(1.01709 1.01709)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.5s" values="1.1 1.1;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.5s" style="animation-play-state: running; animation-delay: 0s;"></animate>
                </circle>
            </g>
        </g><g transform="translate(20,50.00000000000001)" style="animation-play-state: running; animation-delay: 0s;">
            <g transform="rotate(180)" style="animation-play-state: running; animation-delay: 0s;">
                <circle cx="0" cy="0" r="6" fill="#153e50" fill-opacity="0.5" style="animation-play-state: running; animation-delay: 0s;" transform="scale(1.02709 1.02709)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.4s" values="1.1 1.1;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.4s" style="animation-play-state: running; animation-delay: 0s;"></animate>
                </circle>
            </g>
        </g><g transform="translate(25.729490168751575,32.366442431225806)" style="animation-play-state: running; animation-delay: 0s;">
            <g transform="rotate(216)" style="animation-play-state: running; animation-delay: 0s;">
                <circle cx="0" cy="0" r="6" fill="#153e50" fill-opacity="0.4" style="animation-play-state: running; animation-delay: 0s;" transform="scale(1.03709 1.03709)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.3s" values="1.1 1.1;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.3s" style="animation-play-state: running; animation-delay: 0s;"></animate>
                </circle>
            </g>
        </g><g transform="translate(40.729490168751575,21.468304511145394)" style="animation-play-state: running; animation-delay: 0s;">
            <g transform="rotate(252)" style="animation-play-state: running; animation-delay: 0s;">
                <circle cx="0" cy="0" r="6" fill="#153e50" fill-opacity="0.3" style="animation-play-state: running; animation-delay: 0s;" transform="scale(1.04709 1.04709)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.2s" values="1.1 1.1;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.2s" style="animation-play-state: running; animation-delay: 0s;"></animate>
                </circle>
            </g>
        </g><g transform="translate(59.27050983124842,21.46830451114539)" style="animation-play-state: running; animation-delay: 0s;">
            <g transform="rotate(288)" style="animation-play-state: running; animation-delay: 0s;">
                <circle cx="0" cy="0" r="6" fill="#153e50" fill-opacity="0.2" style="animation-play-state: running; animation-delay: 0s;" transform="scale(1.05709 1.05709)">
                    <animateTransform attributeName="transform" type="scale" begin="-0.1s" values="1.1 1.1;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.1s" style="animation-play-state: running; animation-delay: 0s;"></animate>
                </circle>
            </g>
        </g><g transform="translate(74.27050983124842,32.3664424312258)" style="animation-play-state: running; animation-delay: 0s;">
            <g transform="rotate(324)" style="animation-play-state: running; animation-delay: 0s;">
                <circle cx="0" cy="0" r="6" fill="#153e50" fill-opacity="0.1" style="animation-play-state: running; animation-delay: 0s;" transform="scale(1.06709 1.06709)">
                    <animateTransform attributeName="transform" type="scale" begin="0s" values="1.1 1.1;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite" style="animation-play-state: running; animation-delay: 0s;"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="0s" style="animation-play-state: running; animation-delay: 0s;"></animate>
                </circle>
            </g>
        </g></svg>
    </div>
</div>
`;
