import { BriefPanelName, PanelTypeEnum, Unit } from '@balrog/component/types';
import { AccessPermissionEnum } from '@balrog/core/model/const';
import { ProfileInterface } from './interface/profile.interface';
/* eslint no-bitwise: ["error", { "allow": ["&", ">>"] }] */
export class TweetopOPProfile implements ProfileInterface {
    statuses = {};

    alarms = {};

    briefPanel = {
        name: BriefPanelName.TWEETOP_OP
    };

    dashBoard = {
        panelType: PanelTypeEnum.TWEETOP_OP
    };

    historyBoard = null;

    listBoard = {
        title: 'Konfiguracja urzadzenia',
        content: [ {
            paramId: 'NUMBER_DEVICES'
        }, {
            type: 'ITEM-TEXT',
            paramId: 'WERSJA_FIRMWARE',
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
            inputConverter(v: number) {
                return ((v&0xff00)>>8) + "." + (v&0xff);
            }
        }, {
            type: 'ITEM-TEXT',
            paramId: 'WERSJA_HARDWARE',
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
            inputConverter(v: number) {
                return ((v&0xff00)>>8) + "." + (v&0xff);
            }
        }, {
            paramId: 'NOW_TIME',
            type: 'ITEM-TEXT',
            placeholder: 'HH:MM'
        }, {
            paramId: 'NOW_DATE',
            type: 'ITEM-TEXT',
            placeholder: 'yyyy-mm-dd'
        }, {
            paramId: 'TEMP_SENS',
            type: 'ITEM-SELECT',
            options: [
                {value: '0', name: 'brak'},
                {value: '1', name: 'NTC 4k7'},
                {value: '2', name: 'NTC 10k'},
                {value: '3', name: 'NTC OTHER'},
                {value: '4', name: 'DS18B20'}
            ]
        }, {
            paramId: 'TEMP_VAL',
            readonly: true,
            unit: Unit.C
        }, {
            title: 'Modul 1',
            readPermission: AccessPermissionEnum.SERVICE.valueOf(),
            type: 'MENU',
            content: [{
                title: 'Kanał 1',
                readPermission: AccessPermissionEnum.SERVICE.valueOf(),
                type: 'MENU',
                content: [{
                    paramId: 'DEV1_CH1_CFG',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Digital Input'},
                        {value: '1', name: 'Portal - ster. cyfrowe'},
                        {value: '2', name: 'Portal - ster. temp.'},
                        {value: '3', name: 'Harmonogram'},
                        {value: '4', name: 'ON'},
                        {value: '5', name: 'OFF'},
                        {value: '6', name: 'Wyjścia poniżej załączają wyjście'},
                        {value: '7', name: 'Wyjścia powyżej załączają wyjście'},
                        {value: '8', name: 'Wyjścia poniżej włącznie załączają wyjście'},
                        {value: '9', name: 'Wyjścia powyżej włącznie załączają wyjście'},
                        {value: '10', name: 'Modbus BHT-1000'}
                    ]
                }, {
                    paramId: 'DEV1_CH1_MOD_ADDR'
                }, {
                    paramId: 'DEV1_CH1_TEMP',
                    readonly: true,
                    unit: Unit.C
                }, {
                    paramId: 'DEV1_CH1_TEMP_SET',
                    unit: Unit.C
                }, {
                    paramId: 'DEV1_OUT_1',
                    readonly: true,
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
                }]
            }, {
                title: 'Kanał 2',
                readPermission: AccessPermissionEnum.SERVICE.valueOf(),
                type: 'MENU',
                content: [{
                    paramId: 'DEV1_CH2_CFG',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Digital Input'},
                        {value: '1', name: 'Portal - ster. cyfrowe'},
                        {value: '2', name: 'Portal - ster. temp.'},
                        {value: '3', name: 'Harmonogram'},
                        {value: '4', name: 'ON'},
                        {value: '5', name: 'OFF'},
                        {value: '6', name: 'Wyjścia poniżej załączają wyjście'},
                        {value: '7', name: 'Wyjścia powyżej załączają wyjście'},
                        {value: '8', name: 'Wyjścia poniżej włącznie załączają wyjście'},
                        {value: '9', name: 'Wyjścia powyżej włącznie załączają wyjście'},
                        {value: '10', name: 'Modbus BHT-1000'}
                    ]
                }, {
                    paramId: 'DEV1_CH2_MOD_ADDR'
                }, {
                    paramId: 'DEV1_CH2_TEMP',
                    readonly: true,
                    unit: Unit.C
                }, {
                    paramId: 'DEV1_CH2_TEMP_SET',
                    unit: Unit.C
                }, {
                    paramId: 'DEV1_OUT_2',
                    readonly: true,
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
                }]
            }, {
                title: 'Kanał 3',
                readPermission: AccessPermissionEnum.SERVICE.valueOf(),
                type: 'MENU',
                content: [{
                    paramId: 'DEV1_CH3_CFG',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Digital Input'},
                        {value: '1', name: 'Portal - ster. cyfrowe'},
                        {value: '2', name: 'Portal - ster. temp.'},
                        {value: '3', name: 'Harmonogram'},
                        {value: '4', name: 'ON'},
                        {value: '5', name: 'OFF'},
                        {value: '6', name: 'Wyjścia poniżej załączają wyjście'},
                        {value: '7', name: 'Wyjścia powyżej załączają wyjście'},
                        {value: '8', name: 'Wyjścia poniżej włącznie załączają wyjście'},
                        {value: '9', name: 'Wyjścia powyżej włącznie załączają wyjście'},
                        {value: '10', name: 'Modbus BHT-1000'}
                    ]
                }, {
                    paramId: 'DEV1_CH3_MOD_ADDR'
                }, {
                    paramId: 'DEV1_CH3_TEMP',
                    readonly: true,
                    unit: Unit.C
                }, {
                    paramId: 'DEV1_CH3_TEMP_SET',
                    unit: Unit.C
                }, {
                    paramId: 'DEV1_OUT_3',
                    readonly: true,
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
                }]
            }, {
                title: 'Kanał 4',
                readPermission: AccessPermissionEnum.SERVICE.valueOf(),
                type: 'MENU',
                content: [{
                    paramId: 'DEV1_CH4_CFG',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Digital Input'},
                        {value: '1', name: 'Portal - ster. cyfrowe'},
                        {value: '2', name: 'Portal - ster. temp.'},
                        {value: '3', name: 'Harmonogram'},
                        {value: '4', name: 'ON'},
                        {value: '5', name: 'OFF'},
                        {value: '6', name: 'Wyjścia poniżej załączają wyjście'},
                        {value: '7', name: 'Wyjścia powyżej załączają wyjście'},
                        {value: '8', name: 'Wyjścia poniżej włącznie załączają wyjście'},
                        {value: '9', name: 'Wyjścia powyżej włącznie załączają wyjście'},
                        {value: '10', name: 'Modbus BHT-1000'}
                    ]
                }, {
                    paramId: 'DEV1_CH4_MOD_ADDR'
                }, {
                    paramId: 'DEV1_CH4_TEMP',
                    readonly: true,
                    unit: Unit.C
                }, {
                    paramId: 'DEV1_CH4_TEMP_SET',
                    unit: Unit.C
                }, {
                    paramId: 'DEV1_OUT_4',
                    readonly: true,
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
                }]
            }, {
                title: 'Kanał 5',
                readPermission: AccessPermissionEnum.SERVICE.valueOf(),
                type: 'MENU',
                content: [{
                    paramId: 'DEV1_CH5_CFG',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Digital Input'},
                        {value: '1', name: 'Portal - ster. cyfrowe'},
                        {value: '2', name: 'Portal - ster. temp.'},
                        {value: '3', name: 'Harmonogram'},
                        {value: '4', name: 'ON'},
                        {value: '5', name: 'OFF'},
                        {value: '6', name: 'Wyjścia poniżej załączają wyjście'},
                        {value: '7', name: 'Wyjścia powyżej załączają wyjście'},
                        {value: '8', name: 'Wyjścia poniżej włącznie załączają wyjście'},
                        {value: '9', name: 'Wyjścia powyżej włącznie załączają wyjście'},
                        {value: '10', name: 'Modbus BHT-1000'}
                    ]
                }, {
                    paramId: 'DEV1_CH5_MOD_ADDR'
                }, {
                    paramId: 'DEV1_CH5_TEMP',
                    readonly: true,
                    unit: Unit.C
                }, {
                    paramId: 'DEV1_CH5_TEMP_SET',
                    unit: Unit.C
                }, {
                    paramId: 'DEV1_OUT_5',
                    readonly: true,
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
                }]
            }]
        }, {
            title: 'Modul 2',
            readPermission: AccessPermissionEnum.SERVICE.valueOf(),
            type: 'MENU',
            content: [{
                title: 'Kanał 1',
                readPermission: AccessPermissionEnum.SERVICE.valueOf(),
                type: 'MENU',
                content: [{
                    paramId: 'DEV2_CH1_CFG',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Digital Input'},
                        {value: '1', name: 'Portal - ster. cyfrowe'},
                        {value: '2', name: 'Portal - ster. temp.'},
                        {value: '3', name: 'Harmonogram'},
                        {value: '4', name: 'ON'},
                        {value: '5', name: 'OFF'},
                        {value: '6', name: 'Wyjścia poniżej załączają wyjście'},
                        {value: '7', name: 'Wyjścia powyżej załączają wyjście'},
                        {value: '8', name: 'Wyjścia poniżej włącznie załączają wyjście'},
                        {value: '9', name: 'Wyjścia powyżej włącznie załączają wyjście'},
                        {value: '10', name: 'Modbus BHT-1000'}
                    ]
                }, {
                    paramId: 'DEV2_CH1_MOD_ADDR'
                }, {
                    paramId: 'DEV2_CH1_TEMP',
                    readonly: true,
                    unit: Unit.C
                }, {
                    paramId: 'DEV2_CH1_TEMP_SET',
                    unit: Unit.C
                }, {
                    paramId: 'DEV2_OUT_1',
                    readonly: true,
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
                }]
            }, {
                title: 'Kanał 2',
                readPermission: AccessPermissionEnum.SERVICE.valueOf(),
                type: 'MENU',
                content: [{
                    paramId: 'DEV2_CH2_CFG',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Digital Input'},
                        {value: '1', name: 'Portal - ster. cyfrowe'},
                        {value: '2', name: 'Portal - ster. temp.'},
                        {value: '3', name: 'Harmonogram'},
                        {value: '4', name: 'ON'},
                        {value: '5', name: 'OFF'},
                        {value: '6', name: 'Wyjścia poniżej załączają wyjście'},
                        {value: '7', name: 'Wyjścia powyżej załączają wyjście'},
                        {value: '8', name: 'Wyjścia poniżej włącznie załączają wyjście'},
                        {value: '9', name: 'Wyjścia powyżej włącznie załączają wyjście'},
                        {value: '10', name: 'Modbus BHT-1000'}
                    ]
                }, {
                    paramId: 'DEV2_CH2_MOD_ADDR'
                }, {
                    paramId: 'DEV2_CH2_TEMP',
                    readonly: true,
                    unit: Unit.C
                }, {
                    paramId: 'DEV2_CH2_TEMP_SET',
                    unit: Unit.C
                }, {
                    paramId: 'DEV2_OUT_2',
                    readonly: true,
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
                }]
            }, {
                title: 'Kanał 3',
                readPermission: AccessPermissionEnum.SERVICE.valueOf(),
                type: 'MENU',
                content: [{
                    paramId: 'DEV2_CH3_CFG',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Digital Input'},
                        {value: '1', name: 'Portal - ster. cyfrowe'},
                        {value: '2', name: 'Portal - ster. temp.'},
                        {value: '3', name: 'Harmonogram'},
                        {value: '4', name: 'ON'},
                        {value: '5', name: 'OFF'},
                        {value: '6', name: 'Wyjścia poniżej załączają wyjście'},
                        {value: '7', name: 'Wyjścia powyżej załączają wyjście'},
                        {value: '8', name: 'Wyjścia poniżej włącznie załączają wyjście'},
                        {value: '9', name: 'Wyjścia powyżej włącznie załączają wyjście'},
                        {value: '10', name: 'Modbus BHT-1000'}
                    ]
                }, {
                    paramId: 'DEV2_CH3_MOD_ADDR'
                }, {
                    paramId: 'DEV2_CH3_TEMP',
                    readonly: true,
                    unit: Unit.C
                }, {
                    paramId: 'DEV2_CH3_TEMP_SET',
                    unit: Unit.C
                }, {
                    paramId: 'DEV2_OUT_3',
                    readonly: true,
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
                }]
            }, {
                title: 'Kanał 4',
                readPermission: AccessPermissionEnum.SERVICE.valueOf(),
                type: 'MENU',
                content: [{
                    paramId: 'DEV2_CH4_CFG',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Digital Input'},
                        {value: '1', name: 'Portal - ster. cyfrowe'},
                        {value: '2', name: 'Portal - ster. temp.'},
                        {value: '3', name: 'Harmonogram'},
                        {value: '4', name: 'ON'},
                        {value: '5', name: 'OFF'},
                        {value: '6', name: 'Wyjścia poniżej załączają wyjście'},
                        {value: '7', name: 'Wyjścia powyżej załączają wyjście'},
                        {value: '8', name: 'Wyjścia poniżej włącznie załączają wyjście'},
                        {value: '9', name: 'Wyjścia powyżej włącznie załączają wyjście'},
                        {value: '10', name: 'Modbus BHT-1000'}
                    ]
                }, {
                    paramId: 'DEV2_CH4_MOD_ADDR'
                }, {
                    paramId: 'DEV2_CH4_TEMP',
                    readonly: true,
                    unit: Unit.C
                }, {
                    paramId: 'DEV2_CH4_TEMP_SET',
                    unit: Unit.C
                }, {
                    paramId: 'DEV2_OUT_4',
                    readonly: true,
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
                }]
            }, {
                title: 'Kanał 5',
                readPermission: AccessPermissionEnum.SERVICE.valueOf(),
                type: 'MENU',
                content: [{
                    paramId: 'DEV2_CH5_CFG',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Digital Input'},
                        {value: '1', name: 'Portal - ster. cyfrowe'},
                        {value: '2', name: 'Portal - ster. temp.'},
                        {value: '3', name: 'Harmonogram'},
                        {value: '4', name: 'ON'},
                        {value: '5', name: 'OFF'},
                        {value: '6', name: 'Wyjścia poniżej załączają wyjście'},
                        {value: '7', name: 'Wyjścia powyżej załączają wyjście'},
                        {value: '8', name: 'Wyjścia poniżej włącznie załączają wyjście'},
                        {value: '9', name: 'Wyjścia powyżej włącznie załączają wyjście'},
                        {value: '10', name: 'Modbus BHT-1000'}
                    ]
                }, {
                    paramId: 'DEV2_CH5_MOD_ADDR'
                }, {
                    paramId: 'DEV2_CH5_TEMP',
                    readonly: true,
                    unit: Unit.C
                }, {
                    paramId: 'DEV2_CH5_TEMP_SET',
                    unit: Unit.C
                }, {
                    paramId: 'DEV2_OUT_5',
                    readonly: true,
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
                }]
            }]
        }
        ]
    };

    scheduleBoard = null;
}
