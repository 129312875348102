import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes} from '@angular/router';

import {JhiPaginationUtil} from 'ng-jhipster';

import {UserMgmtComponent} from './user-management.component';
import {UserMgmtDetailComponent} from './user-management-detail.component';
import {UserDialogComponent} from './user-management-dialog.component';
import {UserDeleteDialogComponent} from './user-management-delete-dialog.component';
import {UserRouteAccessService} from '../../shared/auth/user-route-access-service'
import {UserManagementDevicesPopupComponent} from './user-management-devices.component';
import {UserService} from '../../shared/user/user.service';

@Injectable()
export class UserResolvePagingParams implements Resolve<any> {

    constructor(private paginationUtil: JhiPaginationUtil) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const page = route.queryParams['page'] ? route.queryParams['page'] : '1';
        const sort = route.queryParams['sort'] ? route.queryParams['sort'] : 'id,asc';
        const searchText = route.queryParams['searchText'] ? route.queryParams['searchText'] : '';
        return {
            page: this.paginationUtil.parsePage(page),
            predicate: this.paginationUtil.parsePredicate(sort),
            ascending: this.paginationUtil.parseAscending(sort),
            searchText
        };
    }
}

@Injectable()
export class UserDevicesResolver implements Resolve<any> {

    constructor(private userService: UserService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.getDeviceAccessesByUserId(route.params['id']);
    }
}

export const userMgmtRoute: Routes = [
    {
        path: 'user-management',
        component: UserMgmtComponent,
        resolve: {
            'pagingParams': UserResolvePagingParams
        },
        data: {
            authorities: ['ROLE_ORG_USER'],
            pageTitle: 'userManagement.home.title'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'user-management/:login',
        component: UserMgmtDetailComponent,
        data: {
            authorities: ['ROLE_ORG_USER'],
            pageTitle: 'userManagement.home.title'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const userDialogRoute: Routes = [
    {
        path: 'user-management-new',
        component: UserDialogComponent,
        data: {
            authorities: ['ROLE_ORG_ADMIN']
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'user-management/:login/edit',
        component: UserDialogComponent,
        data: {
            authorities: ['ROLE_ORG_ADMIN']
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'user-management/:login/delete',
        component: UserDeleteDialogComponent,
        data: {
            authorities: ['ROLE_ORG_ADMIN']
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'user-management/:id/devices',
        component: UserManagementDevicesPopupComponent,
        data: {
            authorities: ['ROLE_ORG_MANAGER'],
            pageTitle: 'balrogadminApp.device.users.title'
        },
        resolve: {
            'accessesHttp': UserDevicesResolver
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
