import {MdlButtonModule, MdlIconModule, MdlRippleModule, MdlShadowModule, MdlTextFieldModule} from '@angular-mdl/core';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CitySelectComponent} from '@balrog/component/common/city-select/city-select.component';
import {TextEditableComponent} from '@balrog/component/common/text-editable/text-editable.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {InteractiveButtonComponent} from './interactiveButton/interactive-button.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {WifiIconComponent} from './wifi-icon/wifi-icon.component';

@NgModule({
  declarations: [
    InteractiveButtonComponent,
    SpinnerComponent,
    WifiIconComponent,
    TextEditableComponent,
    CitySelectComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    MdlIconModule,
    MdlButtonModule,
    MdlShadowModule,
    MdlRippleModule,
    MdlTextFieldModule,
    NgSelectModule,
  ],
  exports: [
    FormsModule,
    CommonModule,
    MdlIconModule,
    MdlButtonModule,
    MdlShadowModule,
    MdlRippleModule,
    MdlTextFieldModule,
    NgSelectModule,

    InteractiveButtonComponent,
    SpinnerComponent,
    WifiIconComponent,
    TextEditableComponent,
    CitySelectComponent
  ]
})
export class CommonComponentModule { }
