 <div *ngIf="deviceType"> <h2><span jhiTranslate="balrogadminApp.deviceType.detail.title">Device Type</span> {{deviceType.id}}</h2> <hr> <jhi-alert-error></jhi-alert-error> <div class="row"> <div class="col-sm-3"> <jhi-parameter-list [paramsList]="[
                { translation: 'balrogadminApp.deviceType.name', value: deviceType.name },
                { translation: 'balrogadminApp.deviceType.type', value: deviceType.type },
                { translation: 'balrogadminApp.deviceType.regulator', value: deviceType.regulator },
                { translation: 'balrogadminApp.deviceType.vendorCode', value: deviceType.vendorCode }
            ]"></jhi-parameter-list> </div> <div class="col-sm-3"> <jhi-parameter-list [paramsList]="[
                { translation: 'balrogadminApp.deviceType.deviceSchema', customValue: deviceSchema },
                { translation: 'balrogadminApp.deviceType.producer', customValue: producer },
                { translation: 'balrogadminApp.deviceType.distributor', customValue: distributor }
            ]"></jhi-parameter-list> </div> <div class="col-sm-3"> <jhi-parameter-list [paramsList]="[
                { translation: 'notification.options.systemNotificationEmail', value: deviceType.notificationOptions.systemNotificationEmail },
                { translation: 'notification.options.systemNotificationSms', value: deviceType.notificationOptions.systemNotificationSms },
                { translation: 'notification.options.customNotificationEmail', value: deviceType.notificationOptions.customNotificationEmail },
                { translation: 'notification.options.customNotificationSms', value: deviceType.notificationOptions.customNotificationSms }
            ]"></jhi-parameter-list> </div> </div> <ng-template #deviceSchema> <div *ngIf="deviceType.deviceSchemaId"> <a [routerLink]="['/device-schema', deviceType.deviceSchemaId]">{{deviceType.deviceSchemaId}}</a> </div> </ng-template> <ng-template #producer> <div *ngIf="deviceType.producerId"> <a [routerLink]="['/organization', deviceType.producerId]">{{deviceType.producerName}}</a> </div> </ng-template> <ng-template #distributor> <div *ngIf="deviceType.distributorId"> <a [routerLink]="['/organization', deviceType.distributorId]">{{deviceType.distributorName}}</a> </div> </ng-template> <button type="submit" (click)="previousState()" class="btn btn-info"> <fa-icon [icon]="faArrowLeft"></fa-icon>&nbsp;<span jhiTranslate="entity.action.back"> Back</span> </button> <button type="button" [routerLink]="['/', { outlets: { popup: 'device-type/'+ deviceType.id + '/edit'} }]" replaceUrl="true" queryParamsHandling="merge" class="btn btn-primary"> <fa-icon [icon]="faPencilAlt"></fa-icon>&nbsp;<span jhiTranslate="entity.action.edit"> Edit</span> </button> </div> 