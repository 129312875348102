import {MdlDialogReference} from '@angular-mdl/core';
import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '@balrog/core';
import {DataPackage, Setpoint} from '@balrog/core/model/data';
import {Device} from '@balrog/core/model/device';
import {Observable, Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';
import {SetpointChangeModel} from './setpointChange.model';

@Component({
    selector: 'jhi-save-setpoint-dialog',
    templateUrl: 'save-setpoints.dialog.html'
})
export class SaveSetpointsDialogComponent implements OnInit, OnDestroy {
    readonly TIMEOUT_DELAY = 120 * 1000;
    setpoints;
    sendingList: SetpointChangeModel[];
    timeout: any;
    confirmed: number;
    sending: boolean;
    error: boolean;
    confirmationErrorNo = 0;
    device: Device;

    readonly subscription: Subscription;

    constructor(
        private dialog: MdlDialogReference,
        private dataService: DataService,
        @Inject('device') device: Device,
        @Inject('setpoints') setpoints: any,
        @Inject('data$') data$: Observable<DataPackage>
    ) {
        // register a listener if you want to be informed if the dialog is closed.
        this.dialog.onHide().subscribe(() => {
            this.finishedSending();
        });
        this.setpoints = setpoints;
        this.device = device;
        this.sendingList = [];
        this.subscription = data$
            .pipe(skip(1))
            .subscribe((response) => {
                this.validateSendingList(response);
            });
    }
    ngOnInit(): void {
        this.sendingList = this.sendSetpoints(this.setpoints);
    }

    public close() {
        this.finishedSending();
        this.dialog.hide();
    }

    @HostListener('keydown.esc')
    public onEsc(): void {
        this.close();
    }

    private sendSetpoints(setpoints: any): SetpointChangeModel[] {
        const changeList = [];
        const configurationToSend: Array<Setpoint> = [];
        this.confirmed = 0;
        this.sending = true;
        setpoints.forEach((setpoint) => {
            changeList.push(new SetpointChangeModel(setpoint.paramId, setpoint.displayName, setpoint.value, setpoint.changeTo));
            configurationToSend.push(new Setpoint(setpoint.paramId, setpoint.changeTo))
        });
        console.warn('send');
        this.dataService.sendSetpoint(this.device.serialNo, configurationToSend);
        this.timeout = setTimeout(() => {
            this.timeoutExceeded();
        }, this.TIMEOUT_DELAY);

        return changeList;
    }

    private validateSendingList(data: DataPackage) {
        this.confirmed = 0;
        this.sendingList.forEach((change) => {
            if (!change.confirmed) {
                const setpoint = data.data[change.paramId];
                if (setpoint && setpoint.confirmed) {
                    change.confirmed = true;
                    if (String(setpoint.value) !== String(change.sentValue)) {
                        change.error = true;
                        change.status = 'Błąd!';
                        if ('' + String(setpoint.value) !== String(change.baseValue)) {
                            change.msg = 'Użytkownik "' + setpoint.modifiedBy + '" zmienił nastawę';
                        } else {
                            change.msg = 'Nieudana próba zmiany nastawy'
                        }
                        this.confirmationErrorNo++;
                    } else {
                        change.status = 'OK';
                    }
                }
            } else {
                this.confirmed++;
            }
        });
        if (this.confirmed >= this.sendingList.length) {
            this.finishedSending();
        }
    }

    timeoutExceeded() {
        this.finishedSending();
        this.sendingList.forEach((change) => {
            if (!change.status) {
                change.status = 'Brak danych';
            }
        });
        this.error = true;
    }

    finishedSending() {
        this.sending = false;
        this.stopTimeout();
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.stopTimeout();
    }

    stopTimeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = 0;
        }
    }

}
