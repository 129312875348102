import {MdlIconModule, MdlListModule} from '@angular-mdl/core';
import {MdlExpansionPanelModule} from '@angular-mdl/expansion-panel';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {NgxPaginationModule} from 'ngx-pagination';
import {EventBodyComponent} from './components/event-body.component';
import {EventComponent} from './components/event.component';
import {DeviceEventListComponent} from './device-event-list.component';

export * from './components/event.component';
export * from './device-event-list.component';

@NgModule({
  declarations: [
    DeviceEventListComponent,
    EventBodyComponent,
    EventComponent,
  ],
  imports: [
    CommonModule,
    MdlListModule,
    MdlIconModule,
    MdlExpansionPanelModule,
    NgxPaginationModule,
    FontAwesomeModule,
    TranslateModule.forChild()
  ],
  exports: [
    DeviceEventListComponent,
    EventComponent
  ]
})
export class DeviceEventListModule { }

