import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {StatisticsInterface, StatisticsService} from './statistics.service';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'jhi-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StatisticsComponent implements OnInit {
    stats: StatisticsInterface[] = [];

    constructor(
        private statisticsService: StatisticsService
    ) {
    }

    ngOnInit() {
        this.statisticsService.get().subscribe((res: HttpResponse<StatisticsInterface[]>) => {
            Object.keys(res.body).forEach((key) => {
                res.body[key].title = key;
                this.stats.push(res.body[key]);
            });
        });
    }

}
