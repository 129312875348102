import {LOCALE_ID, NgModule} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {registerLocaleData} from '@angular/common';
import locale from '@angular/common/locales/pl';

import {WindowRef} from './tracker/window.service';
import {
    AccessPermissionService,
    AuthorityTranslatePipe,
    BalrogadminSharedLibsModule,
    FindLanguageFromKeyPipe,
    JhiAlertComponent,
    JhiAlertErrorComponent,
    JhiLanguageHelper
} from './';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        BalrogadminSharedLibsModule,
        NgbPaginationModule
    ],
    declarations: [
        FindLanguageFromKeyPipe,
        JhiAlertComponent,
        JhiAlertErrorComponent,
        AuthorityTranslatePipe
    ],
    providers: [
        JhiLanguageHelper,
        WindowRef,
        AccessPermissionService,
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'pl'
        },
    ],
    exports: [
        BalrogadminSharedLibsModule,
        NgbPaginationModule,
        FindLanguageFromKeyPipe,
        JhiAlertComponent,
        JhiAlertErrorComponent,
        AuthorityTranslatePipe
    ]
})
export class BalrogadminSharedCommonModule {
    constructor() {
        registerLocaleData(locale);
    }
}
