import {Component, Input, TemplateRef} from '@angular/core';

@Component({
    selector: 'jhi-parameter-list',
    templateUrl: './parameter-list.component.html'
})
export class ParameterListComponent {
    @Input() paramsList: ParameterInterface[];
}

interface ParameterInterface {
    translation: string,
    value?: any,
    customValue?: TemplateRef<string>,
    authority?
}
