import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot} from '@angular/router';
import {Principal} from '@balrog/core';

import {HomeComponent} from './';

@Injectable()
export class HomeCanActivate implements CanActivate {

    constructor(private router: Router,
                private principal: Principal) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        if (this.principal.isAuthenticated()) {
            this.router.navigate(['start']);
            return false;
        } else {
            return true;
        }
    }
}

export const HOME_ROUTE: Route = {
    path: '',
    component: HomeComponent,
    data: {
        authorities: [],
        pageTitle: 'home.title'
    },
    canActivate: [HomeCanActivate]
};
