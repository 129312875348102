import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {CORE_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';
import {addToRequestOption} from '..';
import {City} from '../model/city.model';

type EntityResponseType = HttpResponse<City>;
type EntityArrayResponseType = HttpResponse<{data: City[]}>;

@Injectable()
export class CityService {

    private resourceUrl =  CORE_API_URL + '/v1/cities';
    private resourceUrlNoAuth =  CORE_API_URL + '/v1/noauth/cities';

    constructor(private http: HttpClient) { }

    create(city: City): Observable<EntityResponseType> {
        const copy = this.convert(city);
        return this.http.post(this.resourceUrl, copy, { observe: 'response' });
    }

    update(city: City): Observable<EntityResponseType> {
        const copy = this.convert(city);
        return this.http.put(this.resourceUrl, copy, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    search(req?: any): Observable<EntityArrayResponseType> {
        const options = addToRequestOption(req, new HttpParams());
        return this.http.get<{data: City[]}>(`${this.resourceUrlNoAuth}/search`, { params: options, observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = addToRequestOption(req, new HttpParams());
        return this.http.get<{data: City[]}>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    /**
     * Convert a City to a JSON which can be sent to the server.
     */
    private convert(city: City): City {
        const copy: City = Object.assign({}, city);
        return copy;
    }
}
