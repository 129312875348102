import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import { JhiPaginationUtil } from 'ng-jhipster';

import { UserRouteAccessService } from '../../shared';
import { DeviceAccessComponent } from './device-access.component';
import { DeviceAccessDetailComponent } from './device-access-detail.component';
import { DeviceAccessPopupComponent } from './device-access-dialog.component';
import { DeviceAccessDeletePopupComponent } from './device-access-delete-dialog.component';

@Injectable()
export class DeviceAccessResolvePagingParams implements Resolve<any> {

    constructor(private paginationUtil: JhiPaginationUtil) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const page = route.queryParams['page'] ? route.queryParams['page'] : '1';
        const sort = route.queryParams['sort'] ? route.queryParams['sort'] : 'id,asc';
        return {
            page: this.paginationUtil.parsePage(page),
            predicate: this.paginationUtil.parsePredicate(sort),
            ascending: this.paginationUtil.parseAscending(sort)
      };
    }
}

export const deviceAccessRoute: Routes = [
    {
        path: 'device-access',
        component: DeviceAccessComponent,
        resolve: {
            'pagingParams': DeviceAccessResolvePagingParams
        },
        data: {
            authorities: ['ROLE_ORG_USER'],
            pageTitle: 'balrogadminApp.deviceAccess.home.title'
        },
        canActivate: [UserRouteAccessService]
    }, {
        path: 'device-access/:id',
        component: DeviceAccessDetailComponent,
        data: {
            authorities: ['ROLE_ORG_USER'],
            pageTitle: 'balrogadminApp.deviceAccess.home.title'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const deviceAccessPopupRoute: Routes = [
    {
        path: 'device-access-new',
        component: DeviceAccessPopupComponent,
        data: {
            authorities: ['ROLE_ORG_ADMIN'],
            pageTitle: 'balrogadminApp.deviceAccess.home.title'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'device-access/:id/edit',
        component: DeviceAccessPopupComponent,
        data: {
            authorities: ['ROLE_ORG_ADMIN'],
            pageTitle: 'balrogadminApp.deviceAccess.home.title'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'device-access/:id/delete',
        component: DeviceAccessDeletePopupComponent,
        data: {
            authorities: ['ROLE_ORG_ADMIN'],
            pageTitle: 'balrogadminApp.deviceAccess.home.title'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
