import { NgModule } from '@angular/core';
import { DeviceStatusIndicatorComponent } from './device-status-indicator.component';
import {BalrogadminSharedModule} from '../../../shared/shared.module';

@NgModule({
    imports: [
        BalrogadminSharedModule
    ],
    declarations: [
        DeviceStatusIndicatorComponent
    ],
    entryComponents: [
        DeviceStatusIndicatorComponent
    ],
    exports: [
        DeviceStatusIndicatorComponent
    ]
})
export class DeviceStatusIndicatorModule {}
