import { BaseEntity } from './../../shared';
import {DeviceSchemaField} from './device-schema-field.model';

export class DeviceSchema implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public lowLevelAdapter?: string,
        public profile?: string,
        public createdDate?: any,
        public createdBy?: string,
        public modifiedDate?: any,
        public modifiedBy?: string,
        public fields?: DeviceSchemaField[],
    ) {
    }
}
