import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CORE_API_URL} from '../../app.constants';

import {DeviceType} from './device-type.model';
import {createRequestOption} from '../../shared';

type EntityResponseType = HttpResponse<DeviceType>;
type EntityArrayResponseType = HttpResponse<DeviceType[]>;

@Injectable()
export class DeviceTypeService {

    private resourceUrl =  CORE_API_URL + '/v1/device-types';

    constructor(private http: HttpClient) { }

    create(deviceType: DeviceType): Observable<EntityResponseType> {
        const copy = this.convert(deviceType);
        return this.http.post(this.resourceUrl, copy, { observe: 'response' });
    }

    update(deviceType: DeviceType): Observable<EntityResponseType> {
        const copy = this.convert(deviceType);
        return this.http.put(this.resourceUrl, copy, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption({size: 50, ...req});
        return this.http.get<DeviceType[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    /**
     * Convert a DeviceType to a JSON which can be sent to the server.
     */
    private convert(deviceType: DeviceType): DeviceType {
        const copy: DeviceType = Object.assign({}, deviceType);
        return copy;
    }
}
