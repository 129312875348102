import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injector} from '@angular/core';
import {Router} from '@angular/router';
import {Principal} from '@balrog/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/internal/operators';

export class AuthExpiredInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    const principal = this.injector.get(Principal);

                    if (principal.isAuthenticated()) {
                        principal.authenticate(null);
                        const router = this.injector.get(Router);
                        router.navigate(['accessdenied']);
                    } else {
                        const router = this.injector.get(Router);
                        router.navigate(['/']);
                    }
                }
            }
        }));
    }
}
