import { NgModule } from '@angular/core';
import { ParameterListComponent } from './parameter-list.component';
import {BalrogadminSharedModule} from '../../shared/shared.module';

@NgModule({
    imports: [
        BalrogadminSharedModule
    ],
    declarations: [
        ParameterListComponent
    ],
    entryComponents: [
        ParameterListComponent
    ],
    exports: [
        ParameterListComponent
    ]
})
export class ParameterListModule {}
