import {HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DeviceAccessService} from '@balrog/core';
import {faArrowLeft, faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {JhiEventManager} from 'ng-jhipster';
import {Subscription} from 'rxjs';

import {DeviceAccess} from './device-access.model';

@Component({
    selector: 'jhi-device-access-detail',
    templateUrl: './device-access-detail.component.html'
})
export class DeviceAccessDetailComponent implements OnInit, OnDestroy {
    faArrowLeft = faArrowLeft;
    faPencilAlt = faPencilAlt;

    deviceAccess: DeviceAccess;
    private subscription: Subscription;
    private eventSubscriber: Subscription;

    constructor(
        private eventManager: JhiEventManager,
        private deviceAccessService: DeviceAccessService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        this.registerChangeInDeviceAccesses();
    }

    load(id) {
        this.deviceAccessService.find(id).subscribe((res: HttpResponse<DeviceAccess>) => {
            this.deviceAccess = res.body;
        });
    }
    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInDeviceAccesses() {
        this.eventSubscriber = this.eventManager.subscribe(
            'deviceAccessListModification',
            (response) => this.load(this.deviceAccess.id)
        );
    }
}
