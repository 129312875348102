import {BriefPanelName, PanelTypeEnum, Unit, VAxisType} from '@balrog/component/common';
import {ProfileInterface} from './interface/profile.interface';

export class MinisterMaxProfile implements ProfileInterface {
    statuses = {
        POMPA_CO: {title: 'Pompa CO'},
        POMPA_CWU: {title: 'Pompa CWU'},
        PODAJNIK: {title: 'Podajnik'},
        DMUCHAWA: {title: 'Dmuchawa'},
        TERMOSTAT: {title: 'Termostat'},
        STYK_TERMOSTATU: {title: 'Styk termostatu'},
        GRZALKA: {title: 'Grzałka'},
        PRACA_KOTLA: {title: 'Praca kotła'}
    };

    // alarmy
    alarms = {
        CZUJNIK_CO: {title: 'Czujnik CO'},
        KOCIOL_PRZEGRZANY: {title: 'Kocioł przegrzany'},
        STB: {title: 'Stb'},
        STB_230V: {title: 'Stb 230V'},
        CZUJNIK_TEMP_SPALIN: {title: 'Czujnik temp. spalin'},
        ROZPALANIE_NIEUDANE: {title: 'Nieudane rozpalanie'},
        CZUJNIK_CWU: {title: 'Czujnik CWU'},
        CO_WARN: {title: 'Tlenek węgla przekroczył poziom ostrzegawczy'},
        CO_ALARM: {title: 'Tlenek węgla przekroczył poziom alarmowy'},
    };

    briefPanel = {
        name: BriefPanelName.ELEKTROMIZ_MINISTER_MAX
    };

    dashBoard = {
        panelType: PanelTypeEnum.ELEKTROMIZ_MINISTER_MAX
    };

    historyBoard = {
        title: 'Temperatura [°C]',
        content: [{
            paramId: 'TEMP_KOTLA',
            unit: Unit.C
        }, {
            paramId: 'NST_KOTLA',
            unit: Unit.C,
            type: 'stepped'
        }, {
            paramId: 'TEMP_BOILERA',
            unit: Unit.C
        }, {
            paramId: 'NST_BOILERA',
            unit: Unit.C,
            type: 'stepped'
        }, {
            paramId: 'TEMP_SPALIN',
            unit: Unit.C
        }, {
            paramId: 'MOC_KOTLA',
            unit: Unit.PERCENTAGE,
            type: 'stepped',
            vAxisType: VAxisType.POWER_PERCENTAGE.valueOf()
        }, {
            paramId: 'TEMP_POKOJ',
            unit: Unit.C
        }, {
            paramId: 'ZUZYCIE_PALIWA_WCZORAJ'
        }, {
            paramId: 'CO',
            unit: Unit.PPM,
            vAxisType: VAxisType.PPM.valueOf()
        }]
    };

    listBoard = {
        title: 'Konfiguracja',
        content: [
            {
                title: 'Ogólne parametry',
                type: 'MENU',
                content: [{
                    paramId: 'NST_KOTLA',
                    unit: Unit.C
                }, {
                    paramId: 'NST_BOILERA',
                    unit: Unit.C
                }, {
                    paramId: 'TEMP_ZAWOR_1',
                    unit: Unit.C
                }, {
                    paramId: 'NST_ZAWOR_1',
                    unit: Unit.C
                }, {
                    paramId: 'TEMP_ZEWN',
                    unit: Unit.C
                }, {
                    paramId: 'MOC_KOTLA',
                    unit: Unit.PERCENTAGE
                }, {
                    paramId: 'TEMP_POKOJ',
                    unit: Unit.C
                }, {
                    paramId: 'NST_POKOJ',
                    unit: Unit.C
                }, {
                    paramId: 'ZUZYCIE_PALIWA',
                    step: 0.1,
                    unit: Unit.KG
                }, {
                    paramId: 'NST_ZUZYCIA_PALIWA',
                    unit: Unit.KGH
                }, {
                    type: 'ITEM-TEXT',
                    paramId: 'WERSJA_STEROWNIKA'
                }]
            }, {
                paramId: 'CO',
                unit: Unit.PPM
            }, {
                title: 'Parametry pracy',
                type: 'MENU',
                content: [{
                    paramId: 'NST_CZAS_PRACA',
                    step: 0.1,
                    unit: Unit.SEC
                }, {
                    paramId: 'NST_CZAS_PAUZA',
                    unit: Unit.SEC
                }, {
                    paramId: 'NST_LIMIT_DMUCHAWY',
                    unit: Unit.PERCENTAGE
                }, {
                    paramId: 'NST_MOC_DMUCHAWY_ROZPALANIE',
                    unit: Unit.PERCENTAGE
                }, {
                    paramId: 'NST_ROZPALANIE_CZAS',
                    unit: Unit.MIN
                }, {
                    paramId: 'NST_ROZPALANIE_CZAS_PRACA',
                    unit: Unit.SEC
                }, {
                    paramId: 'NST_ROZPALANIE_TRYB_WEGLOWY',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'nieaktywne'}, {value: 'true', name: 'aktywne'}]
                }, {
                    paramId: 'TEMP_WYL_KOTLA',
                    unit: Unit.C
                }, {
                    paramId: 'NST_TEMP_WL_POMPY_CO',
                    unit: Unit.C
                }, {
                    paramId: 'NST_HISTEREZA_CWU',
                    unit: Unit.C
                }, {
                    paramId: 'NST_CZAS_PRACY_POMPY_1',
                    unit: Unit.MIN
                }, {
                    paramId: 'NST_CZAS_PAUZY_POMPY_1',
                    unit: Unit.MIN
                }, {
                    paramId: 'NST_CZAS_WYGASZANIA',
                    unit: Unit.SEC
                }, {
                    paramId: 'WYBRANA_TEMP_CO',
                    unit: Unit.C
                }, {
                    paramId: 'WYBRANA_TEMP_CWU',
                    unit: Unit.C
                }, {
                    paramId: 'TEMP_CZUJ_POGOD',
                    unit: Unit.C
                }, {
                    title: 'Konfiguracja Bufora',
                    type: 'MENU',
                    content: [{
                        paramId: 'NST_TEMP_BUFOR',
                        unit: Unit.C
                    }, {
                        paramId: 'NST_HISTEREZA_BUFOR',
                        unit: Unit.C
                    }, {
                        paramId: 'TEMP_BUFOR',
                        step: 0.1,
                        unit: Unit.C
                    }, {
                        paramId: 'TRYB_PRACY_POMPY_BUFORA',
                        type: 'ITEM-SELECT',
                        readonly: true,
                        options: [
                            {value: '0', name: 'Aktywna cały czas'},
                            {value: '1', name: 'Histereza'}
                        ]
                    }]
                }, {
                    paramId: 'NST_MIN_MOC_KOTLA',
                    unit: Unit.PERCENTAGE
                }, {
                    paramId: 'NST_OBNIZENIA_ZAWOR_GRZEJNIK',
                    unit: Unit.C
                }, {
                    paramId: 'NST_STALA_CZASOWA',
                    unit: Unit.SEC
                }, {
                    paramId: 'NST_CZAS_PODAWANIA_WSTEPNEGO',
                    unit: Unit.SEC
                }, {
                    paramId: 'NST_CO_ILE_CZYSZCZENIE_KOMORY_KG',
                    unit: Unit.KG
                }, {
                    paramId: 'NST_HISTEREZA_GORNA_KOTLA',
                    unit: Unit.C
                }, {
                    paramId: 'NST_MOC_DMUCHAWY_MIN',
                    unit: Unit.PERCENTAGE
                }, {
                    paramId: 'NST_TEMP_WYL_OBIEGOW_CZUJNIK_ZEWN',
                    unit: Unit.C
                }, {
                    paramId: 'NST_CZUJNIK_ZEWN_AKTYWNE_OBIEGI',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Obieg grzejnikowy'},
                        {value: '1', name: 'Obieg podłogowy'},
                        {value: '1', name: 'Oba obiegi aktywne'}
                    ]
                }]
            }, {
                title: 'Konfiguracja podtrzymania',
                type: 'MENU',
                content: [{
                    paramId: 'NST_PODT_CZAS_PAUZA_PRZEDMUCHU',
                    unit: Unit.MIN
                },{
                    paramId: 'NST_PODT_CZAS_PRZEDMUCHU',
                    unit: Unit.SEC
                },{
                    paramId: 'NST_PODT_CZAS_PRACA',
                    unit: Unit.SEC
                },{
                    paramId: 'NST_PODT_LICZBA_PRZEDMUCHOW'
                },{
                    paramId: 'NST_PODT_TEMP_WYL_KOTLA',
                    unit: Unit.C
                },{
                    paramId: 'NST_PODT_MOC_DMUCHAWY',
                    unit: Unit.PERCENTAGE
                }]
            }, {
                title: 'Nastawy temp. za zaworem',
                type: 'MENU',
                content: [{
                    title: 'Czujnik pogodowy',
                    type: 'MENU',
                    content: [{
                        paramId: 'NST_TEMP_ZA_ZAWOR_POGODA_M10C',
                        unit: Unit.C
                    }, {
                        paramId: 'NST_TEMP_ZA_ZAWOR_POGODA_0C',
                        unit: Unit.C
                    }, {
                        paramId: 'NST_TEMP_ZA_ZAWOR_POGODA_10C',
                        unit: Unit.C
                    }, {
                        paramId: 'NST_TEMP_ZA_ZAWOR_CZUJ_POGOD_AKTYWNY',
                        type: 'ITEM-SWITCH',
                        options: [{value: 'false', name: 'nieaktywne'}, {value: 'true', name: 'aktywne'}]
                    }]
                }, {
                    paramId: 'NST_TEMP_ZA_ZAWOR_PODLOGOWY',
                    unit: Unit.C
                }, {
                    paramId: 'NST_OBNIZENIA_ZAWOR_PODLOGOWY',
                    unit: Unit.C
                }, {
                    paramId: 'TEMP_ZA_ZAWOR_PODLOGOWY',
                    unit: Unit.C
                }]
            }, {
                title: 'Nastawy temp. za zaworem podlogowym',
                type: 'MENU',
                content: [{
                    title: 'Czujnik pogodowy',
                    type: 'MENU',
                    content: [{
                        paramId: 'NST_TEMP_ZA_ZAWOR_PODLOG_POGODA_M10C',
                        unit: Unit.C
                    }, {
                        paramId: 'NST_TEMP_ZA_ZAWOR_PODLOG_POGODA_0C',
                        unit: Unit.C
                    }, {
                        paramId: 'NST_TEMP_ZA_ZAWOR_PODLOG_POGODA_10C',
                        unit: Unit.C
                    }]
                }]
            }, {
                title: 'Solar',
                type: 'MENU',
                content: [{
                    paramId: 'TEMP_SOLAR',
                    step: 0.1,
                    unit: Unit.C
                }, {
                    paramId: 'NST_POMPA_SOLARNA',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'nieaktywne'}, {value: 'true', name: 'aktywne'}]
                }, {
                    paramId: 'NST_DELTA_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'NST_PRZEPLYW_SOLAR',
                    unit: Unit.M3H
                }, {
                    paramId: 'NST_TEMP_ZRZUTU',
                    unit: Unit.C
                }, {
                    paramId: 'MOC_CHWILOWA',
                    step: 0.1,
                    unit: Unit.KW
                }, {
                    paramId: 'MOC_CALKOWITA',
                    step: 0.001,
                    unit: Unit.KWH
                }]
            }, {
                title: 'Czasy kontrolne',
                type: 'MENU',
                content: [{
                    paramId: 'NST_AKTYWNY_CZAS_KONTROLNY_CO',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'nieaktywne'}, {value: 'true', name: 'aktywne'}]
                }, {
                    paramId: 'NST_AKTYWNY_CZAS_KONTROLNY_CWU',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'nieaktywne'}, {value: 'true', name: 'aktywne'}]
                }, {
                    paramId: 'NST_CZAS_KONTROLNY_CO_CZAS_DZIEN',
                    unit: Unit.HOUR
                }, {
                    paramId: 'NST_CZAS_KONTROLNY_CO_CZAS_NOC',
                    unit: Unit.HOUR
                }, {
                    paramId: 'NST_CZAS_KONTROLNY_CO_TEMP_DZIEN',
                    unit: Unit.C
                }, {
                    paramId: 'NST_CZAS_KONTROLNY_CO_TEMP_NOC',
                    unit: Unit.C
                }, {
                    paramId: 'NST_CZAS_KONTROLNY_CWU_CZAS_DZIEN',
                    unit: Unit.HOUR
                }, {
                    paramId: 'NST_CZAS_KONTROLNY_CWU_CZAS_NOC',
                    unit: Unit.HOUR
                }, {
                    paramId: 'NST_CZAS_KONTROLNY_CWU_TEMP_DZIEN',
                    unit: Unit.C
                }, {
                    paramId: 'NST_CZAS_KONTROLNY_CWU_TEMP_NOC',
                    unit: Unit.C
                }]
            }, {
                title: 'Status kotła 2',
                type: 'MENU',
                content: [{
                    paramId: 'KOCIOL2_POMPA_PODLOGOWA',
                    type: 'ITEM-SELECT',
                    readonly: true,
                    options: [
                        {value: '0', name: 'Wył'},
                        {value: '1', name: 'Pracuje'}
                    ]
                }, {
                    paramId: 'KOCIOL2_POMPA_KOM_SOLAR',
                    type: 'ITEM-SELECT',
                    readonly: true,
                    options: [
                        {value: '0', name: 'Wył'},
                        {value: '1', name: 'Pracuje'}
                    ]
                }, {
                    paramId: 'KOCIOL2_TERMOSTAT',
                    type: 'ITEM-SELECT',
                    readonly: true,
                    options: [
                        {value: '0', name: 'Nieaktywny'},
                        {value: '1', name: 'Aktywny'}
                    ]
                }, {
                    paramId: 'KOCIOL2_STYK_TERMOSTATU',
                    type: 'ITEM-SELECT',
                    readonly: true,
                    options: [
                        {value: '0', name: 'Rozwarty'},
                        {value: '1', name: 'Zwarty'}
                    ]
                }, {
                    paramId: 'KOCIOL2_PRIORYTET_GRZANIA_BUFORA',
                    type: 'ITEM-SELECT',
                    readonly: true,
                    options: [
                        {value: '0', name: 'Nieaktywny'},
                        {value: '1', name: 'Aktywny'}
                    ]
                }]
            }, {
                title: 'Strefy czasowe pompa cyrkulacyjna',
                type: 'MENU',
                content: [{
                    paramId: 'AKTYWNA_STREFA_CZAS_1_I_2',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'nieaktywne'}, {value: 'true', name: 'aktywne'}]
                }, {
                    paramId: 'AKTYWNA_STREFA_CZAS_3_I_4',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'nieaktywne'}, {value: 'true', name: 'aktywne'}]
                }, {
                    paramId: 'STREFA_CZAS_POMP_CYRK_1_WL_H'
                }, {
                    paramId: 'STREFA_CZAS_POMP_CYRK_1_WYL_H'
                }, {
                    paramId: 'STREFA_CZAS_POMP_CYRK_2_WL_H'
                }, {
                    paramId: 'STREFA_CZAS_POMP_CYRK_2_WYL_H'
                }, {
                    paramId: 'STREFA_CZAS_POMP_CYRK_3_WL_H'
                }, {
                    paramId: 'STREFA_CZAS_POMP_CYRK_3_WYL_H'
                }, {
                    paramId: 'STREFA_CZAS_POMP_CYRK_4_WL_H'
                }, {
                    paramId: 'STREFA_CZAS_POMP_CYRK_4_WYL_H'
                }]
            }, {
                title: 'Termomiz',
                type: 'MENU',
                content: [{
                    paramId: 'WERSJA_TERMOMIZ'
                }, {
                    paramId: 'ID_TERMOMIZ'
                }, {
                    title: 'Termomiz grzejniki',
                    type: 'MENU',
                    content: [{
                        paramId: 'NST_TERMOMIZ_GRZ',
                        unit: Unit.C
                    }, {
                        paramId: 'NST_TERMOMIZ_GRZ_HISTEREZA',
                        step: 0.1,
                        unit: Unit.C
                    }, {
                        paramId: 'NST_TERMOMIZ_GRZ_JASNOSC'
                    }, {
                        paramId: 'TERMOMIZ_GRZ_TEMP',
                        step: 0.1,
                        unit: Unit.C
                    }, {
                        paramId: 'TERMOMIZ_GRZ_TEMP',
                        step: 0.1,
                        unit: Unit.C
                    }, {
                        paramId: 'TERMOMIZ_GRZ_WILGOTNOSC',
                        unit: Unit.PERCENTAGE
                    }, {
                        paramId: 'TERMOMIZ_GRZ_POZIOM_BATERII',
                        unit: Unit.PERCENTAGE
                    }, {
                        paramId: 'TERMOMIZ_GRZ_ID'
                    }, {
                        paramId: 'TERMOMIZ_GRZ_SILA_SYGNALU',
                        unit: Unit.PERCENTAGE
                    }, {
                        paramId: 'TERMOMIZ_GRZ_WERSJA_PROGRAMU'
                    }, {
                        paramId: 'TERMOMIZ_GRZ_POZIOM_SWIATLA',
                        unit: Unit.LUX
                    }, {
                        title: 'Strefy czasowe',
                        type: 'MENU',
                        content: [{
                            paramId: 'TERMOMIZ_GRZ_STREFA_CZAS_AKTYWNA',
                            type: 'ITEM-SWITCH',
                            options: [{value: 'false', name: 'nieaktywne'}, {value: 'true', name: 'aktywne'}]
                        }, {
                            paramId: 'TERMOMIZ_GRZ_STREFA_CZAS_CZAS_DZIEN',
                            unit: Unit.HOUR
                        }, {
                            paramId: 'TERMOMIZ_GRZ_STREFA_CZAS_CZAS_NOC',
                            unit: Unit.HOUR
                        }, {
                            paramId: 'TERMOMIZ_GRZ_STREFA_CZAS_TEMP_DZIEN',
                            unit: Unit.C
                        }, {
                            paramId: 'TERMOMIZ_GRZ_STREFA_CZAS_TEMP_NOC',
                            unit: Unit.C
                        }]
                    }]
                }, {
                    title: 'Termomiz podłoga',
                    type: 'MENU',
                    content: [{
                        paramId: 'NST_TERMOMIZ_PODL',
                        unit: Unit.C
                    }, {
                        paramId: 'NST_TERMOMIZ_PODL_HISTEREZA',
                        step: 0.1,
                        unit: Unit.C
                    }, {
                        paramId: 'NST_TERMOMIZ_PODL_JASNOSC'
                    }, {
                        paramId: 'TERMOMIZ_PODL_TEMP',
                        step: 0.1,
                        unit: Unit.C
                    }, {
                        paramId: 'TERMOMIZ_PODL_TEMP',
                        step: 0.1,
                        unit: Unit.C
                    }, {
                        paramId: 'TERMOMIZ_PODL_WILGOTNOSC',
                        unit: Unit.PERCENTAGE
                    }, {
                        paramId: 'TERMOMIZ_PODL_POZIOM_BATERII',
                        unit: Unit.PERCENTAGE
                    }, {
                        paramId: 'TERMOMIZ_PODL_ID'
                    }, {
                        paramId: 'TERMOMIZ_PODL_SILA_SYGNALU',
                        unit: Unit.PERCENTAGE
                    }, {
                        paramId: 'TERMOMIZ_PODL_WERSJA_PROGRAMU'
                    }, {
                        paramId: 'TERMOMIZ_PODL_POZIOM_SWIATLA',
                        unit: Unit.LUX
                    }, {
                        title: 'Strefy czasowe',
                        type: 'MENU',
                        content: [{
                            paramId: 'TERMOMIZ_PODL_STREFA_CZAS_AKTYWNA',
                            type: 'ITEM-SWITCH',
                            options: [{value: 'false', name: 'nieaktywne'}, {value: 'true', name: 'aktywne'}]
                        }, {
                            paramId: 'TERMOMIZ_PODL_STREFA_CZAS_CZAS_DZIEN',
                            unit: Unit.HOUR
                        }, {
                            paramId: 'TERMOMIZ_PODL_STREFA_CZAS_CZAS_NOC',
                            unit: Unit.HOUR
                        }, {
                            paramId: 'TERMOMIZ_PODL_STREFA_CZAS_TEMP_DZIEN',
                            unit: Unit.C
                        }, {
                            paramId: 'TERMOMIZ_PODL_STREFA_CZAS_TEMP_NOC',
                            unit: Unit.C
                        }]
                    }]
                }]
            }, {
                paramId: 'NOW_TIME',
                type: 'ITEM-TEXT',
                placeholder: 'HH:MM'
            }, {
                paramId: 'NOW_DATE',
                type: 'ITEM-TEXT',
                placeholder: 'yyyy-mm-dd'
            }]
    };

    scheduleBoard = null;
}
