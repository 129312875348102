import {Component, OnInit} from '@angular/core';
import {EmailModel, NotificationModel, NotificationService, NotificationStatus, NotificationType, SmsModel, TargetType} from 'app/management';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceType, DeviceTypeService} from 'app/entities/device-type';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {JhiAlertService} from 'ng-jhipster';
import {MdlDatePickerService} from '@angular-mdl/datepicker';
import moment from 'moment/src/moment';
import {calculateSms, SmsDetails} from 'app/shared/util/sms-count.util';

@Component({
    selector: 'jhi-notification-modify',
    templateUrl: 'notification-modify.component.html',
    styleUrls: ['notification-modify.component.scss']
})
export class NotificationModifyComponent implements OnInit {

    readonly runTimeOptions = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00',
        '11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00']

    TargetType= TargetType;
    targetTypes = Object.keys(TargetType);
    NotificationType= NotificationType;
    notificationTypes = Object.keys(NotificationType);
    NotificationStatus = NotificationStatus;

    public notification: NotificationModel;
    public email: EmailModel;
    public sms: SmsModel;
    public success = false;
    public deviceTypes: Array<DeviceType>;
    public isModify = false;
    public smsDetails: SmsDetails;
    public smsMaxLength = 459;
    public audit : {created?: string, createdHint?: string, modified?: string, modifiedHint?: string} = {};
    public isReadonly = true;
    public period: number;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private jhiAlertService: JhiAlertService,
        private deviceTypeService: DeviceTypeService,
        private notificationService: NotificationService,
        private datePicker: MdlDatePickerService
    ) {
    }

    ngOnInit() {
        const routeParams = this.route.snapshot.paramMap;
        const notificationId = routeParams.get('id');
        if (notificationId) {
            this.loadNotification(notificationId);
            this.isModify = true;
        } else {
            this.createDefaultNotification();
            this.isReadonly = false;
        }
        this.deviceTypeService.query()
            .subscribe((res: HttpResponse<any>) => { this.deviceTypes = res.body; }, (res: HttpErrorResponse) => this.onError(res));
    }

    save() {
        console.warn('SaveNotification');
        if (this.notification.id) {
            this.notificationService.update(this.prepareToSaveNotification()).subscribe();
        } else {
            this.notificationService.create(this.prepareToSaveNotification()).subscribe((notification) => {
                this.router.navigate(['/manage/notification/' + notification.id]);
            });
        }
    }

    trackDeviceTypeById(index: number, item: DeviceType) {
        return item.id;
    }

    public pickAStartDate($event: MouseEvent) {
        this.datePicker.selectDate(this.notification.startDate, {openFrom: $event}).subscribe( (selectedDate: Date) => {
            this.notification.startDate = selectedDate ? moment(selectedDate) : null;
        });
    }

    public pickAEndDate($event: MouseEvent) {
        this.datePicker.selectDate(this.notification.endDate, {openFrom: $event}).subscribe( (selectedDate: Date) => {
            this.notification.endDate = selectedDate ? moment(selectedDate) : null;
        });
    }

    public pickARunDate($event: MouseEvent) {
        this.datePicker.selectDate(this.notification.runDate, {openFrom: $event}).subscribe( (selectedDate: Date) => {
            this.notification.runDate = selectedDate ? moment(selectedDate) : null;
        });
    }

    onSmsChange(content: string) {
        this.smsDetails = calculateSms(content);
        this.smsMaxLength = this.smsDetails.encoding==='UTF16' ? 201 : 459;
        if (content.length > this.smsMaxLength) {
            setTimeout(() => (this.sms.content = content.substring(0, this.smsMaxLength)), 10);
        }
    }

    private loadNotification(notificationId: string) {
        this.notificationService.find(notificationId).subscribe((notification) => {
            this.notification = notification;
            this.notification.startDate = moment(notification.startDate);
            this.notification.endDate = notification.endDate ? moment(notification.endDate) : null;
            this.notification.runDate = moment(notification.runDate);
            this.email = notification.emailContent!=='null' ? JSON.parse(notification.emailContent) : {title: '', content: ''};
            this.sms = notification.smsContent!=='null' ? JSON.parse(notification.smsContent) : {content: ''};
            this.audit =  {
                created: moment(notification.createdDate).format('YYYY-MM-DD') + ' - ' + notification.createdBy,
                createdHint: moment(notification.createdDate).format('YYYY-MM-DD HH:mm:ss') + ' - ' + notification.createdBy,
                modified: moment(notification.modifiedDate).format('YYYY-MM-DD') + ' - ' + notification.modifiedBy,
                modifiedHint: moment(notification.modifiedDate).format('YYYY-MM-DD HH:mm:ss') + ' - ' + notification.modifiedBy,
            }
            this.notification.runTime = this.adjustTimeZone(notification.runTime, true);
            this.period = this.periodToDays(notification.period);
            if (notification.status !== NotificationStatus.DELETED && notification.status !== NotificationStatus.FINISHED) {
                this.isReadonly = false;
            }
        })
    }

    private createDefaultNotification() {
        this.notification = {
            status: NotificationStatus.CREATED,
            startDate: moment(Date.now()),
            runDate: moment(Date.now()),
            targetType: TargetType.DEVICE_TYPE,
            runTime: '12:00'
        }
        this.period = 1;
        this.email = {title: '', content: ''};
        this.sms = {content: ''};
    }

    private prepareToSaveNotification(): NotificationModel {
        const clonedNotification = JSON.parse(JSON.stringify(this.notification));
        clonedNotification.smsContent = JSON.stringify(this.sms);
        clonedNotification.emailContent = JSON.stringify(this.email);
        clonedNotification.runTime = this.adjustTimeZone(this.notification.runTime, false);
        clonedNotification.period = this.daysToPeriod(this.period);
        return clonedNotification;
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null);
    }

    private periodToDays(period: string): number {
        try {
            return Number(period.substring(1, period.length - 1))
        } catch (e) {
            return 1;
        }
    }

    private daysToPeriod(days: number): string {
        return `P${days}D`;
    }

    private adjustTimeZone(runTime: string, addZone: boolean): string {
        let index = this.runTimeOptions.findIndex(o => o === runTime);
        const offsetInMin = new Date().getTimezoneOffset();
        const offset = offsetInMin ? offsetInMin/60 : 0;
        if (addZone) {
            index -= offset;
        } else {
            index += offset;
        }
        if (index < 0) {
            index+= this.runTimeOptions.length
        } else if (index >= this.runTimeOptions.length) {
            index-= this.runTimeOptions.length - 1;
        }
        return this.runTimeOptions[index];
    }
}
