import { NgModule } from '@angular/core';
import { FilterTextComponent } from './filter-text.component';
import {BalrogadminSharedModule} from '../../../shared/shared.module';

@NgModule({
    imports: [
        BalrogadminSharedModule
    ],
    declarations: [
        FilterTextComponent
    ],
    entryComponents: [
        FilterTextComponent
    ],
    exports: [
        FilterTextComponent
    ]
})
export class FilterTextModule {}
