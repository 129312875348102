import {BaseEntity} from './../../shared';
import {NamedBaseEntity} from 'app/shared/model/named-base-entity';

export class Group implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public accessLevel?: number,
        public users?: BaseEntity[],
        public deviceTypes?: NamedBaseEntity[],
        public organizationId?: number,
        public organizationName?: string,
    ) {
    }
}
