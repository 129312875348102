import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';

import {Observable} from 'rxjs';
import {CORE_CONFIG, CoreConfig} from '../core.config';
import {WINDOW} from '../internal/window.provider';

interface RedirectResponse {
    redirectUri: string;
}

@Injectable()
export class CasLoginService {

  private readonly coreApiUrl: string = '';

  constructor(
    private http: HttpClient,
    @Inject(WINDOW) private window: Window,
    @Optional() @Inject(CORE_CONFIG) config: CoreConfig,
  ) {
    if (config) {
      this.coreApiUrl = config.coreApiUrl;
    }
  }

    login() {
        this.http.get<RedirectResponse>(this.window.location.origin + '/auth/authorize').subscribe((response) =>{
            window.location.href = response.redirectUri;
        });
    }

    token(code: string): Observable<any> {
        return this.http.post( 'auth/token', {code}, {});
    }

    logout() {
        this.http.get<RedirectResponse>(this.window.location.origin + '/auth/logout').subscribe((response) =>{
            window.location.href = response.redirectUri;
        });
    }
}
