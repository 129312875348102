import {BriefPanelName, PanelTypeEnum, Unit, VAxisType} from '@balrog/component/types';
import {AccessPermissionEnum} from '@balrog/core/model/const';
import {ProfileInterface} from './interface/profile.interface';

export class Haba3100v2Profile implements ProfileInterface {
    statuses = {};

    alarms = {
        ALARM_PRZEPELNIENIE: {title: 'Awaria przepełnienie'},
        ALARM_DMUCHAWA1: {title: 'Awaria prądu dmuchawy'}
    };

    briefPanel = {
        name: BriefPanelName.HABA_MULTIBIO_3100
    };

    dashBoard = {
        panelType: PanelTypeEnum.HABA_MULTIBIO,
        panelConfig: {
            mode: 'MULTIBIO_3100v2'
        }
    };

    historyBoard = {
        title: 'Godzin [h]',
        content: [{
            paramId: 'LICZ_CZAS_PRACY_D1',
            unit: Unit.HOUR,
            vAxisType: VAxisType.HOURS.valueOf()
        }, {
            paramId: 'LICZ_CZAS_PRACY_P1',
            unit: Unit.HOUR,
            vAxisType: VAxisType.HOURS.valueOf()
        }, {
            paramId: 'LICZ_CZAS_PRACY_P2',
            unit: Unit.HOUR,
            vAxisType: VAxisType.HOURS.valueOf()
        }, {
            paramId: 'LICZ_CZAS_PRACY_X1',
            unit: Unit.HOUR,
            vAxisType: VAxisType.HOURS.valueOf()
        }]
    };

    listBoard = {
        title: 'Konfiguracja urzadzenia',
        content: [{
            paramId: 'PIN_SERWIS',
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
        }, {
            paramId: 'AKTYWNY_ZESTAW',
            type: 'ITEM-SELECT',
            options: [{value: 1, name: 'Zestaw 1'}, {value: 2, name: 'Zestaw 2'}, {value: 3, name: 'Zestaw 3'}],
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
        }, {
            type: 'ITEM-TEXT',
            paramId: 'WERSJA_STEROWNIKA',
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
        }, {
            paramId: 'ZERUJ_LICZNIKI_FLASH',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'Zeruj'}],
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
        }, {
            title: 'Liczniki',
            type: 'MENU',
            content: [{
                type: 'ITEM-TEXT',
                paramId: 'LICZ_CZAS_PRACY_D1'
            }, {
                type: 'ITEM-TEXT',
                paramId: 'LICZ_CZAS_PRACY_P1'
            }, {
                type: 'ITEM-TEXT',
                paramId: 'LICZ_CZAS_PRACY_P2'
            }, {
                type: 'ITEM-TEXT',
                paramId: 'LICZ_CZAS_PRACY_X1'
            }]
        }, {
            title: 'Urlop',
            type: 'MENU',
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
            content: [{
                paramId: 'CZAS_SEDYM_URLOP',
                unit: Unit.MIN,
            }, {
                paramId: 'POMPA_P2_AWARYJNIE_URLOP',
                unit: Unit.MIN,
            }, {
                paramId: 'URLOP_PRACA'
            }, {
                paramId: 'URLOP_POSTOJ'
            }, {
                type: 'ITEM-TEXT',
                paramId: 'URLOP_D1_PRZERWA_START'
            }, {
                type: 'ITEM-TEXT',
                paramId: 'URLOP_D1_PRZERWA_STOP'
            }]
        }
        , {
            title: 'Ustawienia zestawu 1',
            type: 'MENU',
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
            content: [{
                paramId: 'CZAS_SEDYM_Z1',
                unit: Unit.MIN,
            }, {
                paramId: 'CZAS_DO_URLOP_Z1',
                unit: Unit.HOUR,
            }, {
                paramId: 'POMPA_P2_AWARYJNIE_Z1',
                unit: Unit.MIN,
            }, {
                title: 'Ustawienia czasów',
                type: 'MENU',
                content: [{
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_D1_Z1_START_ZAKRES',
                    paramIdStop: 'CZAS_D1_Z1_STOP_ZAKRES'
                }, {
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_P1_Z1_START_ZAKRES',
                    paramIdStop: 'CZAS_P1_Z1_STOP_ZAKRES'
                }, {
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_P2_Z1_START_ZAKRES',
                    paramIdStop: 'CZAS_P2_Z1_STOP_ZAKRES'
                }, {
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_X1_Z1_START_ZAKRES',
                    paramIdStop: 'CZAS_X1_Z1_STOP_ZAKRES'
                }]
            }
            ]
        }, {
            title: 'Ustawienia zestawu 2',
            type: 'MENU',
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
            content: [{
                paramId: 'CZAS_SEDYM_Z2',
                unit: Unit.MIN,
            }, {
                paramId: 'CZAS_DO_URLOP_Z2',
                unit: Unit.HOUR,
            }, {
                paramId: 'POMPA_P2_AWARYJNIE_Z2',
                unit: Unit.MIN,
            }, {
                title: 'Ustawienia czasów',
                type: 'MENU',
                content: [{
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_D1_Z2_START_ZAKRES',
                    paramIdStop: 'CZAS_D1_Z2_STOP_ZAKRES'
                }, {
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_P1_Z2_START_ZAKRES',
                    paramIdStop: 'CZAS_P1_Z2_STOP_ZAKRES'
                }, {
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_P2_Z2_START_ZAKRES',
                    paramIdStop: 'CZAS_P2_Z2_STOP_ZAKRES'
                }, {
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_X1_Z2_START_ZAKRES',
                    paramIdStop: 'CZAS_X1_Z2_STOP_ZAKRES'
                }]
            }]
        }, {
            title: 'Ustawienia zestawu 3',
            type: 'MENU',
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
            content: [{
                paramId: 'CZAS_SEDYM_Z3',
                unit: Unit.MIN,
            }, {
                paramId: 'CZAS_DO_URLOP_Z3',
                unit: Unit.HOUR,
            }, {
                paramId: 'POMPA_P2_AWARYJNIE_Z3',
                unit: Unit.MIN,
            }, {
                title: 'Ustawienia czasów',
                type: 'MENU',
                content: [{
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_D1_Z3_START_ZAKRES',
                    paramIdStop: 'CZAS_D1_Z3_STOP_ZAKRES'
                }, {
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_P1_Z3_START_ZAKRES',
                    paramIdStop: 'CZAS_P1_Z3_STOP_ZAKRES'
                }, {
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_P2_Z3_START_ZAKRES',
                    paramIdStop: 'CZAS_P2_Z3_STOP_ZAKRES'
                }, {
                    type: 'ARRAY_RANGE',
                    paramIdStart: 'CZAS_X1_Z3_START_ZAKRES',
                    paramIdStop: 'CZAS_X1_Z3_STOP_ZAKRES'
                }]
            }]
        }
        ]
    };

    scheduleBoard = null;
}
