import {Routes} from '@angular/router';

import {configurationRoute, gatewayRoute, healthRoute, logsRoute, metricsRoute, userDialogRoute} from './';

import {UserRouteAccessService} from '../shared';

const ADMIN_ROUTES = [
    configurationRoute,
    healthRoute,
    logsRoute,
    gatewayRoute,
    metricsRoute
];

export const adminState: Routes = [{
    path: '',
    data: {
        authorities: ['ROLE_ADMIN']
    },
    canActivate: [UserRouteAccessService],
    children: ADMIN_ROUTES
},
    ...userDialogRoute
];
