import {BaseEntity} from './../../shared';
import {Organization} from '../organization/organization.model';

export class Device implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public serialNo?: string,
        public regKey?: string,
        public authKey?: string,
        public version?: string,
        public status?: string,
        public lastPing?: any,
        public rssi?: any,
        public state?: any,
        public description?: string,
        public city?: string,
        public createdDate?: any,
        public createdBy?: string,
        public modifiedDate?: any,
        public modifiedBy?: string,
        public deviceAccesses?: BaseEntity[],
        public deviceTypeId?: number,
        public deviceTypeName?: string,
        public organizations?: Organization[],
        public countryId?: number,
        public regionId?: number,
        public usersCount?: number,
    ) {
    }
}
