import {BriefPanelName, PanelTypeEnum, Unit} from '@balrog/component/types';
import {AccessPermissionEnum} from '@balrog/core/model/const';
import {ProfileInterface} from './interface/profile.interface';

export class HabaMultiBio2000Profile implements ProfileInterface {

    statuses = {};

    alarms = {
        ALARM_PRZEPELNIENIE: {title: 'Awaria przepełnienie'}
    };

    briefPanel = {
        name: BriefPanelName.HABA_MULTIBIO_2000
    };

    dashBoard = {
        panelType: PanelTypeEnum.HABA_MULTIBIO,
        panelConfig: {
            mode: 'MULTIBIO_2000'
        }
    };

    historyBoard = null;

    listBoard = {
        title: 'Konfiguracja urzadzenia',
        content: [{
            paramId: 'AKTYWNY_ZESTAW',
            type: 'ITEM-SELECT',
            options: [
              {value: 1, name: 'Zestaw 1'},
              {value: 2, name: 'Zestaw 2'},
              {value: 3, name: 'Zestaw 3'},
              {value: 4, name: 'Zestaw 4'},
              {value: 5, name: 'Zestaw 5'}],
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
        }, {
            type: 'ITEM-TEXT',
            paramId: 'WERSJA_STEROWNIKA',
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
        }, {
            paramId: 'CZAS_SEDYM_DZIEN',
            unit: Unit.MIN,
        }, {
            paramId: 'CZAS_PRACY_P1',
            unit: Unit.MIN,
        }, {
            paramId: 'CZAS_DO_URLOPU',
            unit: Unit.DAY,
        }, {
            paramId: 'CZAS_PRACY_DMUCHAWY_URLOP',
            unit: Unit.MIN,
        }, {
            paramId: 'CZAS_PRZERWY_DMUCHAWY_URLOP',
            unit: Unit.MIN,
        }, {
            paramId: 'CZAS_PRACY_D1',
            unit: Unit.MIN,
        }, {
            paramId: 'CZAS_PRZERWY_D1',
            unit: Unit.MIN,
        }, {
            paramId: 'CZAS_PRACY_D1_POSTOJ',
            unit: Unit.MIN,
        }, {
            paramId: 'CZAS_PRZERWY_D1_POSTOJ',
            unit: Unit.MIN,
        }, {
            paramId: 'LICZ_CZAS_PRACY_D1_M',
            unit: Unit.MIN,
        }, {
            paramId: 'LICZ_CZAS_PRACY_P1_M',
            unit: Unit.MIN,
        }, {
          paramId: 'NST_SEDYMENTACJA_NOC',
          type: 'ITEM-TEXT',
          placeholder: 'HH:MM'
        }, {
          paramId: 'NST_PRACA_POMPY_SEDYMENTACJA_NOC_P1',
          unit: Unit.MIN,
        }, {
          paramId: 'NOW_TIME',
          type: 'ITEM-TEXT',
          placeholder: 'HH:MM'
        }, {
          paramId: 'NOW_DATE',
          type: 'ITEM-TEXT',
          placeholder: 'yyyy-mm-dd'
        }
        ]
    };

    scheduleBoard = null;
}
