import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

import {User, UserService} from '../../shared';
import {HttpResponse} from '@angular/common/http';
import {faArrowLeft, faMicrochip, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-user-mgmt-detail',
    templateUrl: './user-management-detail.component.html'
})
export class UserMgmtDetailComponent implements OnInit, OnDestroy {
    faMicrochip = faMicrochip;
    faPencilAlt = faPencilAlt;
    faArrowLeft = faArrowLeft;

    user: User;
    private subscription: Subscription;

    constructor(
        private userService: UserService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['login']);
        });
    }

    load(login) {
        this.userService.find(login).subscribe((res: HttpResponse<User>) => {
            this.user = res.body;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
