import {Component, Input} from '@angular/core';
import {wiFiComponentHtml} from './wifi-icon.component.template';

enum WifiIcon {
    OFF = 'off',
    S0 = 's0',
    S1 = 's1',
    S2 = 's2',
    S3 = 's3',
    S4 = 's4'
}

@Component({
    selector: 'blg-wifi-icon',
    template: wiFiComponentHtml + ''
})
export class WifiIconComponent {

    WifiIcon = WifiIcon;

    name?: string;
    color?: string;
    rssiValue?: number;

    @Input()
    set rssi(rssi: number) {
        this.color = 'black';
        this.rssiValue = rssi;
        if (!rssi) {
            this.name = WifiIcon.OFF;
        } else {
            if (rssi > -60) {
                this.name = WifiIcon.S4;
            } else if (rssi > -67) {
                this.name = WifiIcon.S3;
            } else if (rssi > -70) {
                this.name = WifiIcon.S2;
            } else if (rssi > -80) {
                this.name = WifiIcon.S1;
            } else {
                this.color = 'red';
                this.name = WifiIcon.S0;
            }
        }
    }

    @Input() size = 24;

    style() {
        return {
          // 'stroke': this.color,
          fill: this.color,
          width: this.size + 'px',
          height: this.size + 'px'
        };
    }
}
