import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {EventMsg} from '@balrog/core/model/device';
import {TimeUtil} from '@balrog/core/utils';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'blg-event',
    templateUrl: './event.component.html',
    styleUrls: ['./event.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('level', [
            state('INFO', style({
                color: '#F29900'
            })),
            state('WARN', style({
                color: '#F29900'
            })),
            state('ERROR', style({
                color: '#ae5600'
            })),
            state('ALERT', style({
                backgroundColor: '#ae0600'
            })),
            transition('* => *', animate('100ms ease-in')),
        ]),
    ]
})
export class EventComponent implements OnInit {

    faExclamationCircle = faExclamationCircle;

    @Input()
    event: EventMsg | undefined;

    @Input()
    options: { name?: string } = {};

    hideContent = true;

    time = '';
    icon = '';
    statusText = '';
    statusClass = '';

    constructor() {
    }

    ngOnInit() {
        setTimeout(() => this.hideContent = false, 500);
        if (this.event) {
            this.time = TimeUtil.timeFromNow(this.event.createdDate);
            switch (this.event.type) {
                case 'ALARM':
                    this.statusText = 'Alarm';
                    if (!this.event.finishedDate) {
                        this.statusClass = 'color--error';
                    } else {
                        this.statusClass = 'color--accent';
                    }
                    return;
                case 'CONNECTION':
                    if (!this.event.finishedDate) {
                        this.statusText = 'Rozłączono';
                        this.statusClass = 'color--warn';
                    } else {
                        this.statusText = 'Połączono';
                        this.statusClass = 'color--success';
                    }
                    return;
                case 'CONFIGURATION':
                    this.statusText = 'Połączono';
                    this.statusClass = 'color--success';
                    return;
                case 'CUSTOM_NOTIFICATION':
                    this.statusText = 'Powiadomienie';
                    this.statusClass = 'color--success';
                    return;
            }
        }
    }
}
