import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Principal} from '@balrog/core';
import {faBan, faSave} from '@fortawesome/free-solid-svg-icons';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';

import {Observable} from 'rxjs';
import {AccessPermissionService} from '../../shared/auth/access-permission.service';
import {User} from '../../shared/user/user.model';
import {DeviceType, DeviceTypeService} from '../device-type';
import {Organization, OrganizationService} from '../organization';
import {GroupPopupService} from './group-popup.service';

import {Group} from './group.model';
import {GroupService} from './group.service';

@Component({
    selector: 'jhi-group-dialog',
    templateUrl: './group-dialog.component.html'
})
export class GroupDialogComponent implements OnInit {
    faBan = faBan;
    faSave = faSave;

    group: Group;
    isSaving: boolean;

    devicetypes: DeviceType[];

    organizations: Organization[];
    accessPermissions: any;

    constructor(
        public activeModal: NgbActiveModal,
        private jhiAlertService: JhiAlertService,
        private groupService: GroupService,
        private deviceTypeService: DeviceTypeService,
        private organizationService: OrganizationService,
        private eventManager: JhiEventManager,
        private accessPermissionService: AccessPermissionService,
        private principal: Principal
    ) {
    }

    ngOnInit() {
        this.isSaving = false;
        this.deviceTypeService.query()
            .subscribe((res: HttpResponse<DeviceType[]>) => { this.devicetypes = res.body; },
                (res: HttpResponse<DeviceType[]>) => this.onError(res.body));

        this.accessPermissionService.getAll().subscribe((accessPermissions) => {
            this.accessPermissions = accessPermissions;
        });

        if (this.principal.hasHierarchyAccess('ROLE_ADMIN')) {
            this.organizationService.query()
                .subscribe((res: HttpResponse<Organization[]>) => { this.organizations = res.body; }, (res: HttpErrorResponse) => this.onError(res));
        }
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        if (this.group.id !== undefined) {
            this.subscribeToSaveResponse(
                this.groupService.update(this.group));
        } else {
            this.subscribeToSaveResponse(
                this.groupService.create(this.group));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<Group>>) {
        result.subscribe((res: HttpResponse<Group>) =>
            this.onSaveSuccess(res.body), (res: HttpResponse<Group>) => this.onSaveError());
    }

    private onSaveSuccess(result: Group) {
        this.eventManager.broadcast({ name: 'groupListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError() {
        this.isSaving = false;
    }

    private onError(error) {
        this.jhiAlertService.error(error.message, null, null);
    }

    trackUserById(index: number, item: User) {
        return item.id;
    }

    trackDeviceTypeById(index: number, item: DeviceType) {
        return item.id;
    }

    trackOrganizationById(index: number, item: Organization) {
        return item.id;
    }

    getSelected(selectedVals: Array<any>, option: any) {
        if (selectedVals) {
            for (let i = 0; i < selectedVals.length; i++) {
                if (option.id === selectedVals[i].id) {
                    return selectedVals[i];
                }
            }
        }
        return option;
    }
}

@Component({
    selector: 'jhi-group-popup',
    template: ''
})
export class GroupPopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private groupPopupService: GroupPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if ( params['id'] ) {
                this.groupPopupService
                    .open(GroupDialogComponent as Component, params['id']);
            } else {
                this.groupPopupService
                    .open(GroupDialogComponent as Component);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
