import {fromEvent as observableFromEvent} from 'rxjs';

import {debounceTime} from 'rxjs/operators';
import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-filter-text',
    templateUrl: './filter-text.component.html',
    styleUrls: ['./filter-text.scss']
})
export class FilterTextComponent implements AfterViewInit {
    faClose = faTimes;

    @ViewChild('searchInput', {static: false}) searchInput;
    @Input() config: FilterTextComponentInterface;
    @Output() filter = new EventEmitter(true);

    ngAfterViewInit() {
        const searchInput$ = observableFromEvent(this.searchInput.nativeElement, 'input').pipe(
            debounceTime(400));

        searchInput$.subscribe(($event: Event) => this.onChange(($event.target as HTMLInputElement).value));
    }

    onChange(value) {
        this.filter.emit(value)
    }

    onClear() {
        this.searchInput.nativeElement.value = '';
        this.onChange('');
    }
}

export interface FilterTextComponentInterface {
    inputPlaceholder?: string,
    initialValue?: string
}
