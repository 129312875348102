import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class UserContextService implements OnDestroy {

  changeDeviceSubscription(): Observable<any> {
    return new BehaviorSubject<any>(null).asObservable();
  }

  currentDevice(): {serialNo: string} {
    return {serialNo: 'asd'} ;
  }

  ngOnDestroy(): void {
  }

}
