import { NgModule } from '@angular/core';

import {StatisticsComponent} from './statistics.component';
import {StatisticsService} from './statistics.service';
import {StatisticsUsersModule} from './statistics-users/statistics-users.module';
import {StatisticsDevicesModule} from './statistics-devices/statistics-devices.module';
import {BalrogadminSharedModule} from '../shared/shared.module';

@NgModule({
    imports: [
        BalrogadminSharedModule,
        StatisticsUsersModule,
        StatisticsDevicesModule
    ],
    declarations: [
        StatisticsComponent
    ],
    entryComponents: [
        StatisticsComponent
    ],
    exports: [
        StatisticsComponent
    ],
    providers: [
        StatisticsService
    ]
})
export class StatisticsModule {}
