import {BaseEntity} from './../../shared';

export class Organization implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public city?: string,
        public devices?: BaseEntity[],
        public groups?: BaseEntity[],
        public users?: BaseEntity[],
        public countryId?: number,
        public countryName?: string,
        public regionId?: number,
        public regionName?: string,
        public vendor?: string,
    ) {
    }
}
