import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'jhi-ngx-table-text-field',
    template: `<div
                style="width: 100%;"
                title="Double click to edit"
                (dblclick)="editing = true"
                *ngIf="!editing">
              {{value ? value : '_'}}
            </div>
            <input
                autofocus
                (blur)="handleUpdate($event)"
                *ngIf="(!type || type === 'text') && editing"
                type="text"
                [value]="value"
            />
            <input
                autofocus
                (blur)="handleUpdate($event)"
                *ngIf="type === 'number' && editing"
                type="number"
                [value]="value"
            />`
})
export class NgxTableTextFieldComponent {

    editing = false;
    @Input() value: string;
    @Input() type;
    @Output() update: EventEmitter<any> = new EventEmitter();

    handleUpdate(event) {
        this.editing = false;
        this.update.emit(event.target.value)
    }
}
