import {Component, Input} from '@angular/core';
import {faWifi} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-device-status-indicator',
    templateUrl: './device-status-indicator.component.html',
    styleUrls: [
        './device-status-indicator.scss'
    ]
})
export class DeviceStatusIndicatorComponent {
    faWifi = faWifi;
    @Input() statusValue: string;
    @Input() lastPing: string;
    @Input() rssi: number;
    data = {
        CONNECTED: {
            msg: 'deviceStatusIndicator.status.CONNECTED'
        },
        DISCONNECTED: {
            msg: 'deviceStatusIndicator.status.DISCONNECTED'
        },
        IDLE: {
            msg: 'deviceStatusIndicator.status.IDLE'
        }
    }
}
