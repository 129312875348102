import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {JhiEventManager} from 'ng-jhipster';

import {Country} from './country.model';
import {CountryService} from './country.service';
import {HttpResponse} from '@angular/common/http';
import {faArrowLeft, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-country-detail',
    templateUrl: './country-detail.component.html'
})
export class CountryDetailComponent implements OnInit, OnDestroy {
    faPencilAlt = faPencilAlt;
    faArrowLeft = faArrowLeft;

    country: Country;
    private subscription: Subscription;
    private eventSubscriber: Subscription;

    constructor(
        private eventManager: JhiEventManager,
        private countryService: CountryService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        this.registerChangeInCountries();
    }

    load(id) {
        this.countryService.find(id).subscribe((res: HttpResponse<Country>) => {
            this.country = res.body;
        });
    }
    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInCountries() {
        this.eventSubscriber = this.eventManager.subscribe(
            'countryListModification',
            (response) => this.load(this.country.id)
        );
    }
}
