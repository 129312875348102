import {Route} from '@angular/router';

import {UserRouteAccessService} from '../../shared';
import {MessageComponent} from './message.component';

export const messageRoute: Route = {
    path: 'contact',
    component: MessageComponent,
    canActivate: [UserRouteAccessService]
};
