import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {CORE_CONFIG, CoreConfig} from '../core.config';
import {CORE_API_URL} from '../core.constants';
import {DeviceSchema} from '../model/device';
import {createRequestOption} from '../utils/request-util';

type EntityResponseType = HttpResponse<DeviceSchema>;
type EntityArrayResponseType = HttpResponse<DeviceSchema[]>;

@Injectable()
export class DeviceSchemaService {
  private readonly coreApiUrl: string = CORE_API_URL;

  private resourceUrl = this.coreApiUrl + '/v1/device-schemas';

  constructor(private http: HttpClient,
              @Optional() @Inject(CORE_CONFIG) config: CoreConfig,
  ) {
    if (config) {
      this.coreApiUrl = config.coreApiUrl;
    }
  }

  create(deviceSchema: DeviceSchema): Observable<EntityResponseType> {
    const copy = this.convert(deviceSchema);
    return this.http.post(this.resourceUrl, copy, {observe: 'response'});
  }

  update(deviceSchema: DeviceSchema): Observable<EntityResponseType> {
    const copy = this.convert(deviceSchema);
    return this.http.put(this.resourceUrl, copy, {observe: 'response'});
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<DeviceSchema[]>(this.resourceUrl, {params: options, observe: 'response'});
  }

  delete(id: number): Observable<DeviceSchema> {
    return this.http.delete(`${this.resourceUrl}/${id}`);
  }

  getForDevice(serialNo: string): Observable<DeviceSchema> {
    return this.http.get<DeviceSchema>(this.resourceUrl + '/device/' + serialNo);
  }

  /**
   * Convert a DeviceSchema to a JSON which can be sent to the server.
   */
  private convert(deviceSchema: DeviceSchema): DeviceSchema {
    return deviceSchema;
  }
}
