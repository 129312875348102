import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EventMsg} from '@balrog/core/model/device';
import {Observable} from 'rxjs';

@Component({
    selector: 'blg-device-event-list',
    templateUrl: './device-event-list.component.html',
    styleUrls: ['./device-event-list.component.scss']
})
export class DeviceEventListComponent  {

  @Input() events: Observable<(EventMsg & {options: { name?: string }})[]> | undefined;

  @Input() loading = true;

  @Input() total = 0;

  @Input() page = 0;

  @Output() pageChange = new EventEmitter<number>();

  onPageChange(page: number) {
    this.pageChange.emit(page);
  }
}
