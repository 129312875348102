import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {CasLoginService, Principal} from '@balrog/core';
import {User} from '@balrog/core/model';
import {JhiEventManager} from 'ng-jhipster';

@Component({
    selector: 'jhi-home',
    templateUrl: './home.component.html',
    styleUrls: [
        'home.scss'
    ]

})
export class HomeComponent implements OnInit {
    account: User;
    authorizationCode: string;
    message: string;
    errorMessage: string;

    constructor(
        private router: Router,
        private principal: Principal,
        private route: ActivatedRoute,
        private loginService: CasLoginService,
        private eventManager: JhiEventManager
    ) {
    }

    ngOnInit() {
        this.authorizationCode = this.route.snapshot.queryParamMap.get('code');
        this.message = 'Weryfikacja konta ...';
        this.principal.identity().then((account) => {
            if (account) {
                this.account = account;
                this.router.navigate(['start']);
            } else {
                if (this.authorizationCode) {
                    this.message = 'Autoryzacja ...';
                    this.loginService.token(this.authorizationCode).subscribe(() => {
                        this.principal.identity().then((a) => {
                            if (a) {
                                this.router.navigate(['start']);
                            } else {
                                this.message = 'Błąd sieciowy ...';
                                this.errorMessage = 'Nieoczekiwany problem, prosimy o kontakt';
                            }
                        });
                    }, (err) => {
                        console.warn(err);
                        this.message = 'Błąd autoryzacji ...';
                        this.errorMessage = 'Prawdopodobnie twoja sesja wygasła, spróbujemy ponownie';
                        setTimeout(() => this.loginService.login(), 1000);
                    });
                } else {
                    this.loginService.login();
                }
            }
        });
        this.registerAuthenticationSuccess();
    }

    registerAuthenticationSuccess() {
        this.eventManager.subscribe('authenticationSuccess', (message) => {
            this.principal.identity().then((account) => {
                this.account = account;
            });
        });
    }
}
