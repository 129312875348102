import {Component, Input, OnInit} from '@angular/core';
import {Unit} from '@balrog/component/types';

@Component({
    selector: 'jhi-list-board-row',
    templateUrl: './list-board-row.component.html',
    styleUrls: ['./device-configuration-list.component.scss'],
    providers: []
})
export class ListBoardRowComponent implements OnInit {
    @Input() highlighted: boolean;
    @Input() content: any;

    readValue: number;
    unit: string;
    range: string;
    min: number;
    max: number;
    step: number;
    readonly: boolean;
    deny: boolean;
    optionMap = {};
    inputConverter: (string) => string | undefined;

    constructor() {}

    ngOnInit() {
        console.warn('LOADED ROW');
        this.readValue = 10;
        this.unit = this.content.unit;
        this.min = this.content.min;
        this.max = this.content.max;
        this.step = this.content.step;
        this.readonly = this.content.readonly;
        this.deny = this.content.deny;
        this.inputConverter = this.content.inputConverter;

        if (!this.min && !this.max && this.content.unit === Unit.PERCENTAGE) {
            this.min = 0;
            this.max = 100;
        }
        if (this.min || this.max) {
            this.range = '(' + this.min + '-' + this.max + ')'
        }
        this.content.changeTo = this.content.value;
        if (this.content.options) {
            this.content.options.forEach((option) => {
                this.optionMap[option.value] = option.name ? option.name : option.value;
            })
        }
    }

    handleChanged() {
    }
}
