import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '@balrog/core/model';
import {AUTHORITIES} from '@balrog/core/model/const';
import {Observable, Subject} from 'rxjs';
import {AccountService} from '../api/account.service';
import {CORE_CONFIG, CoreConfig} from '../core.config';

@Injectable()
export class Principal {
    private userIdentity: User;
    private authenticated = false;
    private authenticationState = new Subject<any>();
    private readonly config: CoreConfig;

    constructor(
        private router: Router,
        private account: AccountService,
        @Inject(CORE_CONFIG) config: CoreConfig,
    ) {
        this.config = config;
    }

    authenticate(identity) {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthority(authorities: string[]): Promise<boolean> {
        return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            for (let j = 0; j < this.userIdentity.authorities.length; j++) {
                if (this.userIdentity.authorities[j] === authorities[i]) {
                    return true;
                }
            }
        }
        return false;
    }

    hasAuthority(authority: string): Promise<boolean> {
        if (!this.authenticated) {
            return Promise.resolve(false);
        }

        return this.identity().then((id) => {
            let found = false;
            for (let j = 0; j < this.userIdentity.authorities.length; j++) {
                if (this.userIdentity.authorities[j] === authority) {
                    found = true;
                }
            }
            return Promise.resolve(id.authorities && found);
        }, () => {
            return Promise.resolve(false);
        });
    }

    hasHierarchyAccess(authority: string|string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        let userAuthority;

        if (typeof authority !== 'string') {
            // if couple of roles are required, the lowest required is taken
            authority = authority.sort((a, b) => AUTHORITIES.indexOf(a) - AUTHORITIES.indexOf(b))[0];
        }

        if (this.userIdentity.authorities.length > 0) {
            // if user has multiple roles, the highest is taken
            userAuthority = this.userIdentity.authorities.sort((a, b) => AUTHORITIES.indexOf(b) - AUTHORITIES.indexOf(a))[0];
        } else {
            userAuthority = this.userIdentity.authorities[0];
        }

        const requiredAuthorityIndex = AUTHORITIES.indexOf(authority),
            userAuthorityIndex = AUTHORITIES.indexOf(userAuthority);

        return requiredAuthorityIndex <= userAuthorityIndex;
    }

    identity(force?: boolean): Promise<User> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account.get().toPromise().then((account) => {
            if (account) {
                this.userIdentity = account;
                this.authenticated = true;
            } else {
                this.userIdentity = null;
                this.authenticated = false;
            }
            if (!this.hasAccessToEnter()) {
                this.router.navigate(['accessdenied']).then(() => {});
                this.userIdentity = account;
                this.authenticated = false;
            }
            this.authenticationState.next(this.userIdentity);
            return this.userIdentity;
        }).catch(() => {
            this.userIdentity = null;
            this.authenticated = false;
            this.authenticationState.next(this.userIdentity);
            return null;
        });
    }

    isAuthenticated(): boolean {
        return this.authenticated;
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    hasAccessToEnter(): boolean {
        return this.config.requiredRole ? this.hasHierarchyAccess(this.config.requiredRole) : true; // minimum role to enter
    }

    getUserIdentity(): any {
        return this.isIdentityResolved() ? this.userIdentity : null;
    }

    getUsername(): string {
        return this.isIdentityResolved() ? this.userIdentity.email : null;
    }
}
