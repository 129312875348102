export enum DeviceState {
    CREATED, ACTIVE, INACTIVE
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DeviceState {
    // eslint-disable-next-line no-inner-declarations
    export function names(): Array<string> {
        const keys = Object.keys(DeviceState);
        return keys.slice(keys.length / 2, keys.length - 1);
    }
}
