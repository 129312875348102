/* tslint:disable:no-bitwise */
import {BriefPanelName, PanelTypeEnum, Unit, VAxisType} from '@balrog/component/types';
import {AccessPermissionEnum} from '@balrog/core/model/const';
/* eslint no-bitwise: ["error", { "allow": ["&", "<<"] }] */
import {ProfileInterface} from './interface/profile.interface';
import {ScheduleboardConfigInterface} from './interface/scheduleboard-config.interface';

export class SmartventProfile implements ProfileInterface {
    statuses = {};
    alarms = {};

    briefPanel = {
        name: BriefPanelName.SMARTVENT
    };

    dashBoard = {
        panelType: PanelTypeEnum.SMARTVENT,
        panelConfig: {
            mode: 'V1'
        }
    };

    historyBoard = {
        title: '',
        content: [{
            paramId: 'TEMP_CZERPNI'
        }, {
            paramId: 'TEMP_WYRZUTNI'
        }, {
            paramId: 'TEMP_NAWIEW'
        }, {
            paramId: 'TEMP_WYWIEW'
        }, {
            paramId: 'HUM_CZERPNI',
            vAxisType: VAxisType.PERCENTAGE.valueOf()
        }, {
            paramId: 'HUM_WYRZUTNI',
            vAxisType: VAxisType.PERCENTAGE.valueOf()
        }, {
            paramId: 'HUM_NAWIEW',
            vAxisType: VAxisType.PERCENTAGE.valueOf()
        }, {
            paramId: 'HUM_WYWIEW',
            vAxisType: VAxisType.PERCENTAGE.valueOf()
        }, {
            paramId: 'ODZYSK_PERCT',
            type: 'stepped',
            vAxisType: VAxisType.PERCENTAGE.valueOf()
        }, {
            paramId: 'MOC_WENT',
            type: 'stepped',
            vAxisType: VAxisType.PERCENTAGE.valueOf()
        }]
    };

    listBoard = {
        title: 'Konfiguracja urządzenia',
        content: [{
            paramId: 'MAX_TEMP_PRACY',
            readPermission: AccessPermissionEnum.SERVICE.valueOf(),
            unit: Unit.C
        }, {
            paramId: 'BYPASS_TEMP_CZERPNI',
            unit: Unit.C
        }, {
            paramId: 'BYPASS_TEMP_WYWIEWU',
            unit: Unit.C
        }, {
            title: 'Ustawienia serwa',
            readPermission: AccessPermissionEnum.SERVICE.valueOf(),
            type: 'MENU',
            content: [{
                paramId: 'PREDKOSC_SERWA'
            }, {
                paramId: 'SERWO_NAWIEW_MIN'
            }, {
                paramId: 'SERWO_NAWIEW_MAX'
            }, {
                paramId: 'SERWO_WYWIEW_MIN'
            }, {
                paramId: 'SERWO_WYWIEW_MAX'
            }, {
                paramId: 'SERWO_BYPASS_MIN'
            }, {
                paramId: 'SERWO_BYPASS_MAX'
            }]
        }, {
            paramId: 'CZAS_DOGRZEWANIA',
            unit: Unit.MIN,
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'CZAS_PRZERWY',
            unit: Unit.MIN,
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'MOC_ODMRAZANIA',
            unit: Unit.PERCENTAGE,
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'TEMP_ODMRAZANIA',
            readPermission: AccessPermissionEnum.SERVICE.valueOf(),
            unit: Unit.C
        }, {
            paramId: 'TEMP_NAWIEW',
            readPermission: AccessPermissionEnum.SERVICE.valueOf(),
            unit: Unit.C
        }, {
            paramId: 'TEMP_WYWIEW',
            readPermission: AccessPermissionEnum.SERVICE.valueOf(),
            unit: Unit.C
        }, {
            paramId: 'TEMP_CZERPNI',
            readPermission: AccessPermissionEnum.SERVICE.valueOf(),
            unit: Unit.C
        }, {
            paramId: 'TEMP_WYRZUTNI',
            readPermission: AccessPermissionEnum.SERVICE.valueOf(),
            unit: Unit.C
        }, {
            paramId: 'NST_MOC_WENT_NAWIEW',
            unit: Unit.PERCENTAGE
        }, {
            paramId: 'WENT_NAWIEW',
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'NST_MOC_WENT_WYWIEW',
            unit: Unit.PERCENTAGE
        }, {
            paramId: 'WENT_WYWIEW',
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'HUM_NAWIEW',
            unit: Unit.PERCENTAGE
        }, {
            paramId: 'HUM_WYWIEW',
            unit: Unit.PERCENTAGE,
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'HUM_CZERPNI',
            unit: Unit.PERCENTAGE,
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'HUM_WYRZUTNI',
            unit: Unit.PERCENTAGE,
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'MOC_WENT',
            unit: Unit.PERCENTAGE,
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'CZAS_WYMIANY_FILTRA',
            unit: Unit.DAY
        }, {
            paramId: 'NAST_TEMP_DOGRZEWANIA',
            unit: Unit.C
        }, {
            paramId: 'DOGRZEWANIE',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
        }, {
            paramId: 'NAST_TEMP_CHLODZENIA',
            unit: Unit.C
        }, {
            paramId: 'CHLODZENIE',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
        }, {
            paramId: 'MOC_GRZANIA',
            unit: Unit.PERCENTAGE,
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'MOC_CHLODZENIA',
            unit: Unit.PERCENTAGE,
            readPermission: AccessPermissionEnum.SERVICE.valueOf()
        }, {
            paramId: 'SLAVE_ERR',
            type: 'ITEM-TEXT',
            inputConverter(v: number) {
                const text = [];
                if (v & (1 << 0)) {text.push('Błąd temp. czerpnia'); }
                if (v & (1 << 1)) {text.push('Błąd wilg. czerpnia'); }
                if (v & (1 << 2)) {text.push('Błąd temp. wywiew'); }
                if (v & (1 << 3)) {text.push('Błąd wilg. wywiew'); }
                if (v & (1 << 4)) {text.push('Błąd temp. wyrzutnia'); }
                if (v & (1 << 5)) {text.push('Błąd wilg. wyrzutnia'); }
                if (v & (1 << 6)) {text.push('Błąd temp. nawiew'); }
                if (v & (1 << 7)) {text.push('Błąd wilg. nawiew'); }
                return text.length ? text.join(',') : '---';
            }
        }, {
            type: 'ITEM-TEXT',
            paramId: 'WERSJA_STEROWNIKA'
        }, {
            paramId: 'NOW_TIME',
            type: 'ITEM-TEXT',
            placeholder: 'HH-MM'
        }, {
            paramId: 'NOW_DATE',
            type: 'ITEM-TEXT',
            placeholder: 'yyyy-mm-dd'
        }, {
            paramId: 'NOW_DAY_OF_WEEK',
            type: 'ITEM-SELECT',
            readonly: true,
            options: [
                {value: '0', name: 'Poniedziałek'},
                {value: '1', name: 'Wtorek'},
                {value: '2', name: 'Środa'},
                {value: '3', name: 'Czwartek'},
                {value: '4', name: 'Piątek'},
                {value: '5', name: 'Sobota'},
                {value: '6', name: 'Niedziela'}
            ]
        }, {
            paramId: 'HARD_RESET',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'Reset'}],
            readPermission: AccessPermissionEnum.ADMIN.valueOf(),
        }]
    };

    scheduleBoard: ScheduleboardConfigInterface = {
        title: 'Kalendarz',
        event: [
            {
                paramId: 'NST_MOC_WENT_NAWIEW',
                defaultValue: 20,
                unit: Unit.PERCENTAGE
            },
            {
                paramId: 'NST_MOC_WENT_WYWIEW',
                defaultValue: 20,
                unit: Unit.PERCENTAGE
            },
            {
                paramId: 'TEMP_NAWIEW',
                defaultValue: 20,
                unit: Unit.C
            },
            {
                paramId: 'BOOST_MODE',
                displayType: 'ITEM-SWITCH',
                options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
            },
            {
                paramId: 'DOGRZEWANIE',
                displayType: 'ITEM-SWITCH',
                options: [{value: 'false', name: 'wył.'}, {value: 'true', name: 'wł.'}]
            }
        ]
    };
}
