import {BriefPanelName, PanelTypeEnum, Unit, VAxisType} from '@balrog/component/types';
import {ProfileInterface} from './interface/profile.interface';

export class CobraProfile implements ProfileInterface {
    statuses = {
        TRYB_PID: { title: 'Tryb PID' },
        POMPA_CO: { title: 'Pompa CO' },
        POMPA_CWU: { title: 'Pompa CWU' },
        PODAJNIK: { title: 'Podajnik' },
        DMUCHAWA: { title: 'Dmuchawa' },
        TERMOSTAT: { title: 'Termostat' },
        STYK_TERMOSTATU: { title: 'Styk termostatu' },
        POMPA_CYRKULACYJNA: { title: 'Pompa cyrkulacyjna' },
        PRACA_KOTLA: { title: 'Praca kotła' }
    };

    // alarmy
    alarms = {
        CZUJNIK_CO: {title: 'Czujnik CO'},
        KOCIOL_PRZEGRZANY: {title: 'Kocioł przegrzany'},
        POZAR_KOSZA: {title: 'Pożar kosza'},
        STB: {title: 'Stb'},
        STB_230V: {title: 'Stb 230V'},
        CZUJNIK_TEMP_KOSZA: {title: 'Czujnik temp. kosza'},
        BRAK_OPALU: {title: 'Brak opału'},
        OTWARTY_KOSZ: {title: 'Otwarty kosz'},
        ALARM_PODAJNIK: {title: 'Błąd podajnika'},
        CZUJNIK_CWU: {title: 'Czujnik CWU'},
        KOSZ_OTWARTY: {title: 'Kosz Otwarty'},
        CO_WARN: {title: 'Tlenek węgla przekroczył poziom ostrzegawczy'},
        CO_ALARM: {title: 'Tlenek węgla przekroczył poziom alarmowy'}
    };

    briefPanel = {
        name: BriefPanelName.ELEKTROMIZ_COBRA
    };

    dashBoard = {
        panelType: PanelTypeEnum.ELEKTROMIZ_COBRA,
    };

    historyBoard = {
        title: 'Temperatura [°C]',
        content: [{
            paramId: 'TEMP_KOTLA',
            unit: Unit.C
        }, {
            paramId: 'NST_KOTLA',
            unit: Unit.C,
            type: 'stepped'
        }, {
            paramId: 'TEMP_BOILERA',
            unit: Unit.C
        }, {
            paramId: 'NST_BOILERA',
            unit: Unit.C,
            type: 'stepped'
        }, {
            paramId: 'TEMP_KOSZA',
            unit: Unit.C
        }, {
            paramId: 'MOC_KOTLA',
            unit: Unit.PERCENTAGE,
            type: 'stepped',
            vAxisType: VAxisType.POWER_PERCENTAGE.valueOf()
        }, {
            paramId: 'CO',
            unit: Unit.PPM,
            vAxisType: VAxisType.PPM.valueOf()
        }]
    };

    listBoard = {
        title: 'Konfiguracja',
        content: [{
            paramId: 'NST_OBNIZENIA_TERMOSTATU_1'
        }, {
            paramId: 'NST_OBNIZENIA_TERMOSTATU_2'
        }, {
            paramId: 'NST_HISTEREZA_CWU',
            unit: Unit.C
        }, {
            paramId: 'NST_HISTEREZA_CO',
            unit: Unit.C
        }, {
            paramId: 'MOC_KOTLA',
            unit: Unit.PERCENTAGE
        }, {
            paramId: 'MOC_DMUCHAWY',
            unit: Unit.PERCENTAGE
        }, {
            paramId: 'NST_TEMP_WL_CO',
            unit: Unit.C
        }, {
            paramId: 'NST_MOC_PRZEDMUCHU',
            unit: Unit.PERCENTAGE
        }, {
            paramId: 'NST_MOC_DMUCHAWY_MAX',
            unit: Unit.PERCENTAGE
        }, {
            paramId: 'NST_MOC_DMUCHAWY_MIN',
            unit: Unit.PERCENTAGE
        }, {
            paramId: 'NST_PID_CZAS_PAUZA',
            unit: Unit.SEC
        }, {
            paramId: 'NST_PID_CZAS_PRACA',
            unit: Unit.SEC
        }, {
            title: 'Zuzycie paliwa',
            type: 'MENU',
            content: [{
                paramId: 'NST_ZUZYCIE_PALIWA'
            }, {
                paramId: 'ZUZYCIE_PALIWA_WCZORAJ'
            }, {
                paramId: 'ZUZYCIE_PALIWA_WCZORAJ_1'
            }]
        }, {
            title: 'Pompy',
            type: 'MENU',
            content: [{
                paramId: 'TRYB_PRACY_POMPY_CYRK',
                type: 'ITEM-SWITCH',
                options: [{value: 'false', name: 'Ciągły'}, {value: 'true', name: 'Przerywany'}]
            }, {
                paramId: 'NST_CZAS_PRACY_POMPY_1',
                unit: Unit.SEC
            }, {
                paramId: 'NST_CZAS_PAUZY_POMPY_1',
                unit: Unit.SEC
            }, {
                paramId: 'NST_CZAS_PRACY_POMPY_2',
                unit: Unit.SEC
            }, {
                paramId: 'NST_CZAS_PAUZY_POMPY_2',
                unit: Unit.SEC
            }, {
                paramId: 'NST_CZAS_PRACY_POMPY_CYRK_1',
                unit: Unit.SEC
            }, {
                paramId: 'NST_CZAS_PAUZY_POMPY_CYRK_1',
                unit: Unit.SEC
            }, {
                paramId: 'NST_CZAS_PRACY_POMPY_CYRK_2',
                unit: Unit.SEC
            }, {
                paramId: 'NST_CZAS_PAUZY_POMPY_CYRK_2',
                unit: Unit.SEC
            }]
        }, {
            title: 'Podtrzymanie',
            type: 'MENU',
            content: [{
                paramId: 'NST_PODT_CZAS_PRACA',
                unit: Unit.SEC
            }, {
                paramId: 'NST_PODT_CZAS_PRZEDMUCHU',
                unit: Unit.SEC
            }, {
                paramId: 'NST_PODT_CZAS_PAUZA',
                step: 0.1,
                unit: Unit.SEC
            }, {
                paramId: 'NST_PODT_LICZBA_PRZEDMUCHOW'
            }]
        }, {
            paramId: 'CO',
            unit: Unit.PPM
        }]
    };

    scheduleBoard = null;
}
