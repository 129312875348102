import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';

import {Region} from './region.model';
import {RegionPopupService} from './region-popup.service';
import {RegionService} from './region.service';
import {faBan, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-region-delete-dialog',
    templateUrl: './region-delete-dialog.component.html'
})
export class RegionDeleteDialogComponent {
    faTimes = faTimes;
    faBan = faBan;
    region: Region;

    constructor(
        private regionService: RegionService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.regionService.delete(id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'regionListModification',
                content: 'Deleted an region'
            });
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'jhi-region-delete-popup',
    template: ''
})
export class RegionDeletePopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private regionPopupService: RegionPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.regionPopupService
                .open(RegionDeleteDialogComponent as Component, params['id']);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
