import {Component, Input, OnInit} from '@angular/core';
import {Device} from '../../../entities/device/device.model';
import {DeviceService} from '../../../entities/device/device.service';
import {HttpResponse} from '@angular/common/http';
import {faBell, faExchangeAlt, faExclamationCircle, faExclamationTriangle, faInfoCircle, faWrench} from '@fortawesome/free-solid-svg-icons';

const DEVICE_EVENT_LOGS_WARNING_LEVELS = {
    WARN: {
        icon: faExclamationTriangle,
        iconCls: 'app-DeviceEventsLog-levelIcon--WARN',
        msg: 'event.warnLevel.WARN'
    },
    ALERT: {
        icon: faExclamationTriangle,
        iconCls: 'app-DeviceEventsLog-levelIcon--ALERT',
        msg: 'event.warnLevel.ALERT'
    },
    INFO: {
        icon: faInfoCircle,
        iconCls: 'app-DeviceEventsLog-levelIcon--INFO',
        msg: 'event.warnLevel.INFO'
    },
    ERROR: {
        icon: faExclamationCircle,
        iconCls: 'app-DeviceEventsLog-levelIcon--ERROR',
        msg: 'event.warnLevel.ERROR'
    }
};

const DEVICE_EVENT_LOGS_TYPES = {
    ALARM: {
        icon: faBell,
        iconCls: 'app-DeviceEventsLog-typeIcon--ALARM',
        msg: 'event.type.WARN'
    },
    CONNECTION: {
        icon: faExchangeAlt,
        iconCls: 'app-DeviceEventsLog-typeIcon--CONNECTION',
        msg: 'event.type.ALERT'
    },
    CONFIGURATION: {
        icon: faWrench,
        iconCls: 'app-DeviceEventsLog-typeIcon--CONFIGURATION',
        msg: 'event.type.INFO'
    }
};

// temporarily mocked
const DEVICE_EVENT_ALARMS = {
    101700: {title: 'Czujnik CO'},
    101701: {title: 'Kocioł przegrzany'},
    101702: {title: 'Pożar kosza'},
    101703: {title: 'Stb'},
    101704: {title: 'Stb 230V'},
    101705: {title: 'Czujnik temp. kosza'},
    101706: {title: 'Brak opału'},
    101707: {title: 'Otwarty kosz'},
    101708: {title: 'Czujnik CWU'},
    101709: {title: 'Kosz Otwarty'}
};

@Component({
    selector: 'jhi-device-events-log',
    templateUrl: './device-events-log.component.html',
    styleUrls: [
        './device-events-log.scss'
    ]
})
export class DeviceEventsLogComponent implements OnInit {

    faInfo = faInfoCircle;
    @Input() device: Device;

    warningLevels = DEVICE_EVENT_LOGS_WARNING_LEVELS;
    types = DEVICE_EVENT_LOGS_TYPES;
    alarms = DEVICE_EVENT_ALARMS;

    historyTableHeadConfig = {
        icon: {
            translation: ''
        },
        info: {
            translation: 'balrogadminApp.device.events.fields.title'
        },
        type: {
            translation: 'balrogadminApp.device.events.fields.type'
        },
        startedDate: {
            translation: 'balrogadminApp.device.events.fields.startedDate'
        },
        finishedDate: {
            translation: 'balrogadminApp.device.events.fields.finishedDate'
        }
    };
    historyTableData: DeviceLog[] = [];

    constructor(private deviceService: DeviceService) {}

    ngOnInit() {
        this.loadLogs();
    }

    loadLogs() {
        this.deviceService.getDeviceLogs(this.device.serialNo).subscribe((res: HttpResponse<DeviceLog[]>) => {
            this.historyTableData = res.body;
        });
    }
}

export class DeviceLog {
    constructor(
        public id?: number,
        public title?: string,
        public details?: string,
        public level?: string,
        public type?: string,
        public deviceSerialNo?: string,
        public refId?: string,
        public createdDate?: string,
        public startedDate?: string,
        public finishedDate?: string,
    ) {
    }
}
