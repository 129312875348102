import { NgModule } from '@angular/core';
import { DeviceEventsLogComponent } from './device-events-log.component';
import {BalrogadminSharedModule} from '../../../shared/shared.module';
import {TableModule} from '../../table/table.module';

@NgModule({
    imports: [
        BalrogadminSharedModule,
        TableModule
    ],
    declarations: [
        DeviceEventsLogComponent
    ],
    entryComponents: [
        DeviceEventsLogComponent
    ],
    exports: [
        DeviceEventsLogComponent
    ]
})
export class DeviceEventsLogComponentModule {}
