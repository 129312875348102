import {BriefPanelName, PanelTypeEnum, Unit} from '@balrog/component/types';
import {AccessPermissionEnum} from '@balrog/core/model/const';
import {ProfileInterface} from './interface/profile.interface';

export class HabaMultiBio3200Profile implements ProfileInterface {

    statuses = {};

    alarms = {
        ALARM_PRZEPELNIENIE: {title: 'Awaria przepełnienie'},
        ALARM_OSAD: {title: 'Awaria osad'},
        ALARM_X1: {title: 'Awaria wyjścia X1'},
    };

    briefPanel = {
        name: BriefPanelName.HABA_MULTIBIO_3200
    };

    dashBoard = {
        panelType: PanelTypeEnum.HABA_MULTIBIO,
        panelConfig: {
            mode: 'MULTIBIO_3200'
        }
    };

    historyBoard = null;

    listBoard = {
        title: 'Konfiguracja urzadzenia',
        content: [{
            type: 'ITEM-TEXT',
            paramId: 'WERSJA_STEROWNIKA',
            readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
        }, {
            paramId: 'CZAS_SEDYM_DZIEN',
            unit: Unit.MIN,
        }, {
            paramId: 'CZAS_PRACY_P1_NOC',
            unit: Unit.MIN,
        }, {
            paramId: 'CZAS_PRACY_X1',
            type: 'ITEM-TEXT',
            placeholder: 'HH:MM'
        }, {
            paramId: 'CZAS_PRACY_P1_PRZEPELNIENIE',
            unit: Unit.MIN,
        }, {
            paramId: 'CZAS_MIESZANIA',
            unit: Unit.MIN,
        }, {
            paramId: 'DNI_OD_PRACY_X1',
            unit: Unit.DAY,
        }, {
            paramId: 'LICZ_CZAS_PRACY_D1',
            unit: Unit.SEC
        }, {
            paramId: 'LICZ_CZAS_PRACY_D2',
            unit: Unit.SEC
        }, {
            paramId: 'LICZ_CZAS_PRACY_P1',
            unit: Unit.SEC
        }, {
            paramId: 'LICZ_CZAS_PRACY_X1',
            unit: Unit.SEC
        }, {
            paramId: 'CZAS_DO_URLOPU',
            unit: Unit.DAY,
        }, {
            paramId: 'ZASTOSOWANIE_X1',
            type: 'ITEM-SELECT',
            options: [
                {value: '0', name: 'Pompa osadu'},
                {value: '1', name: 'Dozowanie'}
            ]
        }, {
          title: 'Parametry pracy dmuchawy 1',
          type: 'MENU',
          content: [
            {
              paramId: 'CZAS_NAPOWIETRZANIA_D1',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_POSTOJ_D1',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_PRACY_D1_POSTOJ',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_PRZERWY_D1_POSTOJ',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_NAPOWIETRZANIA_D1_UROP',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_POSTOJ_D1_UROP',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_PRACY_D1_POSTOJ_UROP',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_PRZERWY_D1_POSTOJ_UROP',
              unit: Unit.MIN,
            }
          ]
        }, {
          title: 'Parametry pracy dmuchawy 2',
          type: 'MENU',
          content: [
            {
              paramId: 'CZAS_NAPOWIETRZANIA_D2',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_POSTOJ_D2',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_PRACY_D2_POSTOJ',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_PRZERWY_D2_POSTOJ',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_NAPOWIETRZANIA_D2_UROP',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_POSTOJ_D2_UROP',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_PRACY_D2_POSTOJ_UROP',
              unit: Unit.MIN,
            }, {
              paramId: 'CZAS_PRZERWY_D2_POSTOJ_UROP',
              unit: Unit.MIN,
            }
          ]
        }, {
          paramId: 'NOW_TIME',
          type: 'ITEM-TEXT',
          placeholder: 'HH:MM'
        }, {
          paramId: 'NOW_DATE',
          type: 'ITEM-TEXT',
          placeholder: 'yyyy-mm-dd'
        }, {
          type: 'ITEM-TEXT',
          paramId: 'WERSJA_FIRMWARE',
          readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
        }, {
            title: 'Czasy kontrolne',
            type: 'MENU',
            content: [
                {
                    paramId: 'CHECK_TIME_1',
                    type: 'ITEM-TEXT',
                    placeholder: 'HH:MM'
                }, {
                    paramId: 'CHECK_TIME_2',
                    type: 'ITEM-TEXT',
                    placeholder: 'HH:MM'
                }, {
                    paramId: 'CHECK_TIME_3',
                    type: 'ITEM-TEXT',
                    placeholder: 'HH:MM'
                }, {
                    paramId: 'CHECK_TIME_4',
                    type: 'ITEM-TEXT',
                    placeholder: 'HH:MM'
                }, {
                    paramId: 'CHECK_TIME_5',
                    type: 'ITEM-TEXT',
                    placeholder: 'HH:MM'
                }, {
                    paramId: 'CHECK_TIME_6',
                    type: 'ITEM-TEXT',
                    placeholder: 'HH:MM'
                }
            ]
        }, {
            title: 'Zestawy',
            type: 'MENU',
            content: [
                {
                    title: 'Zestaw 1',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'Z1_X1_ON',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }, {
                            paramId: 'Z1_X1_OFF',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }
                    ]
                }, {
                    title: 'Zestaw 2',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'Z2_X1_ON',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }, {
                            paramId: 'Z2_X1_OFF',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }
                    ]
                },{
                    title: 'Zestaw 3',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'Z3_X1_ON',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }, {
                            paramId: 'Z3_X1_OFF',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }
                    ]
                },{
                    title: 'Zestaw 4',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'Z4_X1_ON',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }, {
                            paramId: 'Z4_X1_OFF',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }
                    ]
                },{
                    title: 'Zestaw 5',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'Z5_X1_ON',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }, {
                            paramId: 'Z5_X1_OFF',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }
                    ]
                },{
                    title: 'Zestaw 6',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'Z6_X1_ON',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }, {
                            paramId: 'Z6_X1_OFF',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }
                    ]
                },{
                    title: 'Zestaw 7',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'Z7_X1_ON',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }, {
                            paramId: 'Z7_X1_OFF',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }
                    ]
                },{
                    title: 'Zestaw 8',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'Z8_X1_ON',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }, {
                            paramId: 'Z8_X1_OFF',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }
                    ]
                },{
                    title: 'Zestaw 9',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'Z9_X1_ON',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }, {
                            paramId: 'Z9_X1_OFF',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }
                    ]
                },{
                    title: 'Zestaw 10',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'Z10_X1_ON',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }, {
                            paramId: 'Z10_X1_OFF',
                            type: 'ITEM-TEXT',
                            placeholder: 'HH:MM:SS'
                        }
                    ]
                }
            ]
        }
        ]
    };

    scheduleBoard = null;
}
