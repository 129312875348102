import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DeviceSchemaFieldDataAdapter} from './device-schema-field-data-adapter.model';
import {DataAdapter} from './data-adapter';
import {faBan, faSave} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-device-schema-adapter-dialog',
    templateUrl: './device-schema-adapter-dialog.component.html'
})
export class DeviceSchemaAdapterDialogComponent implements OnInit {
    faBan = faBan;
    faSave = faSave;

    adapter: DeviceSchemaFieldDataAdapter;
    copy: DeviceSchemaFieldDataAdapter;

    DataAdapter = DataAdapter;

    constructor(
        public activeModal: NgbActiveModal,
    ) {
    }

    ngOnInit() {
        if (this.adapter) {
            if (!this.adapter.values) {
                this.adapter.values = {};
            }
            if (!this.adapter.options) {
                this.adapter.options = {};
            }
            this.copy = {...this.adapter, values : {... this.adapter.values}, options : {... this.adapter.options}}
        } else {
            this.adapter = {values: {}, options: {}};
        }
    }

    clear() {
        this.activeModal.dismiss(this.copy);
    }

    save() {
        this.activeModal.close(this.adapter);
    }
}
