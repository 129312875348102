import {Component, Input} from '@angular/core';
import {spinnerComponentHtml} from './spinner.component.template';

@Component({
    selector: 'blg-spinner',
    template: spinnerComponentHtml + '',
    styles: [`
    .spinner-container {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.spinner-container.dimmed {
    background: rgba(55,55,55,0.3);
}

.spinner-center {
    position: absolute;
    left: 50%;
    top: 50%;
}`]
})
export class SpinnerComponent {
    @Input() dimmed = true;
    @Input() show = false;
    @Input() size = 100;

    style() {
        return {
            // 'stroke': this.color
            'margin-left': '-' + this.size / 2 + 'px',
            'margin-top': '-' + this.size / 2 + 'px',
            width: this.size + 'px',
            height: this.size + 'px'
        };
    }
}
