import {BriefPanelName, PanelTypeEnum, Unit} from '@balrog/component/types';
import {ProfileInterface} from './interface/profile.interface';

/* eslint no-bitwise: ["error", { "allow": ["&", "<<"] }] */

export class TweetopPompaV2Profile implements ProfileInterface {
    statuses = {};

    alarms = {};

    briefPanel = {
        name: BriefPanelName.TWEETOP_POMPA_V2
    };

    dashBoard = {
        panelType: PanelTypeEnum.TWEETOP_POMPA_V2
    };

    historyBoard = {
        title: 'Temperatura [°C]',
        content: [{
            paramId: 'WATER_TANK_TEMP',
            unit: Unit.C
        }, {
            paramId: 'SETTING_ROOM_TEMP',
            unit: Unit.C
        }]
    };

    listBoard = {
        title: 'Konfiguracja urzadzenia',
        content: [{
            title: 'Ogólne',
            type: 'MENU',
            content: [
                {
                    paramId: 'COMPRESSOR_OPERATING_FREQ',
                    unit: Unit.HZ
                }, {
                    paramId: 'OUTDOOR_UNIT_OPERATION_MODE',
                    readonly: true,
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Off'},
                        {value: '2', name: 'Chłodzenie'},
                        {value: '3', name: 'Ogrzewanie'}
                    ]
                }, {
                    paramId: 'FAN_SPEED',
                    unit: Unit.RPM
                }, {
                    paramId: 'OPENNESS_OUTDOOR_VALVE'
                }, {
                    paramId: 'TW_IN',
                    unit: Unit.C
                }, {
                    paramId: 'TW_OUT',
                    unit: Unit.C
                }, {
                    paramId: 'CONDENSER_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'OUTDOOR_AMBIENT_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'COMPRESSOR_DISCHARGE_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'COMPRESSOR_SUCTION_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'TOTAL_WATER_OUTLET_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'WATER_TEMP_FLOW_ZONE2',
                    unit: Unit.C
                }, {
                    paramId: 'REFRIGERANT_LIQUID_SIDE_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'REFRIGERANT_GAS_SIDE_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'ROOM_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'WATER_TANK_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'OUTDOOR_UNIT_HIGH_PRESSURE',
                    unit: Unit.KPA
                }, {
                    paramId: 'OUTDOOR_UNIT_LOW_PRESSURE',
                    unit: Unit.KPA
                }, {
                    paramId: 'OUTDOOR_UNIT_OPERATING_CURRENT',
                    unit: Unit.A
                }, {
                    paramId: 'OUTDOOR_UNIT_VOLTAGE',
                    unit: Unit.V
                }, {
                    paramId: 'TBT1_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'TBT2_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'COMPRESSOR_OPERATING_TIME,',
                    unit: Unit.HOUR
                },
            ]
        }, {
            title: 'Ustawienia',
            type: 'MENU',
            content: [
                {
                    paramId: 'ON_OFF_AIR_CONDITION_ZONE_1',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'OFF'}, {value: 'true', name: 'ON'}]
                }, {
                    paramId: 'ON_OFF_FLOOR_HEATING_ZONE_1',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'OFF'}, {value: 'true', name: 'ON'}]
                }, {
                    paramId: 'ON_OFF_DHW_ZONE_1',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'OFF'}, {value: 'true', name: 'ON'}]
                }, {
                    paramId: 'ON_OFF_FLOOR_HEATING_ZONE_2',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'OFF'}, {value: 'true', name: 'ON'}]
                }, {
                    paramId: 'MODE',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '1', name: 'Auto'},
                        {value: '2', name: 'Chłodzenie'},
                        {value: '3', name: 'Ogrzewanie'}
                    ]
                }, {
                    paramId: 'SETTING_WATER_TEMP_FLOOR_HEATING_ZONE_1',
                    unit: Unit.C
                }, {
                    paramId: 'SETTING_WATER_TEMP_FLOOR_HEATING_ZONE_2',
                    unit: Unit.C
                }, {
                    paramId: 'SETTING_ROOM_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'SETTING_WATER_TANK_TEMP',
                    unit: Unit.C
                }, {
                    paramId: 'WATER_TEMP_FLOOR_HEATING_ZONE_1',
                    unit: Unit.C
                }, {
                    paramId: 'WATER_TEMP_FLOOR_HEATING_ZONE_2',
                    unit: Unit.C
                }
            ]
        }]
    };

    scheduleBoard = null;
}