import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CORE_API_URL } from '../../app.constants';

import { Country } from './country.model';
import { createRequestOption } from '../../shared';

type EntityResponseType = HttpResponse<Country>;
type EntityArrayResponseType = HttpResponse<Country[]>;

@Injectable()
export class CountryService {

    private resourceUrl =  CORE_API_URL + '/v1/countries';
    private resourceUrlNoAuth =  CORE_API_URL + '/v1/noauth/countries';

    constructor(private http: HttpClient) { }

    create(country: Country): Observable<EntityResponseType> {
        const copy = this.convert(country);
        return this.http.post(this.resourceUrl, copy, { observe: 'response' });
    }

    update(country: Country): Observable<EntityResponseType> {
        const copy = this.convert(country);
        return this.http.put(this.resourceUrl, copy, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<Country[]>(this.resourceUrlNoAuth, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    /**
     * Convert a Country to a JSON which can be sent to the server.
     */
    private convert(country: Country): Country {
        const copy: Country = Object.assign({}, country);
        return copy;
    }
}
