import {DeviceSchemaFieldDataAdapter} from './device-schema-field-data-adapter.model';

export class DeviceSchemaField {
    constructor(
        public name?: string,
        public displayName?: string,
        public modifiedDate?: string,
        public modifiedBy?: string,
        public readPermission?: number,
        public writePermission?: number,
        public description?: string,
        public option?: string,
        public lowAlertLevel?: string,
        public highAlertLevel?: string,
        public min?: string,
        public max?: string,
        public adapter?: DeviceSchemaFieldDataAdapter,
    ) {
    }
}
