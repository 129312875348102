import {HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EventService} from '@balrog/core/api';
import {EventMsg} from '@balrog/core/model/device';
import {faArrowLeft, faPencilAlt, faUpload, faUsers} from '@fortawesome/free-solid-svg-icons';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {Device} from './device.model';
import {DeviceService} from './device.service';

type EventMsgExt = (EventMsg & {options?: { name: string }});

@Component({
    selector: 'jhi-device-detail',
    templateUrl: './device-detail.component.html'
})
export class DeviceDetailComponent implements OnInit, OnDestroy {
    faArrowLeft = faArrowLeft;
    faUpload = faUpload;
    faPencilAlt = faPencilAlt;
    faUsers = faUsers;

    device: Device;
    private subscription: Subscription;
    private eventSubscriber: Subscription;

    events: Observable<EventMsgExt[]>;
    loading: boolean;
    hideContent = true;
    showDebug = false;
    showFilerList = false;
    page;
    total: number;

    constructor(
        private eventManager: JhiEventManager,
        private deviceService: DeviceService,
        private eventService: EventService,
        private jhiAlertService: JhiAlertService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        this.registerChangeInDevices();
    }

    load(id) {
        this.deviceService.find(id).subscribe((res: HttpResponse<Device>) => {
            this.device = res.body;
            this.getPage(1);
        });
    }

    updateFirmware(dev: boolean) {
        this.deviceService.deviceFirmwareUpdate(this.device, dev).subscribe(
            () => {
                this.jhiAlertService.success('balrogadminApp.device.alert.upgrade.' + (dev ? 'dev' : 'stable'), null, null);
        }, () => {
                this.jhiAlertService.error('Unexpected Error', null, null);
            }
        );
    }

    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInDevices() {
        this.eventSubscriber = this.eventManager.subscribe(
            'deviceListModification',
            (response) => this.load(this.device.id)
        );
    }

    getPage(page: number) {
        if (this.device) {
            this.loading = true;
            this.hideContent = true;
            const option = {
                page: page - 1,
                size: null,
                excludeLevel: null
            };
            if (!this.showDebug) {
                option.excludeLevel = ['DEBUG'];
            }
            this.page = page;
            this.events = this.eventService.query(this.device.serialNo, option).pipe(
                map((resp) => {
                    this.total = Number(resp.headers.get('X-Total-Count'));
                    this.loading = false;
                    return resp.body;
                })
            );
            setTimeout(() => {
                this.hideContent = false
            }, 1000);
        }
    }
}
