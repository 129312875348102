import {BriefPanelName, PanelTypeEnum, Unit} from '@balrog/component/types';
import {ProfileInterface} from './interface/profile.interface';

/* eslint no-bitwise: ["error", { "allow": ["&", "<<"] }] */

export class TweetopPompaProfile implements ProfileInterface {
    statuses = {};

    alarms = {};

    briefPanel = {
        name: BriefPanelName.TWEETOP
    };

    dashBoard = {
        panelType: PanelTypeEnum.TWEETOP_POMPA
    };

    historyBoard = {
        title: 'Temperatura [°C]',
        content: [{
            paramId: 'HOT_WATER_TEMP',
            unit: Unit.C
        }, {
            paramId: 'INLET_WATER_TEMP',
            unit: Unit.C
        }, {
            paramId: 'OUTLET_WATER_TEMP',
            unit: Unit.C
        }, {
            paramId: 'AMBIENT_TEMP',
            unit: Unit.C
        }]
    };

    listBoard = {
        title: 'Konfiguracja urzadzenia',
        content: [{
            title: 'Ogólne',
            type: 'MENU',
            content: [
                {
                    paramId: 'ON_OFF',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'OFF'}, {value: 'true', name: 'ON'}]
                }, {
                    paramId: 'MODE',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'CWU'},
                        {value: '1', name: 'Ogrzewanie'},
                        {value: '2', name: 'Chłodzenie'},
                        {value: '3', name: 'CWU + ogrzewanie'},
                        {value: '4', name: 'CWU + chłodzenie'}
                    ]
                }, {
                    paramId: 'MANUAL_CONTROL',
                    type: 'ITEM-TEXT',
                    inputConverter(v: number) {
                        const text = [];
                        if (v & (1 << 0)) {text.push('Odmrażanie'); }
                        if (v & (1 << 1)) {text.push('???'); }
                        if (v & (1 << 2)) {text.push('?!?'); }
                        return text.length ? text.join(',') : '---';
                    }
                }, {
                    paramId: 'AUTOMATIC_RESTART',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
                }, {
                    paramId: 'COOLING_MODE',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
                }, {
                    paramId: 'CONTROLER_CHOICE',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Wyświetlacz'},
                        {value: '1', name: 'Zdalne sterowanie'}
                    ]
                }, {
                    paramId: 'UNIT_ADDRESS'
                }, {
                    paramId: 'EVI_ENABLED',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'Brak EVI'},
                        {value: '1', name: 'EVI chłodzenie'},
                        {value: '2', name: 'EVI ogrzewanie'},
                        {value: '3', name: 'EVI chłodzenie i ogrzewanie'}
                    ]
                }, {
                    paramId: 'HOT_WATER_MODE',
                    type: 'ITEM-SWITCH',
                    options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
                }, {
                    paramId: 'TEMP_UNIT',
                    type: 'ITEM-SELECT',
                    options: [
                        {value: '0', name: 'C'},
                        {value: '1', name: 'F'}
                    ]
                }
            ]
        }, {
            paramId: 'SILENCE_MODE',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
        }, {
            paramId: 'ELETRIC_HEATER_ENERGY_STATE',
            type: 'ITEM-SELECT',
            options: [
                {value: '0', name: 'Etap 1'},
                {value: '1', name: 'Etap 2'},
                {value: '2', name: 'Etap 3'}
            ]
        }, {
            paramId: 'VALVE_3WAY_POLARITY',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
        }, {
            paramId: 'MODEL_RUNNING_PARAM_CODE'
        }, {
            paramId: 'CONTROL_TEMP_CHOICE_MODE',
            type: 'ITEM-SELECT',
            options: [
                {value: '0', name: 'Temp. na wylocie'},
                {value: '1', name: 'Temp. w pokoju'}
            ]
        }, {
            paramId: 'HEAT_PUMP_SHUTDOWN_AMBIENT_TEMP',
            unit: Unit.C
        }, {
            paramId: 'ANTIFREEZE_SETTING_TEMP',
            unit: Unit.C
        }, {
            paramId: 'ANTIFREEZE_SETTING_TEMP_DIFFERENCE',
            unit: Unit.C
        }, {
            paramId: 'EXHAUST_TEMP_PROTECT_SETTING',
            unit: Unit.C
        }, {
            paramId: 'ANTIFREZE_SETTING_MIN_TEMP',
            unit: Unit.C
        }, {
            paramId: 'FAN_MOTOR_TYPE',
            type: 'ITEM-SELECT',
            options: [
                {value: '1', name: 'Podwójna prędkość'},
                {value: '3', name: 'Silnik DC'}
            ]
        }, {
            paramId: 'COIL_TEMP_SETTING_FAN_HIGH_COOL',
            unit: Unit.C
        }, {
            paramId: 'COIL_TEMP_SETTING_FAN_LOW_COOL',
            unit: Unit.C
        }, {
            paramId: 'COIL_TEMP_SETTING_FAN_HIGH_HEAT',
            unit: Unit.C
        }, {
            paramId: 'COIL_TEMP_SETTING_FAN_LOW_HEAT',
            unit: Unit.C
        }, {
            paramId: 'FAN_QUANTITY',
            type: 'ITEM-SELECT',
            options: [
                {value: '0', name: 'single'},
                {value: '1', name: 'double'}
            ]
        }, {
            paramId: 'FAN_MIN_SPEED_COOL',
            unit: Unit.RPM
        }, {
            paramId: 'FAN_MIN_SPEED_HEAT',
            unit: Unit.RPM
        }, {
            paramId: 'TIMER_MUTE',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
        }, {
            paramId: 'FAN_SPEED_MANUAL_CONTROL',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
        }, {
            paramId: 'FAN_DC_RATED_SPEED',
            unit: Unit.RPM
        }, {
            paramId: 'FAN_MAX_SPEED_COOL',
            unit: Unit.RPM
        }, {
            paramId: 'FAN_MAX_SPEED_HEAT',
            unit: Unit.RPM
        }, {
            paramId: 'DEFROST_START_SETPOINT_TEMP',
            unit: Unit.C
        }, {
            paramId: 'DEFROST_EXIT_SETPOINT_TEMP',
            unit: Unit.C
        }, {
            paramId: 'DEFROST_CYCLE',
            unit: Unit.MIN
        }, {
            paramId: 'DEFROST_MAX_TIME',
            unit: Unit.MIN
        }, {
            paramId: 'ELECTRIC_HEATER_CONTROL',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
        }, {
            paramId: 'DEFROST_SLIDING_AMBIENT_TEMP',
            unit: Unit.C
        }, {
            paramId: 'DEFROST_SLIDING_AMBIENT_TEMP_DIFF',
            unit: Unit.C
        }, {
            paramId: 'DEFROST_SLIDING_COIL_TEMP_DIFF',
            unit: Unit.C
        }, {
            paramId: 'DEFROST_SLIDING_COIL_TEMP',
            unit: Unit.C
        }, {
            paramId: 'DEFROST_COMPRESSOR_FREQUENCY',
            unit: Unit.HZ
        }, {
            paramId: 'EEV1_ADJUST_MODE',
            type: 'ITEM-SELECT',
            options: [
                {value: '0', name: 'Manual'},
                {value: '1', name: 'Auto'}
            ]
        }, {
            paramId: 'EEV1_INITIAL_STEPS',
            unit: Unit.N
        }, {
            paramId: 'EEV1_TARGET_SUPERHEAT_TEMP',
            unit: Unit.C
        }, {
            paramId: 'EEV_MIN_STEPS',
            unit: Unit.N
        }, {
            paramId: 'COOLING_INITIAL_STEPS',
            unit: Unit.N
        }, {
            paramId: 'EVI1_ADJUSTMENT_MODE',
            type: 'ITEM-SELECT',
            options: [
                {value: '0', name: 'Manual'},
                {value: '1', name: 'Auto'}
            ]
        }, {
            paramId: 'EVI1_INITIAL_STEPS',
            unit: Unit.N
        }, {
            paramId: 'EVI_TARGET_SUPERHEAT_TEMP',
            unit: Unit.C
        }, {
            paramId: 'EVI_MIN_STEPS',
            unit: Unit.N
        }, {
            paramId: 'DEFROST_STEPS',
            unit: Unit.N
        }, {
            paramId: 'TARGET_SUPERHEAT_COOL_TEMP',
            unit: Unit.C
        }, {
            title: 'Dezynfekcja',
            type: 'MENU',
            content: [{
                paramId: 'STERILIZE_TARGET_TEMP',
                unit: Unit.C
            }, {
                paramId: 'STERILIZE_MAINTAIN_TIME',
                unit: Unit.MIN
            }, {
                paramId: 'STERILIZE_START_TIME',
                unit: Unit.HOUR
            }, {
                paramId: 'STERILIZE_CYCLE',
                unit: Unit.DAY
            }, {
                paramId: 'STERILIZE_FUNCITON',
                type: 'ITEM-SWITCH',
                options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
            }]
        }, {
            paramId: 'HOT_WATER_TARGET_TEMP',
            unit: Unit.C
        }, {
            paramId: 'HEATING_TARGET_TEMP',
            unit: Unit.C
        }, {
            paramId: 'COOLING_TARGET_TEMP',
            unit: Unit.C
        }, {
            title: 'Sprężąrka',
            type: 'MENU',
            content: [{
                paramId: 'HEATING_RESTART_DIFF_TEMP',
                unit: Unit.C
            }, {
                paramId: 'HEATING_CONSTANT_DOWNTIME_DIFF_TEMP',
                unit: Unit.C
            }, {
                paramId: 'MIN_COOLING_SETPOINT',
                unit: Unit.C
            }, {
                paramId: 'MAX_COOLING_SETPOINT',
                unit: Unit.C
            }, {
                paramId: 'MIN_HEATING_SETPOINT',
                unit: Unit.C
            }, {
                paramId: 'MAX_HEATING_SETPOINT',
                unit: Unit.C
            }, {
                paramId: 'EXIT_OUTER_WATER_HIGH_DIFF_TEMP',
                unit: Unit.C
            }, {
                paramId: 'HEATING_COMPENSATION_ON_LOW_AMBIENT_TEMP',
                unit: Unit.C
            }, {
                paramId: 'HEATING_COMPENSATION_OFF_LOW_AMBIENT_TEMP',
                unit: Unit.C
            }, {
                paramId: 'LOW_AT_MAX_COMPENSATION_AIM_TEMP',
                unit: Unit.C
            }, {
                paramId: 'HIGH_WATER_COMPENSATION_ON_HIGH_AMBIENT_TEMP',
                unit: Unit.C
            }, {
                paramId: 'HIGH_WATER_COMPENSATION_OFF_HIGH_AMBIENT_TEMP',
                unit: Unit.C
            }, {
                paramId: 'HIGH_WATER_COMPENSATION_ON_HIGH_AMBIENT_TARGET_TEMP',
                unit: Unit.C
            }, {
                paramId: 'ELECTRIC_HEATER_FUNCITON',
                type: 'ITEM-SELECT',
                options: [
                    {value: '0', name: 'Wyłączony'},
                    {value: '1', name: 'Linia wody'},
                    {value: '2', name: 'Boiler'}
                ]
            }, {
                paramId: 'COOLING_RESTART_DIFFERENCE_TEMP',
                unit: Unit.C
            }, {
                paramId: 'COOLING_CONSTANT_DOWNTIME_DIFF_TEMP',
                unit: Unit.C
            }, {
                paramId: 'MIN_HOT_WATER_SETPOINT_TEMP',
                unit: Unit.C
            }, {
                paramId: 'MAX_HOT_WATER_SETPOINT_TEMP',
                unit: Unit.C
            }, {
                paramId: 'HEATING_MODE_AUTO_RESTART_AMBIENT_TEMP',
                unit: Unit.C
            }, {
                paramId: 'WATER_PUMP_RUNNING_AMBIENT_TEMP',
                unit: Unit.C
            }, {
                paramId: 'HOT_WATER_PUMP_RUNNING_AMBIENT_TEMP',
                unit: Unit.C
            }, {
                paramId: 'HOT_WATER_RESTART_DIFF_TEMP',
                unit: Unit.C
            }, {
                paramId: 'HOT_WATER_CONSTANT_DOWNTIME_DIFF_TEMP',
                unit: Unit.C
            }]
        }, {
            title: 'Pompa',
            type: 'MENU',
            content: [{
                paramId: 'WATER_PUMP_RUNNING_MODE',
                type: 'ITEM-SELECT',
                options: [
                    {value: '0', name: 'Normalny'},
                    {value: '1', name: 'Ekonomiczny'},
                    {value: '2', name: 'Czasowy'}
                ]
            }, {
                paramId: 'WATER_PUMP_RUNNING_INTERVAL_TIME',
                unit: Unit.MIN
            }, {
                paramId: 'WATER_PUMP_RUNNING_DURATION',
                unit: Unit.MIN
            }, {
                paramId: 'HOT_WATER_PUMP_WORKING_MODE',
                type: 'ITEM-SELECT',
                options: [
                    {value: '0', name: 'Normalny'},
                    {value: '1', name: 'Ekonomiczny'},
                    {value: '2', name: 'Czasowy'}
                ]
            }]
        }, {
            title: 'Timer',
            type: 'MENU',
            content: [{
                paramId: 'TIMER_FUNCTION_START',
                type: 'ITEM-SWITCH',
                options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
            }, {
                paramId: 'TIMER_FUNCTION_START_HOUR'
            }, {
                paramId: 'TIMER_FUNCTION_CLOSED',
                type: 'ITEM-SWITCH',
                options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
            }, {
                paramId: 'TIMER_FUNCTION_CLOSED_HOUR'
            }, {
                paramId: 'TIMER_FUNCTION_MINUTER_START'
            }, {
                paramId: 'TIMER_FUNCTION_MINUTER_CLOSED'
            }]
        }, {
            title: 'Kompresor',
            type: 'MENU',
            content: [{
                paramId: 'COMPRESSOR_MANUAL_FREQ',
                unit: Unit.HZ
            }, {
                paramId: 'COMPRESSOR_MIN_FREQ',
                unit: Unit.HZ
            }, {
                paramId: 'COMPRESSOR_MAX_FREQ',
                unit: Unit.HZ
            }, {
                paramId: 'COMPRESSOR_MODEL_SELECTION'
            }, {
                paramId: 'COMPRESSOR_MIN_FREQ_OF_LOW_TEMP_AMBIENT',
                unit: Unit.HZ
            }, {
                paramId: 'COMPRESSOR_FREQ_CONTROL_MODE'
            }]
        }, {
            paramId: 'MAX_WATER_HEATING_TEMP',
            unit: Unit.C
        }, {
            paramId: 'MAX_WATER_HEATING_TEMP_UNDER_LOW_TEMP',
            unit: Unit.C
        }, {
            paramId: 'MAX_WATER_HEATING_TEMP_UNDER_HIGH_TEMP',
            unit: Unit.C
        }, {
            paramId: 'ELECTRIC_HEATER_START_AMBIENT_TEMP',
            unit: Unit.C
        }, {
            paramId: 'UNIT_STATE',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
        }, {
            paramId: 'UNIT_MODE',
            type: 'ITEM-SELECT',
            options: [
                {value: '0', name: 'Chłodzenie'},
                {value: '1', name: 'Ogrzewanie'},
                {value: '2', name: 'Odmrażanie'},
                {value: '3', name: 'Dezynfekcja'},
                {value: '4', name: 'Ciepła woda'}
            ]
        }, {
            paramId: 'TEMPERATURE',
            unit: Unit.C
        }, {
            paramId: 'DTU_STATE',
            type: 'ITEM-SWITCH',
            options: [{value: 'false', name: 'Nie'}, {value: 'true', name: 'Tak'}]
        }



        , {
            paramId: 'EEV_STEPS',
            unit: Unit.N
        }, {
            paramId: 'EVI_EEV_STEPS',
            unit: Unit.N
        }

        , {
            paramId: 'COMPRESSOR_CURRENTS'
        }, {
            paramId: 'DC_LINE_VOLT'
        }, {
            paramId: 'IPM_TEMP',
            unit: Unit.C
        }, {
            paramId: 'INLET_WATER_TEMP',
            unit: Unit.C
        }, {
            paramId: 'OUTLET_WATER_TEMP',
            unit: Unit.C
        }, {
            paramId: 'COIL_TEMP',
            unit: Unit.C
        }, {
            paramId: 'SUCTION_TEMP',
            unit: Unit.C
        }, {
            paramId: 'DISCHARGE_TEMP',
            unit: Unit.C
        }, {
            paramId: 'ANTIFREEZE_TEMP',
            unit: Unit.C
        }, {
            paramId: 'AC_INPUT_CURRENTS'
        }, {
            paramId: 'ROOM_TEMP',
            unit: Unit.C
        }, {
            paramId: 'IP_MOVE_HEAT_STOP_RUNNING_TEMP',
            unit: Unit.C
        }, {
            paramId: 'AC_INPUT_VOLTAGE'
        }, {
            paramId: 'EVI_INLET_TEMP',
            unit: Unit.C
        }, {
            paramId: 'EVI_OUTLET_TEMP',
            unit: Unit.C
        }, {
            paramId: 'COMPRESSOR_FREQ_SETTING',
            unit: Unit.HZ
        }, {
            paramId: 'COMPRESSOR_RUNNING_FREQ_SETTING',
            unit: Unit.HZ
        }, {
            paramId: 'INVERTER_BOARD_MAX_FREQ',
            unit: Unit.HZ
        }, {
            paramId: 'DC_FAN1_SPEED',
            unit: Unit.RPM
        }, {
            paramId: 'DC_FAN2_SPEED',
            unit: Unit.RPM
        }, {
            paramId: 'DC_FAN_TARGET_SPEED',
            unit: Unit.RPM
        }
        ]
    };

    scheduleBoard = null;
}
