import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BalrogadminSharedModule } from '../shared';

import { WELCOME_ROUTE, WelcomeComponent } from './';
import {StatisticsModule} from '../statistics/statistics.module';

@NgModule({
    imports: [
        BalrogadminSharedModule,
        RouterModule.forChild([ WELCOME_ROUTE ]),
        StatisticsModule
    ],
    declarations: [
        WelcomeComponent,
    ],
    entryComponents: [
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BalrogadminWelcomeModule {}
