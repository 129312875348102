import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DeviceAccessService} from '@balrog/core';
import {faBan, faTimes} from '@fortawesome/free-solid-svg-icons';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';
import {DeviceAccessPopupService} from './device-access-popup.service';

import {DeviceAccess} from './device-access.model';

@Component({
    selector: 'jhi-device-access-delete-dialog',
    templateUrl: './device-access-delete-dialog.component.html'
})
export class DeviceAccessDeleteDialogComponent {
    faTimes = faTimes;

    faBan = faBan;
    deviceAccess: DeviceAccess;

    constructor(
        private deviceAccessService: DeviceAccessService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.deviceAccessService.delete(id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'deviceAccessListModification',
                content: 'Deleted an deviceAccess'
            });
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'jhi-device-access-delete-popup',
    template: ''
})
export class DeviceAccessDeletePopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private deviceAccessPopupService: DeviceAccessPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.deviceAccessPopupService
                .open(DeviceAccessDeleteDialogComponent as Component, params['id']);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
