import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'jhi-list-board-row-array-range',
    templateUrl: './list-board-row-array-range.component.html',
    styleUrls: ['./device-configuration-list.component.scss']
})
export class ListBoardRowArrayRangeComponent implements OnInit {
    @Input() highlighted: boolean;
    @Input() content: any;

    constructor() {}

    ngOnInit() {
    }

    isArray() {
        return this.content.value instanceof Array;
    }

}
