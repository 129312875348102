import {Component} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';

import {faBan, faTimes} from '@fortawesome/free-solid-svg-icons';
import {NotificationModel, NotificationService, NotificationStatus} from 'app/management';

@Component({
    selector: 'jhi-notification-status-change-dialog',
    templateUrl: './notification-status-change-dialog.component.html'
})
export class NotificationStatusChangeDialogComponent {
    faTimes = faTimes;
    faBan = faBan;
    NotificationStatus = NotificationStatus;
    notification: NotificationModel;

    constructor(
        private notificationService: NotificationService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager
    ) {
    }

    canBeActive() {
        const status = NotificationStatus[this.notification.status];
        return status === NotificationStatus.CREATED || status === NotificationStatus.INACTIVE || status === NotificationStatus.ERROR;
    }

    canBeInactive() {
        const status = NotificationStatus[this.notification.status];
        return status === NotificationStatus.ACTIVE || status === NotificationStatus.ERROR;
    }

    canBeFinished() {
        const status = NotificationStatus[this.notification.status];
        return status === NotificationStatus.ACTIVE || status === NotificationStatus.INACTIVE;
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    changeStatus(status: NotificationStatus) {
        this.notificationService.changeStatus(this.notification.id, status).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'messageListModification',
                content: 'Deleted an message'
            });
            this.notification.status = status;
            this.activeModal.dismiss(true);
        });
    }
}
