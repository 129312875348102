// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DataAdapter {
    const adapters = {
        TEXT: {
            values: [
                {name: 'paramId'}
            ]
        },
        NUMBER: {
            values: [
                {name: 'paramId'}
            ]
        },
        NUMBER_COMPOSED: {
            values: [
                {name: 'paramId'},
                {name: 'paramIdMod'}
            ]
        },
        BOOL: {
            values: [
                {name: 'paramId'}
            ]
        },
        DATE: {
            values: [
                {name: 'yearParamId'},
                {name: 'monthParamId'},
                {name: 'dayParamId'}
            ],
            options: [
                {name: 'arrayFrom'},
                {name: 'arrayTo'}
            ]
        },
        TIME: {
            values: [
                {name: 'hourParamId'},
                {name: 'minParamId'},
                {name: 'secParamId'}
            ],
            options: [
                {name: 'arrayFrom'},
                {name: 'arrayTo'}
            ]
        },
        NUMBER_32BIT: {
            values: [
                {name: 'lower16bitParamId'},
                {name: 'upper16bitParamId'}
            ],
            options: [
                {name: 'multiplyModifierParamId'}
            ]
        }
    };

    // eslint-disable-next-line no-inner-declarations
    export function names(): Array<string> {
        return Object.keys(adapters);
    }

    // eslint-disable-next-line no-inner-declarations
    export function definition(name): Object {
        return adapters[name];
    }
}
