/* tslint:disable:max-line-length */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
    selector: 'jhi-text-editable',
    template: `<div style="display: inline-block">
        <mdl-textfield
            autocomplete="off"
            *ngIf="focused"
            type="text"
            [(ngModel)]="_value"
            (keydown.enter)="send()"
            (blur)="blur()"
        ></mdl-textfield>
        <span *ngIf="!focused" (click)="focus()" style="font-size: large;">{{_value}}</span>
        <span *ngIf="saveSuccess" class="mdl-color--green-100 ml-2" style="padding: 8px;border-radius: 30px;position: absolute;z-index: 100;">Zapisano</span>
        <span *ngIf="saveError" class="mdl-color--red-100 ml-2" style="padding: 8px;border-radius: 30px;position: absolute;z-index: 100;">Błąd zapisu</span>
        <span *ngIf="saveLoader" class="ml-2"><img
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/></span>
        <button mdl-button mdl-colored="primary" *ngIf="!readonly && !dirty() && !focused" (click)="focus()">
            <mdl-icon>create</mdl-icon>
        </button>
        <button mdl-button mdl-button-type="mini-fab" mdl-colored="primary" *ngIf="dirty()" (click)="send()" style="margin: 4px">
            <mdl-icon>done</mdl-icon>
        </button>
        <button mdl-button mdl-button-type="mini-fab" mdl-colored="accent" *ngIf="dirty()" (click)="blur()" style="margin: 4px">
            <mdl-icon>clear</mdl-icon>
        </button>
    </div>`,
    styleUrls: []
})
export class TextEditableComponent implements OnInit {
    _value: any;
    orgValue: any;

    @Input()
    set value(value: any) {
        this._value = value;
        this.orgValue = value;
    }

    @Input() readonly: boolean;
    @Input() handleSend: (string) => Observable<any>;
    @Output() onSuccess = new EventEmitter<any>();
    @Output() onError = new EventEmitter<any>();

    focused: boolean;
    saveSuccess: boolean;
    saveError: boolean;
    saveLoader: boolean;

    constructor(
    ) {
    }

    ngOnInit() {
    }

    focus(): void {
        if (!this.readonly) {
            this.focused = true;
        }
    }

    blur(): void {
        setTimeout(() => {
            if (this.focused) {
                this.focused = false;
                this._value = this.orgValue;
            }
        }, 100);
    }

    dirty(): boolean {
        return this._value !== this.orgValue && this.focused;
    }

    send() {
        this.saveLoader = true;
        const value = this._value;
        this.handleSend(value).subscribe(() => {
            this.saveLoader = false;
            this.saveSuccess = true;
            setTimeout(() => {
                this.saveSuccess = false;
            }, 1000);
            this.onSuccess.next(value);
        }, () => {
            this.saveError = true;
            this.saveLoader = false;
            setTimeout(() => {
                this.saveError = false;
            }, 2000);
            this.onError.next(value);
        });
    }
}
