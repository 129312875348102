import { Routes } from '@angular/router';

import {
    userMgmtRoute,
    userDialogRoute
} from './';

import { UserRouteAccessService } from '../shared';

const USER_ROUTES = [
    ...userMgmtRoute
];

export const userState: Routes = [{
    path: '',
    data: {
        authorities: ['ROLE_USER']
    },
    canActivate: [UserRouteAccessService],
    children: USER_ROUTES
},
    ...userDialogRoute
];
