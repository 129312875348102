import {BriefPanelName, InteractiveButtonInterface, PanelTypeEnum, Unit} from '@balrog/component/common';
import {take} from 'rxjs/operators';
import {ProfileInterface} from './interface/profile.interface';

export class MinisterElektrProfile implements ProfileInterface {
  statuses = {};

  // alarmy
  alarms = {
    CZUJNIK_CO: {title: 'Czujnik CO'},
    KOCIOL_PRZEGRZANY: {title: 'Kocioł przegrzany'},
    STB: {title: 'Stb'},
    STB_230V: {title: 'Stb 230V'},
    BRAK_PRZEPLYWU: {title: 'Brak przepływu'},
    CZUJNIK_CISNIENIA: {title: 'Czujnik ciśnienia'},
    NISKIE_CISNIENIE: {title: 'Niskie ciśnienie'},
    WYSOKIE_CISNIENIE: {title: 'Wysokie ciśnienie'},
    CZUJNIK_CWU: {title: 'Czujnik CWU'}
  };

  briefPanel = {
    name: BriefPanelName.ELEKTROMIZ_MINISTER_ELEKTR
  };

  dashBoard = {
    panelType: PanelTypeEnum.ELEKTROMIZ_MINISTER_ELEKTR
  };

  historyBoard = {
    title: 'Temperatura [°C]',
    content: [{
      paramId: 'TEMP_KOTLA',
      unit: Unit.C
    }, {
      paramId: 'NST_KOTLA',
      unit: Unit.C,
      type: 'stepped'
    }, {
      paramId: 'TEMP_BOILERA',
      unit: Unit.C
    }, {
      paramId: 'NST_BOILERA',
      unit: Unit.C,
      type: 'stepped'
    }]
  };

  listBoard = {
    title: 'Konfiguracja',
    content: [{
      paramId: 'NST_KOTLA',
      unit: Unit.C
    }, {
      paramId: 'NST_BOILERA',
      unit: Unit.C
    }, {
      paramId: 'CISNIENIE',
      unit: Unit.BAR,
      step: 0.1
    }, {
      paramId: 'TEMP_ZAWOR_1',
      unit: Unit.C,
      step: 0.1,
    }, {
      paramId: 'NST_ZAWOR_1',
      unit: Unit.C
    }, {
      paramId: 'TEMP_POKOJ',
      unit: Unit.C,
      step: 0.1
    }, {
      paramId: 'ZUZYCIE_ENERGII',
      unit: Unit.KWH
    }, {
      type: 'ITEM-TEXT',
      paramId: 'WERSJA_STEROWNIKA'
    }, {
      type: 'ITEM-TEXT',
      paramId: 'WERSJA_ALIGATOR'
    }, {
      paramId: 'NST_HISTEREZA_CO',
      unit: Unit.C
    }, {
      paramId: 'NST_HISTEREZA_CWU',
      unit: Unit.C
    }, {
      paramId: 'MAX_MOC_KOTLA',
      unit: Unit.KW
    }, {
      paramId: 'MODULACJA',
      type: 'ITEM-SELECT',
      readonly: true,
      options: [
        {value: '0', name: 'Wyłączona'},
        {value: '1', name: 'Praca skokowa'},
        {value: '2', name: 'Praca ciągła'}
      ]
    }, {
      paramId: 'NOW_TIME',
      type: 'ITEM-TEXT',
      placeholder: 'HH:MM'
    }, {
      type: 'ITEM-BUTTON',
      paramId: 'SYNCHRONIZE',
      config: {
        defaultTitle: 'loading...',
        class: 'xLarge',
        diagramState: {
          ENABLED: {
            title: 'Synchronizuj',
            onClick(event: InteractiveButtonInterface) {
              function roundTo2(n: number) {
                return n < 10 ? '0' + n : n;
              }
              const date = new Date();
              const systemTime = date.getHours() + ':' + date.getMinutes();
              const systemDate = date.getFullYear() + ':' + roundTo2(date.getMonth()) + '-' + roundTo2(date.getDay());
              event.sendConfiguration([{paramId: 'NOW_TIME', value: systemTime}, {paramId: 'NOW_DATE', value: systemDate}]);
              event.setState('WAITING');
              setTimeout(() => event.setState('ENABLED'), 30000);
            }
          },
          WAITING: {
            title: 'Synchronizacja ...',
            onClick() {
            }
          }
        },
        init(event: InteractiveButtonInterface) {
          event.data$.pipe(take(1)).subscribe((dataPackage) => {
            // const date = new Date();
            // const systemTime = date.getHours() + ":" + date.getMinutes();
            event.setState('ENABLED');
            // event.setTitle(systemTime);
          });
        }
      }
    }, {
      paramId: 'NOW_DATE',
      type: 'ITEM-TEXT',
      placeholder: 'yyyy-mm-dd'
    }]
  };

  scheduleBoard = null;
}
