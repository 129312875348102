import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {ContactMessage} from './message-message.model';
import {CORE_API_URL} from '../../app.constants';

@Injectable()
export class MessageService {

    constructor(private http: HttpClient) {
    }

    sendMessage(message: ContactMessage): Observable<any> {
        return this.http.post(CORE_API_URL + '/v1/messages/send', message);
    }
}
