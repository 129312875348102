import {CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';

import {BalrogadminSharedModule, UserRouteAccessService} from '../../shared';

import {NotificationBrowseComponent, NotificationComponent, NotificationModifyComponent, NotificationService} from './';
import {MdlDatePickerModule} from '@angular-mdl/datepicker';
import {JhiPaginationUtil} from 'ng-jhipster';
import {NotificationStatusChangeDialogComponent} from 'app/management/notification/components/notification-status-change-dialog.component';
import {NotificationPopupService} from 'app/management/notification/notification-popup.service';
import {AngularEditorModule} from '@kolkov/angular-editor';

@Injectable()
export class NotificationResolvePagingParams implements Resolve<any> {

    constructor(private paginationUtil: JhiPaginationUtil) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const page = route.queryParams['page'] ? route.queryParams['page'] : '1';
        const sort = route.queryParams['sort'] ? route.queryParams['sort'] : 'id,asc';
        return {
            page: this.paginationUtil.parsePage(page),
            predicate: this.paginationUtil.parsePredicate(sort),
            ascending: this.paginationUtil.parseAscending(sort)
        };
    }
}


export const notificationState: Routes = [{
    path: 'manage',
    canActivate: [UserRouteAccessService],
    resolve: {
        'pagingParams': NotificationResolvePagingParams
    },
    data: {
        authorities: ['ROLE_ORG_MANAGER'],
        pageTitle: 'notification.pageTitle'
    },
    children: [{
        path: 'notification/create',
        component: NotificationModifyComponent,
    }, {
        path: 'notification/:id',
        component: NotificationModifyComponent,
    }, {
        path: 'notification',
        component: NotificationComponent,
    }]
}
]

@NgModule({
    imports: [
        BalrogadminSharedModule,
        MdlDatePickerModule,
        AngularEditorModule,
        RouterModule.forChild(notificationState),
    ],
    declarations: [
        NotificationComponent,
        NotificationModifyComponent,
        NotificationBrowseComponent,
        NotificationStatusChangeDialogComponent,
    ],
    entryComponents: [
        NotificationComponent,
        NotificationModifyComponent,
        NotificationBrowseComponent,
        NotificationStatusChangeDialogComponent,
    ],
    providers: [
        NotificationResolvePagingParams,
        NotificationService,
        NotificationPopupService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NotificationModule {}
