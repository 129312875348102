import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {BalrogadminSharedModule} from '../shared';

import {
    adminState,
    GatewayRoutesService,
    JhiConfigurationComponent,
    JhiConfigurationService,
    JhiGatewayComponent,
    JhiHealthCheckComponent,
    JhiHealthModalComponent,
    JhiHealthService,
    JhiMetricsMonitoringComponent,
    JhiMetricsMonitoringModalComponent,
    JhiMetricsService,
    LogsComponent,
    LogsService
} from './';
import {JhiServerChooserComponent} from './server-chooser.component';

@NgModule({
    imports: [
        BalrogadminSharedModule,
        RouterModule.forChild(adminState),
    ],
    declarations: [
        LogsComponent,
        JhiConfigurationComponent,
        JhiHealthCheckComponent,
        JhiHealthModalComponent,
        JhiGatewayComponent,
        JhiMetricsMonitoringComponent,
        JhiMetricsMonitoringModalComponent,
        JhiServerChooserComponent,
    ],
    entryComponents: [
        JhiHealthModalComponent,
        JhiMetricsMonitoringModalComponent,
    ],
    providers: [
        JhiConfigurationService,
        JhiHealthService,
        JhiMetricsService,
        GatewayRoutesService,
        LogsService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BalrogadminAdminModule {}
