import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, Input} from '@angular/core';
import {EventMsg} from '@balrog/core/model/device';

@Component({
    selector: 'blg-event-body',
    templateUrl: 'event-body.component.html',
    styleUrls: ['event.component.scss'],
    animations: [
        trigger('level', [
            state('WARN', style({
                color: '#ae7e00'
            })),
            state('ERROR', style({
                color: '#ae5600'
            })),
            state('ALERT', style({
                backgroundColor: '#ae0600'
            })),
            transition('* => *', animate('100ms ease-in')),
        ]),
    ]
})
export class EventBodyComponent {

    @Input()
    event: EventMsg | undefined;
}
