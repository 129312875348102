import {BaseEntity} from './../../shared';

export class DeviceAccess implements BaseEntity {
    constructor(
        public id?: number,
        public accessLevel?: number,
        public createdDate?: any,
        public createdBy?: string,
        public modifiedDate?: any,
        public modifiedBy?: string,
        public userId?: number,
        public userEmail?: string,
        public deviceId?: number,
        public deviceName?: string,
        public deviceSerialNo?: string,
        public notificationOptions?: NotificationOptions
    ) {
    }
}
