import {Component, Input, TemplateRef} from '@angular/core';

@Component({
    selector: 'jhi-table',
    templateUrl: './table.component.html'
})
export class TableComponent {
    Object = Object;

    @Input() headData = {};
    @Input() bodyData: any[] = [];
    @Input() customFields: TemplateRef<any>[] = [];
    @Input() actions: TemplateRef<any>;
}
