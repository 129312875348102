import { NgModule } from '@angular/core';

import {StatisticsUsersComponent} from './statistics-users.component';
import {BalrogadminSharedCommonModule} from '../../shared/shared-common.module';

@NgModule({
    imports: [
        BalrogadminSharedCommonModule
    ],
    declarations: [
        StatisticsUsersComponent
    ],
    entryComponents: [
        StatisticsUsersComponent
    ],
    exports: [
        StatisticsUsersComponent
    ]
})
export class StatisticsUsersModule {}
