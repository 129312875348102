import {MdlDialogReference, MdlDialogService} from '@angular-mdl/core';
import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '@balrog/core';
import {DataPackage} from '@balrog/core/model/data';
import {Device} from '@balrog/core/model/device';
import {ConfigContentInterface, ConfigContentMenuInterface, ListboardConfigInterface} from '@balrog/profile';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {SaveSetpointsDialogComponent} from './dialog/save-setpoints.dialog';

@Component({
    selector: 'blg-device-configuration-list',
    templateUrl: './device-configuration-list.component.html',
    styleUrls: ['./device-configuration-list.component.scss'],
    providers: []
})
export class DeviceConfigurationListComponent implements OnInit {

    @Input() device: Device;

    @Input() data$: Observable<DataPackage>;

    @Input() config: ListboardConfigInterface;

    contents: Array<ConfigContentInterface>;

    constructor(
        private dataService: DataService,
        private dialogService: MdlDialogService
    ) {

    }
    private static stepRound(value: number, step: number): any {
        if (step && value) {
            const precision = Math.ceil(Math.log10(1 / step + 1)) - 1;
            return value.toFixed(precision > 0 ? precision : 0);
        }
        return value
    }

    ngOnInit() {
        this.contents = this.config.content;
        this.updateData();
    }

    handleSaveChanges(): void {
        const deviceDisconnected = this.device.status !== 'CONNECTED';
        if (deviceDisconnected) {
            this.dialogService.alert('Urządzenie jest rozłączone, sprawdź stan połączenia i spróbuj ponownie');
        } else {
            this.saveChanges();
        }

    }

    private saveChanges() {
        const changes = this.findChanges([], this.config?.content);
        if (changes.length > 0) {
            const pDialog = this.dialogService.showCustomDialog({
                component: SaveSetpointsDialogComponent,
                providers: [
                    {provide: 'setpoints', useValue: changes},
                    {provide: 'data$', useValue: this.data$},
                    {provide: 'device', useValue: this.device},
                    ],
                isModal: true,
                styles: {'width': '450px'},
                clickOutsideToClose: true,
                enterTransitionDuration: 400,
                leaveTransitionDuration: 400
            });
            pDialog.subscribe((dialogReference: MdlDialogReference) => {
                dialogReference.onHide().subscribe(() => this.updateData());
            });
        } else {
            this.dialogService.alert('Nie ma żadnej zmiany nastawy do wysłania');
        }
    }

    handleRefreshData(): void {
        this.updateData();
    }

    private updateData(): void {
        console.warn('DATA LOADED');
        if (this.data$) {
            this.data$
                .pipe(take(1))
                .subscribe((dataPackage) => {
                    if (dataPackage && this.contents) {
                        this.processPackage(dataPackage, this.contents);
                    }
                });
        }
    }

    private processPackage(dataPackage: DataPackage, contents: Array<ConfigContentInterface>) {
        contents.forEach((content) => {
            if (content.type === 'MENU') {
                const menu = content as ConfigContentMenuInterface;
                return this.processPackage(dataPackage, menu.content);
            } else {
                const entry = content as any;
                if (entry.paramId !== null && dataPackage.data[entry.paramId] ) {
                    entry.value = DeviceConfigurationListComponent.stepRound(dataPackage.data[entry.paramId].value as number, entry.step);
                    entry.changeTo = entry.value;
                    entry.timestamp = dataPackage.data[entry.paramId].timestamp;
                    entry.modifiedBy = dataPackage.data[entry.paramId].modifiedBy;
                    entry.confirmed = dataPackage.data[entry.paramId].confirmed !== undefined ? dataPackage.data[entry.paramId].confirmed : true;
                } else if (
                        entry.paramIdStart !== null &&
                        entry.paramIdStop !== null &&
                        dataPackage.data[entry.paramIdStart] &&
                        dataPackage.data[entry.paramIdStop]
                ) {
                    entry.valueStart = dataPackage.data[entry.paramIdStart].value;
                    entry.valueStop = dataPackage.data[entry.paramIdStop].value;
                }
            }
        });
    }

    private findChanges(changed, content) {
        if (content) {
            content.forEach((entry) => {
                if (entry.type === 'MENU') {
                    return this.findChanges(changed, entry.content);
                } else {
                    if (entry.changeTo !== null && entry.changeTo !== undefined && entry.value !== entry.changeTo) {
                        changed.push(entry);
                    }
                }
            });
            return changed;
        } else {
            console.error('Incorrect configuration, cannot determine content for this board');
            return
        }
    }

}
