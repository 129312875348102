import {HttpParams} from '@angular/common/http';

export const addToRequestOption = (req: any, httpParams: HttpParams): HttpParams => {
  if (req && httpParams) {
    Object.keys(req).forEach((key) => {
      if (key !== 'sort') {
        httpParams = httpParams.set(key, req[key]);
      }
    });
    if (req.sort) {
      req.sort.forEach((val: any) => {
        httpParams = httpParams.append('sort', val);
      });
    }
  }
  return httpParams;
};

export const createRequestOptionDontUseThat = (req?: any): HttpParams => {
    return addToRequestOption(req, new HttpParams());
};

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (req) {
    Object.keys(req).forEach((key) => {
      if (key !== 'sort') {
        options = options.set(key, req[key]);
      }
    });
    if (req.sort) {
      req.sort.forEach((val: any) => {
        options = options.append('sort', val);
      });
    }
  }
  return options;
};

export const printKSI = (): void => {
  console.warn('ksi');
};
