import {Routes} from '@angular/router';

import {passwordRoute, settingsRoute} from './';
import {messageRoute} from './message/message.route';

const ACCOUNT_ROUTES = [
    passwordRoute,
    settingsRoute,
    messageRoute
];

export const accountState: Routes = [{
    path: '',
    children: ACCOUNT_ROUTES
}];
