import {DatePipe} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
// tslint:disable-next-line:max-line-length
import {BalrogadminSharedCommonModule, BalrogadminSharedLibsModule, CSRFService, HasAnyAuthorityDirective, ModalService, StateStorageService, UserService} from './';

@NgModule({
    imports: [
        BalrogadminSharedLibsModule,
        BalrogadminSharedCommonModule
    ],
    declarations: [
        HasAnyAuthorityDirective
    ],
    providers: [
        StateStorageService,
        CSRFService,
        UserService,
        DatePipe,
        ModalService
    ],
    entryComponents: [],
    exports: [
        BalrogadminSharedCommonModule,
        HasAnyAuthorityDirective,
        DatePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class BalrogadminSharedModule {}
