import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {CORE_API_URL} from 'app/app.constants';
import {Observable} from 'rxjs';
import {createRequestOption} from 'app/shared';
import {NotificationModel, NotificationStatus} from 'app/management';

type EntityResponseType = HttpResponse<NotificationModel>;
type EntityArrayResponseType = HttpResponse<NotificationModel[]>;

@Injectable()
export class NotificationService {
    private resourceUrl =  CORE_API_URL + '/v1/notifications';

    constructor(private http: HttpClient) { }

    create(notification: NotificationModel): Observable<NotificationModel> {
        const copy = this.convert(notification);
        return this.http.post<NotificationModel>(this.resourceUrl, copy);
    }

    update(notification: NotificationModel): Observable<EntityResponseType> {
        const copy = this.convert(notification);
        return this.http.put(this.resourceUrl, copy, { observe: 'response' });
    }

    changeStatus(notificationId: string, status: NotificationStatus): Observable<NotificationModel> {
        return this.http.put<NotificationModel>(`${this.resourceUrl}/${notificationId}/status/${status}`, {});
    }

    find(id: string): Observable<NotificationModel> {
        return this.http.get<NotificationModel>(`${this.resourceUrl}/${id}`);
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<NotificationModel[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    /**
     * Convert a Message to a JSON which can be sent to the server.
     */
    private convert(notification: NotificationModel): NotificationModel {
        const copy: NotificationModel = Object.assign({}, notification);
        copy.createdDate = null;
        return copy;
    }
}
