import {BriefPanelName, PanelTypeEnum, Unit, VAxisType} from '@balrog/component/common';
import {ProfileInterface} from './interface/profile.interface';

export class MinisterZgProfile implements ProfileInterface {
    statuses = {
        POMPA_CO: { title: 'Pompa CO' },
        POMPA_CWU: { title: 'Pompa CWU' },
        PODAJNIK: { title: 'Podajnik' },
        DMUCHAWA: { title: 'Dmuchawa' },
        TERMOSTAT: { title: 'Termostat' },
        STYK_TERMOSTATU: { title: 'Styk termostatu' },
        GRZALKA: { title: 'Grzałka' },
        PRACA_KOTLA: { title: 'Praca kotła' }
    };

    // alarmy
    alarms = {
        CZUJNIK_CO: {title: 'Czujnik CO'},
        KOCIOL_PRZEGRZANY: {title: 'Kocioł przegrzany'},
        STB: {title: 'Stb'},
        STB_230V: {title: 'Stb 230V'},
        CZUJNIK_TEMP_SPALIN: {title: 'Czujnik temp. spalin'},
        ROZPALANIE_NIEUDANE: {title: 'Nieudane rozpalanie'},
        CZUJNIK_CWU: {title: 'Czujnik CWU'},
        CO_WARN: {title: 'Tlenek węgla przekroczył poziom ostrzegawczy'},
        CO_ALARM: {title: 'Tlenek węgla przekroczył poziom alarmowy'},
    };

    briefPanel = {
        name: BriefPanelName.ELEKTROMIZ_MINISTER_PELET
    };

    dashBoard = {
        panelType: PanelTypeEnum.ELEKTROMIZ_MINISTER_ZG
    };

    historyBoard = {
        title: 'Temperatura [°C]',
        content: [{
            paramId: 'TEMP_KOTLA',
            unit: Unit.C
        }, {
            paramId: 'NST_KOTLA',
            unit: Unit.C,
            type: 'stepped'
        }, {
            paramId: 'TEMP_BOILERA',
            unit: Unit.C
        }, {
            paramId: 'NST_BOILERA',
            unit: Unit.C,
            type: 'stepped'
        }, {
            paramId: 'TEMP_PODAJNIKA',
            unit: Unit.C
        }, {
            paramId: 'TEMP_SPALIN',
            unit: Unit.C
        }, {
            paramId: 'MOC_KOTLA',
            unit: Unit.PERCENTAGE,
            type: 'stepped',
            vAxisType: VAxisType.POWER_PERCENTAGE.valueOf()
        }, {
            paramId: 'TEMP_POKOJ',
            unit: Unit.C
        }, {
            paramId: 'ZUZYCIE_PALIWA_WCZORAJ'
        }, {
            paramId: 'CO',
            unit: Unit.PPM,
            vAxisType: VAxisType.PPM.valueOf()
        }]
    };

    listBoard = {
        title: 'Konfiguracja',
        content: [{
            paramId: 'NST_KOTLA',
            unit: Unit.C
        }, {
            paramId: 'NST_BOILERA',
            unit: Unit.C
        }, {
            paramId: 'TEMP_ZAWOR_1',
            unit: Unit.C
        }, {
            paramId: 'TEMP_PODAJNIKA',
            unit: Unit.C
        }, {
            paramId: 'NST_ZAWOR_1',
            unit: Unit.C
        }, {
            paramId: 'MOC_KOTLA',
            unit: Unit.PERCENTAGE
        }, {
            paramId: 'ZUZYCIE_PALIWA',
            step: 0.1
        }, {
            paramId: 'NST_ZUZYCIA_PALIWA',
            unit: Unit.KGH
        }, {
            paramId: 'ZUZYCIE_PALIWA_WCZORAJ',
            step: 0.1,
            unit: Unit.KGH
        }, {
            type: 'ITEM-TEXT',
            paramId: 'WERSJA_STEROWNIKA'
        }, {
            paramId: 'CO',
            unit: Unit.PPM
        }, {
            title: 'Parametry pracy',
            type: 'MENU',
            content: [{
                paramId: 'NST_CZAS_PRACA',
                step: 0.1,
                unit: Unit.SEC
            }, {
                paramId: 'NST_CZAS_PAUZA',
                unit: Unit.SEC
            }, {
                paramId: 'NST_LIMIT_DMUCHAWY',
                unit: Unit.PERCENTAGE
            }, {
                paramId: 'NST_MOC_DMUCHAWY_ROZPALANIE',
                unit: Unit.PERCENTAGE
            }, {
                paramId: 'NST_ROZPALANIE_CZAS',
                unit: Unit.MIN
            }, {
                paramId: 'NST_ROZPALANIE_CZAS_PRACA',
                unit: Unit.SEC
            }, {
                paramId: 'NST_TEMP_ZA_ZAWOR',
                unit: Unit.C
            }, {
                paramId: 'NST_TEMP_WL_POMPY_CO',
                unit: Unit.C
            }, {
                paramId: 'NST_HISTEREZA_CWU',
                unit: Unit.C
            }]
        }, {
            paramId: 'NOW_TIME',
            type: 'ITEM-TEXT',
            placeholder: 'HH:MM'
        }, {
            paramId: 'NOW_DATE',
            type: 'ITEM-TEXT',
            placeholder: 'yyyy-mm-dd'
        }]
    };

    scheduleBoard = null;
}
