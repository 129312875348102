import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';

import {BalrogadminSharedModule} from '../../shared';
import {
    DeviceAccessComponent,
    DeviceAccessDeleteDialogComponent,
    DeviceAccessDeletePopupComponent,
    DeviceAccessDetailComponent,
    DeviceAccessDialogComponent,
    DeviceAccessPopupComponent,
    deviceAccessPopupRoute,
    DeviceAccessPopupService,
    DeviceAccessResolvePagingParams,
    deviceAccessRoute,
} from './';

const ENTITY_STATES = [
    ...deviceAccessRoute,
    ...deviceAccessPopupRoute,
];

@NgModule({
    imports: [
        BalrogadminSharedModule,
        NgSelectModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        DeviceAccessComponent,
        DeviceAccessDetailComponent,
        DeviceAccessDialogComponent,
        DeviceAccessDeleteDialogComponent,
        DeviceAccessPopupComponent,
        DeviceAccessDeletePopupComponent,
    ],
    entryComponents: [
        DeviceAccessComponent,
        DeviceAccessDialogComponent,
        DeviceAccessPopupComponent,
        DeviceAccessDeleteDialogComponent,
        DeviceAccessDeletePopupComponent,
    ],
    providers: [
        DeviceAccessPopupService,
        DeviceAccessResolvePagingParams,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BalrogadminDeviceAccessModule {}
