import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from '@balrog/core/core.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CORE_API_URL} from 'app/app.constants';
import {BalrogadminManagementModule} from 'app/management/management.module';
import {JhiConfigService, JhiEventManager, missingTranslationHandler, translatePartialLoader} from 'ng-jhipster';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {BalrogadminAccountModule} from './account/account.module';
import {BalrogadminAdminModule} from './admin/admin.module';
import {BalrogadminAppRoutingModule} from './app-routing.module';
import {PaginationConfig} from './blocks/config/uib-pagination.config';
import {AuthExpiredInterceptor} from './blocks/interceptor/auth-expired.interceptor';
import {ErrorHandlerInterceptor} from './blocks/interceptor/errorhandler.interceptor';
import {NotificationInterceptor} from './blocks/interceptor/notification.interceptor';
import {BalrogadminEntityModule} from './entities/entity.module';
import {BalrogadminHomeModule} from './home/home.module';
import {ActiveMenuDirective, ErrorComponent, FooterComponent, JhiMainComponent, NavbarComponent, ProfileService} from './layouts';

import {BalrogadminSharedModule, UserRouteAccessService} from './shared';
import {WINDOW_PROVIDERS} from './shared/auth/window.provider';
import {BalrogadminUserModule} from './user/user.module';
import './vendor.ts';
import {BalrogadminWelcomeModule} from './welcome/welcome.module';

@NgModule({
    imports: [
        NgbModule,
        BrowserModule,
        BrowserAnimationsModule,
        BalrogadminAppRoutingModule,
        NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-'}),
        CoreModule.forRoot({coreApiUrl: CORE_API_URL, requiredRole: 'ROLE_ORG_USER'}),
        BalrogadminSharedModule,
        BalrogadminHomeModule,
        BalrogadminWelcomeModule,
        BalrogadminAdminModule,
        BalrogadminManagementModule,
        BalrogadminUserModule,
        BalrogadminAccountModule,
        BalrogadminEntityModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translatePartialLoader,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useFactory: missingTranslationHandler,
                deps: [JhiConfigService]
            }
        }),
    ],
    declarations: [
        JhiMainComponent,
        NavbarComponent,
        ErrorComponent,
        ActiveMenuDirective,
        FooterComponent
    ],
    providers: [
        ProfileService,
        PaginationConfig,
        UserRouteAccessService,
        // AccountService,
        // Principal,
        WINDOW_PROVIDERS,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: AuthInterceptor,
        //     multi: true,
        //     deps: [
        //         LocalStorageService,
        //         SessionStorageService
        //     ]
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [Injector]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
            deps: [
                JhiEventManager
            ]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true,
            deps: [
                Injector
            ]
        }
    ],
    bootstrap: [ JhiMainComponent ]
})
export class BalrogadminAppModule {}
