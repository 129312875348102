import { Component, OnInit } from '@angular/core';

import { Log } from './log.model';
import { LogsService } from './logs.service';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'jhi-logs',
    templateUrl: './logs.component.html',
})
export class LogsComponent implements OnInit {

    loggers: Log[];
    filter: string;
    orderProp: string;
    reverse: boolean;
    server: string;

    constructor(
        private logsService: LogsService
    ) {
        this.filter = '';
        this.orderProp = 'name';
        this.reverse = false;
    }

    ngOnInit() {
        this.logsService.findAll().subscribe((res: HttpResponse<Log[]>) => this.loggers = res.body);
    }

    changeLevel(name: string, level: string) {
        const log = new Log(name, level);
        this.logsService.changeLevel(log).subscribe(() => {
            this.logsService.findAll().subscribe((res: HttpResponse<Log[]>) => this.loggers = res.body);
        });
    }

    selectServer(serverName: string) {
        this.loggers = [];
        this.server = serverName;
        this.logsService.setServer(serverName);
        this.logsService.findAll().subscribe((res: HttpResponse<Log[]>) => this.loggers = res.body);
    }

}
