import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BalrogadminSharedModule } from '../shared';

import {
    userState,
    UserMgmtComponent,
    UserDialogComponent,
    UserDeleteDialogComponent,
    UserMgmtDetailComponent,
    UserMgmtDialogComponent,
    UserMgmtDeleteDialogComponent,
    UserResolvePagingParams,
    UserManagementDevicesComponent,
    UserManagementDevicesPopupComponent,
    UserModalService,
    UserDevicesResolver
} from './';
import {ModalService} from '../shared/modal/modal.service';
import {TableModule} from '../partial-components/table/table.module';
import {FilterTextModule} from '../partial-components/filters/filter-text/filter-text.module';

@NgModule({
    imports: [
        BalrogadminSharedModule,
        RouterModule.forChild(userState),
        TableModule,
        FilterTextModule,
        /* jhipster-needle-add-admin-module - JHipster will add admin modules here */
    ],
    declarations: [
        UserMgmtComponent,
        UserDialogComponent,
        UserDeleteDialogComponent,
        UserMgmtDetailComponent,
        UserMgmtDialogComponent,
        UserMgmtDeleteDialogComponent,
        UserManagementDevicesPopupComponent,
        UserManagementDevicesComponent
    ],
    entryComponents: [
        UserMgmtDialogComponent,
        UserMgmtDeleteDialogComponent,
        UserManagementDevicesComponent,
        UserManagementDevicesPopupComponent
    ],
    providers: [
        UserResolvePagingParams,
        UserModalService,
        ModalService,
        UserDevicesResolver
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BalrogadminUserModule {}
