import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CasLoginService} from '@balrog/core/auth';
import {BehaviorSubject, interval as observableInterval, Observable, Subscription} from 'rxjs';

import {startWith, switchMap} from 'rxjs/operators';
import {CORE_API_URL} from '../core.constants';
import {ChartData, DataPackage, Setpoint} from '../model/data';

@Injectable()
export class DataService {
    private readonly url = CORE_API_URL + '/v1/devices';

    private dataEvent = new BehaviorSubject<DataPackage>({data: { }});
    public readonly data$: Observable<DataPackage> = this.dataEvent.asObservable();
    private dataPulling: Subscription|null = null;

    constructor(
        private http: HttpClient,
        private loginService: CasLoginService) {
    }

  startDataPulling(serialNo: string) {
    if (!this.dataPulling) {
      this.dataPulling = observableInterval(6000)
        .pipe(
          startWith(0),
          switchMap(() => this.getDataOnce(serialNo))
        )
        .subscribe((data) => {
          this.dataEvent.next(data);
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.loginService.login();
            }
          }
        });
    }
  }

    stopDataPulling() {
      if (this.dataPulling) {
        this.dataPulling.unsubscribe();
        this.dataPulling = null;
      }
    }

    getDataOnce(serialNo: string): Observable<DataPackage> {
        return this.http.get<DataPackage>(this.url + '/data/' + serialNo);
    }

    historicalDataOnce(serialNo: string, params: string[], startDate: Date, endDate: Date): Observable<ChartData> {
        const request = {params, startDate, endDate};
        return this.http.post<ChartData>(this.url + '/data/' + serialNo + '/history', request);
    }

    sendSetpoint(serialNo: string, change: Setpoint[]) {
        return this.http.post(this.url + '/data/' + serialNo, change);
    }
}
