export enum VAxisType {
    'TEMP',
    'POWER_KW',
    'POWER_PERCENTAGE',
    'PERCENTAGE',
    'HOURS',
    'PPM',
    'POWER_W',
    'MS',
    'RPM',
    'DEGREE',
    'V',
}
