export class TimeUtil {
    static timeFromNow(date: string) {
        const min = (Date.now() - Date.parse(date)) / 1000 / 60;
        const d = new Date(date);
        const now = new Date();
        if (min < 1) {
            return 'teraz';
        }
        if (min < 60) {
            return Math.round(min) + ' min temu';
        }
        if (min < 60 * 4) {
            return Math.round(min / 60) + ' godzin temu';
        }
        if (min < 60 * 24 * 2 && d.getDay() === now.getDay()) {
            return 'Dzisiaj ' + TimeUtil.formatDate(d.getHours(), d.getMinutes());
        }
        if (min < 60 * 24 * 3 && d.getDay() === (new Date(Date.now() - 1000 * 3600 * 24)).getDay()) {
            return 'Wczoraj ' + TimeUtil.formatDate(d.getHours(), d.getMinutes());
        }
        return null;
    }

    static formatDate(hours: number, minutes: number) {
        return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
    }
}
