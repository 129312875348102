import {Device} from '.';
import {IOption} from '../option.interface';

/**
 * Created by krzysztof on 09.09.17.
 */

export class DeviceAccessExt {
    private static map: IOption[] = [{
        id: 0,
        name: 'Read'
    }, {
        id: 1,
        name: 'Basic manage'
    }, {
        id: 2,
        name: 'Manage'
    }, {
        id: 3,
        name: 'Admin'
    }];
    private static namedMap = {
        0: 'Read',
        1: 'Basic manage',
        2: 'Manage',
        3: 'Admin'
    };

  id: number;
  serialNo: string;
  name: string;
  role: number;
  device: Device;

  public static getOptions(): IOption[] {
    return DeviceAccessExt.map;
  }

  public static getOption(id: number): IOption {
    return DeviceAccessExt.map.find((option) => option.id === id);
  }

  public static getName(code: number): string {
    return this.namedMap[code];
  }
}
