import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from '../../app.constants';

@Injectable()
export class JhiMetricsService {

    serverName = '';

    constructor(private http: HttpClient) {}

    getMetrics(): Observable<any> {
        return this.http.get<any>(SERVER_API_URL + (this.serverName ? 'api/' + this.serverName + '/' : '') + 'management/metrics');
    }

    setServer(serverName: string) {
        this.serverName = serverName;
    }

    threadDump(): Observable<any> {
        return this.http.get<any>(SERVER_API_URL + (this.serverName ? 'api/' + this.serverName + '/' : '') + 'management/dump');
    }
}
