import { NgModule } from '@angular/core';

import {StatisticsDevicesComponent} from './statistics-devices.component';
import {BalrogadminSharedCommonModule} from '../../shared/shared-common.module';

@NgModule({
    imports: [
        BalrogadminSharedCommonModule
    ],
    declarations: [
        StatisticsDevicesComponent
    ],
    entryComponents: [
        StatisticsDevicesComponent
    ],
    exports: [
        StatisticsDevicesComponent
    ]
})
export class StatisticsDevicesModule {}
