import {NotificationOptions} from '@balrog/core/model/device';
import {BaseEntity} from './../../shared';

export class DeviceType implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public type?: string,
        public regulator?: string,
        public vendorCode?: string,
        public devices?: BaseEntity[],
        public deviceSchemaId?: number,
        public deviceSchemaName?: string,
        public groups?: BaseEntity[],
        public producerId?: number,
        public producerName?: number,
        public distributorId?: number,
        public distributorName?: number,
        public notificationOptions?: NotificationOptions
    ) {
        if (!notificationOptions) {
            this.notificationOptions = new NotificationOptions();
        }
    }
}
