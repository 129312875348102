import {NgModule} from '@angular/core';
import {DeviceDiagnosticComponent} from './device-diagnostic.component';
import {BalrogadminSharedModule} from '../../../shared/shared.module';
import {TableModule} from '../../table/table.module';

@NgModule({
    imports: [
        BalrogadminSharedModule,
        TableModule
    ],
    declarations: [
        DeviceDiagnosticComponent
    ],
    entryComponents: [
        DeviceDiagnosticComponent
    ],
    exports: [
        DeviceDiagnosticComponent
    ]
})
export class DeviceDiagnosticComponentModule {}
