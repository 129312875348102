import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DeviceEventListModule} from '@balrog/component/boards';
import {DeviceConfigurationListModule} from '@balrog/component/boards/device-configuration-list/device-configuration-list.module';
import {NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {ListConfigurationTabComponent} from 'app/entities/device/tabs/list-configuration-tab.component';
import {DeviceDiagnosticComponentModule} from '../../partial-components/device/device-diagnostic/device-diagnostic.module';
import {DeviceEventsLogComponentModule} from '../../partial-components/device/device-events-log/device-events-log.module';
import {DeviceStatusIndicatorModule} from '../../partial-components/device/device-status-indicator/device-status-indicator.module';
import {FilterTextModule} from '../../partial-components/filters/filter-text/filter-text.module';
import {ParameterListModule} from '../../partial-components/parameter-list/parameter-list.module';
import {TableModule} from '../../partial-components/table/table.module';

import {BalrogadminSharedModule} from '../../shared';
import {
    DeviceComponent,
    DeviceDeleteDialogComponent,
    DeviceDeletePopupComponent,
    DeviceDetailComponent,
    DeviceDialogComponent,
    DevicePopupComponent,
    devicePopupRoute,
    DevicePopupService,
    DeviceResolvePagingParams,
    deviceRoute,
    DeviceService,
    DeviceUsersComponent,
    DeviceUsersPopupComponent,
    DeviceUsersResolver
} from './';

const ENTITY_STATES = [
    ...deviceRoute,
    ...devicePopupRoute,
];

@NgModule({
    imports: [
        BalrogadminSharedModule,
        DeviceEventListModule,
        RouterModule.forChild(ENTITY_STATES),
        DeviceStatusIndicatorModule,
        TableModule,
        FilterTextModule,
        ParameterListModule,
        DeviceEventsLogComponentModule,
        DeviceDiagnosticComponentModule,
        DeviceConfigurationListModule,
        NgSelectModule,
        NgbTabsetModule
    ],
    declarations: [
        DeviceComponent,
        DeviceDetailComponent,
        DeviceDialogComponent,
        DeviceDeleteDialogComponent,
        DevicePopupComponent,
        DeviceDeletePopupComponent,
        DeviceUsersComponent,
        DeviceUsersPopupComponent,
        ListConfigurationTabComponent
    ],
    entryComponents: [
        DeviceComponent,
        DeviceDialogComponent,
        DevicePopupComponent,
        DeviceDeleteDialogComponent,
        DeviceDeletePopupComponent,
        DeviceUsersComponent,
        DeviceUsersPopupComponent
    ],
    providers: [
        DeviceService,
        DevicePopupService,
        DeviceResolvePagingParams,
        DeviceUsersResolver
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BalrogadminDeviceModule {}
