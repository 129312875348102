import {BriefPanelName, InteractiveButtonInterface, PanelTypeEnum, Unit, VAxisType} from '@balrog/component/common';
import {take} from 'rxjs/operators';
import {ProfileInterface} from './interface/profile.interface';

export class MinisterLfpWsterProfile implements ProfileInterface {
    statuses = {
      TRYB_SOLAR: { title: 'Tryb solar' },
      TRYB_KOMINEK: { title: 'Tryb kominek' },
      POMPA_PODLOGOWA: { title: 'Pompa podłogowa' },
      POMPA_CO: { title: 'Pompa CO' },
      POMPA_CWU: { title: 'Pompa CWU' },
      POMPA_KOM_SOLAR: { title: 'Pompa kominek/solar' },
      POMPA_CYRKULACYJNA: { title: 'Pompa cyrk.' },
      TERMOSTAT: { title: 'Termostat' },
      TERMOSTAT_PODLOGOWY: { title: 'Termostat podłogowy' },
      STYK_TERMOSTATU: { title: 'Styk termostatu' },
      STYK_TERMOSTATU_PODLOGOWY: { title: 'Styk termostatu podłogowy' },
      POMPA_CO_AKTYWNA: { title: 'Pompa CO Aktywna' },
      POMPA_CWU_AKTYWNA: { title: 'Pompa CWU Aktywna' },
      POMPA_PODLOGOWA_AKTYWNA: { title: 'Pompa podłogowa aktywna' },
      POMPA_CYRK_AKTYWNA: { title: 'Pompa cyrk. aktywna' }
    };

    // alarmy
    alarms = {
        CZUJNIK_CO: {title: 'Czujnik CO'},
        KOCIOL_PRZEGRZANY: {title: 'Kocioł przegrzany'},
        CWU_PRZEGRZANY: {title: 'Czujnik CWU przegrzany'},
        CZUJNIK_TEMP_KOLEKTORA: {title: 'Czujnik temp. kolektora'},
        CZUJNIK_TEMP_PODLOGOWEJ: {title: 'Czujnik temp. podłogowej'},
        CZUJNIK_CWU: {title: 'Czujnik CWU'},
        CO_WARN: {title: 'Tlenek węgla przekroczył poziom ostrzegawczy'},
        CO_ALARM: {title: 'Tlenek węgla przekroczył poziom alarmowy'},
    };

    briefPanel = {
        name: BriefPanelName.ELEKTROMIZ_LFP_WSTER
    };

    dashBoard = {
        panelType: PanelTypeEnum.ELEKTROMIZ_LFP_WSTER
    };

    historyBoard = {
        title: 'Temperatura [°C]',
        content: [{
            paramId: 'TEMP_KOTLA',
            unit: Unit.C
        }, {
            paramId: 'NST_KOTLA',
            unit: Unit.C,
            type: 'stepped'
        }, {
            paramId: 'TEMP_BOILERA',
            unit: Unit.C
        }, {
            paramId: 'NST_BOILERA',
            unit: Unit.C,
            type: 'stepped'
        }, {
            paramId: 'TEMP_KOLEKTOR',
            unit: Unit.C
        }, {
            paramId: 'CO',
            unit: Unit.PPM,
            vAxisType: VAxisType.PPM.valueOf()
        }]
    };

    listBoard = {
        title: 'Konfiguracja',
        content: [{
            paramId: 'NST_KOTLA',
            unit: Unit.C
        }, {
            paramId: 'NST_BOILERA',
            unit: Unit.C
        }, {
            paramId: 'TEMP_ZAWOR_1',
            unit: Unit.C
        }, {
            paramId: 'NST_ZAWOR_1',
            unit: Unit.C
        }, {
            type: 'ITEM-TEXT',
            paramId: 'WERSJA_STEROWNIKA'
        }, {
            paramId: 'CO',
            unit: Unit.PPM
        }, {
            paramId: 'NOW_TIME',
            type: 'ITEM-TEXT',
            placeholder: 'HH:MM'
        }, {
            type: 'ITEM-BUTTON',
            paramId: 'NOW_TIME',
            config: {
                defaultTitle: 'loading...',
                class: 'xLarge',
                diagramState: {
                    ENABLED: {
                        title: 'Synchronizuj',
                        onClick(event: InteractiveButtonInterface) {
                            const date = new Date();
                            const systemTime = date.getHours() + ':' + date.getMinutes();
                            event.sendConfiguration([{paramId: 'NOW_TIME', value: systemTime}]);
                            event.setState('WAITING');
                            setTimeout(() => event.setState('ENABLED'), 30000);
                        }
                    },
                    WAITING: {
                        title: 'Synchronizacja ...',
                        onClick() {}
                    }
                },
                init(event: InteractiveButtonInterface) {
                    event.data$.pipe(take(1)).subscribe((dataPackage) => {
                        // const date = new Date();
                        // const systemTime = date.getHours() + ":" + date.getMinutes();
                        event.setState('ENABLED');
                        // event.setTitle(systemTime);
                    });
                }
            }
        }, {
            paramId: 'NOW_DATE',
            type: 'ITEM-TEXT',
            placeholder: 'yyyy-mm-dd'
        }]
    };

    scheduleBoard = null;
}
