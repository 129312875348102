import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {CORE_API_URL} from '../app.constants';

@Injectable()
export class StatisticsService {

    constructor(private http: HttpClient) {}

    get() {
        return this.http.get(`${CORE_API_URL}/v1/account/statistic`, { observe: 'response' });
    }
}

export class StatisticsInterface {
    constructor(
        public title: string,
        public usersTotal?: number,
        public employees?: number,
        public users?: number,
        public activeUsers?: number,
        public devicesTotal?: number,
        public activeDevice?: number,
        public connectedDevice?: number,
        public disconnectedDevice?: number,
        public errorDevice?: number
    ) {}
}
