import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {concat, Observable, of, Subject, Subscription} from 'rxjs';
import {JhiEventManager} from 'ng-jhipster';

import {Group} from './group.model';
import {GroupService} from './group.service';
import {HttpResponse} from '@angular/common/http';
import {UserService} from '../../shared';
import {faArrowLeft, faPencilAlt, faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-group-detail',
    templateUrl: './group-detail.component.html'
})
export class GroupDetailComponent implements OnInit, OnDestroy {
    faPencilAlt = faPencilAlt;
    faArrowLeft = faArrowLeft;
    faPlus = faPlus;
    faTimes = faTimes;

    group: Group;
    private subscription: Subscription;
    private eventSubscriber: Subscription;
    userInput$ = new Subject<string>();
    userLoading: boolean;
    user$: Observable<{} | any[]>;
    selectedUser: any;

    constructor(
        private eventManager: JhiEventManager,
        private groupService: GroupService,
        private userService: UserService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        // this.userInput$.subscribe( (param) => {
        //     console.log(param);
        //     this.search(param);
        // })
        this.registerChangeInGroups();
        // this.search('');
        this.initUser();
    }

    load(id) {
        this.groupService.find(id).subscribe((res: HttpResponse<Group>) => {
            this.group = res.body;
        });
    }
    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInGroups() {
        this.eventSubscriber = this.eventManager.subscribe(
            'groupListModification',
            (response) => this.load(this.group.id)
        );
    }

    search(text) {
        return this.userService.query({
            query: text,
            page: 0,
            size: 20,
            sort: []}).pipe(map((resp) => {
            this.userLoading = false;
            return resp.body;
        }));
    }

    private initUser() {
        this.user$ = concat(
            of([]), // default items
            this.userInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => this.userLoading = true),
                switchMap((term) => this.search(term).pipe(
                    catchError(() => of([])), // empty list on error
                    tap(() => this.userLoading = false)
                ))
            )
        );
    }

    addUser() {
        this.group.users = [...this.group.users, this.selectedUser];
        this.save();
    }

    removeUser(rowIndex) {
        const users = this.group.users.splice(0);
        users.splice(rowIndex, 1);
        this.group.users = users;
        this.save();
    }

    save() {
        this.subscribeToSaveResponse(this.groupService.update(this.group));
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<Group>>) {
        result.subscribe((res: HttpResponse<Group>) => this.onSaveSuccess(res.body));
    }

    private onSaveSuccess(result: Group) {
        this.eventManager.broadcast({ name: 'groupListModification', content: 'OK'});
    }
}
