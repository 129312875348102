import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ParameterListModule} from 'app/partial-components/parameter-list/parameter-list.module';

import {BalrogadminSharedModule} from '../../shared';
import {
    DeviceTypeComponent,
    DeviceTypeDeleteDialogComponent,
    DeviceTypeDeletePopupComponent,
    DeviceTypeDetailComponent,
    DeviceTypeDialogComponent,
    DeviceTypePopupComponent,
    deviceTypePopupRoute,
    DeviceTypePopupService,
    DeviceTypeResolvePagingParams,
    deviceTypeRoute,
    DeviceTypeService,
} from './';

const ENTITY_STATES = [
    ...deviceTypeRoute,
    ...deviceTypePopupRoute,
];

@NgModule({
    imports: [
        BalrogadminSharedModule,
        ParameterListModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        DeviceTypeComponent,
        DeviceTypeDetailComponent,
        DeviceTypeDialogComponent,
        DeviceTypeDeleteDialogComponent,
        DeviceTypePopupComponent,
        DeviceTypeDeletePopupComponent,
    ],
    entryComponents: [
        DeviceTypeComponent,
        DeviceTypeDialogComponent,
        DeviceTypePopupComponent,
        DeviceTypeDeleteDialogComponent,
        DeviceTypeDeletePopupComponent,
    ],
    providers: [
        DeviceTypeService,
        DeviceTypePopupService,
        DeviceTypeResolvePagingParams,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BalrogadminDeviceTypeModule {}
