import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Principal} from '@balrog/core';
import {faClipboardList, faExchangeAlt, faEye, faInfoCircle, faPencilAlt, faPlus, faSort, faTimes, faUsers} from '@fortawesome/free-solid-svg-icons';
import {NotificationModel, NotificationService, NotificationStatus} from 'app/management';
import {NotificationPopupService} from 'app/management/notification/notification-popup.service';
import moment from 'moment/src/moment';
import {JhiAlertService, JhiEventManager, JhiParseLinks} from 'ng-jhipster';
import {Subscription} from 'rxjs';

import {ITEMS_PER_PAGE} from '../../../shared';

@Component({
    selector: 'jhi-notification-browse',
    templateUrl: 'notification-browse.component.html',
    styleUrls: ['notification-browse.component.scss']
})
export class NotificationBrowseComponent implements OnInit, OnDestroy {
    moment = moment;
    faUsers = faUsers;
    faEye = faEye;
    faPencilAlt = faPencilAlt;
    faTimes = faTimes;
    faPlus = faPlus;
    faSort = faSort;
    faClipboardList = faClipboardList;
    faExchangeAlt = faExchangeAlt;
    faInfoCircle = faInfoCircle;

    currentAccount: any;
    notifications: NotificationModel[];
    error: any;
    success: any;
    eventSubscriber: Subscription;
    routeData: any;
    links: any;
    totalItems: any;
    queryCount: any;
    itemsPerPage: any;
    page: any;
    predicate: any;
    previousPage: any;
    reverse: any;
    searchValue: string;

    constructor(
        private notificationService: NotificationService,
        private parseLinks: JhiParseLinks,
        private jhiAlertService: JhiAlertService,
        private principal: Principal,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private eventManager: JhiEventManager,
        private notificationPopupService: NotificationPopupService,
    ) {
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.routeData = this.activatedRoute.data.subscribe((data) => {
            this.page = data.pagingParams.page;
            this.previousPage = data.pagingParams.page;
            this.reverse = data.pagingParams.ascending;
            this.predicate = data.pagingParams.predicate;
            this.searchValue = data.pagingParams.searchText;
        });
    }

    filterHandler(searchValue) {
        this.searchValue = searchValue;
        this.page = 1;
        this.previousPage = 1;
        this.transition();
    }

    loadAll() {
        this.notificationService.query({
            page: this.page - 1,
            size: this.itemsPerPage,
            sort: this.sort()
        }).subscribe(
            (res: HttpResponse<any>) => this.onSuccess(res.body, res.headers),
            (res: HttpErrorResponse) => this.onError(res)
        );
    }

    loadPage(page: number) {
        if (page !== this.previousPage) {
            this.previousPage = page;
            this.transition();
        }
    }

    transition() {
        console.warn("transition");
        this.router.navigate(['/manage/notification'], {queryParams:
                {
                    page: this.page,
                    size: this.itemsPerPage,
                    sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc'),
                    searchText: this.searchValue
                }
        });
        this.loadAll();
    }

    clear() {
        this.page = 0;
        this.router.navigate(['/manage/notification', {
            page: this.page,
            sort: this.predicate + ',' + (this.reverse ? 'asc' : 'desc')
        }]);
        this.loadAll();
    }
    ngOnInit() {
        this.loadAll();
        this.principal.identity().then((account) => {
            this.currentAccount = account;
        });
        this.registerChangeInDevices();
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    trackId(index: number, item: NotificationModel) {
        return item.id;
    }
    registerChangeInDevices() {
        this.eventSubscriber = this.eventManager.subscribe('deviceListModification', (response) => this.loadAll());
    }

    sort() {
        const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    changeStatus(notification: NotificationModel) {
        this.notificationPopupService.openChangeStatusPopup(notification);
    }

    canChangeStatus(notification: NotificationModel) {
        return notification.status !== NotificationStatus.FINISHED && notification.status !== NotificationStatus.DELETED;
    }

    private onSuccess(data, headers) {
        this.links = this.parseLinks.parse(headers.get('link'));
        this.totalItems = headers.get('X-Total-Count');
        this.queryCount = this.totalItems;
        this.notifications = data;
    }

    private onError(error) {
        this.jhiAlertService.error(error.message, null, null);
    }
}
