import {BriefPanelName, PanelTypeEnum, Unit, VAxisType} from '@balrog/component/types';
import {ProfileInterface} from './interface/profile.interface';

// import {VAxisType} from '../../board/history-board/history-board.component';

export class SolarProfile implements ProfileInterface {
    statuses = {};

    // alarmy
    alarms = {
        TEMP_KOLEKTOR_ALARM: {title: 'Czujnik Temperatury kolektora'},
        PRZEGRZ_KOLEKTOR_ALARM: {title: 'kolektor przegrzany'},
        TEMP_BOJERA_ALARM: {title: 'czujnik temperatury boilera'},
        TEMP_ZBIORNIKA_ALARM: {title: 'czujnik temperatury z dodatkowego zbiornika'},
        STB_ALARM: {title: 'Stb 230V'}
    };

    briefPanel = {
        name: BriefPanelName.ELEKTROMIZ_SOLAR
    };

    dashBoard = {
        panelType: PanelTypeEnum.ELEKTROMIZ_SOLAR,
    };

    historyBoard = {
        title: 'Temperatura [°C]',
        content: [{
            paramId: 'TEMP_KOLEKTOR',
            unit: Unit.C
        }, {
            paramId: 'TEMP_BOJLER',
            unit: Unit.C
        }, {
            paramId: 'TEMP_ZBIORNIK',
            unit: Unit.C
        }, {
            paramId: 'MOC_CHWILOWA',
            unit: Unit.KW,
            vAxisType: VAxisType.POWER_KW.valueOf()
        }]
    };

    listBoard = {
        title: 'Konfiguracja',
        content: [{
            paramId: 'TEMP_KOLEKTOR',
            unit: Unit.C,
            step: 0.1
        }, {
            paramId: 'TEMP_BOJLER',
            unit: Unit.C,
            step: 0.1
        }, {
            paramId: 'TEMP_ZBIORNIK',
            unit: Unit.C,
            step: 0.1
        }, {
            paramId: 'TEMP_ZAWOR_1',
            unit: Unit.C,
            step: 0.1
        }, {
            paramId: 'PRZEPLYW'
        }, {
            paramId: 'MOC_POMPY',
            unit: Unit.PERCENTAGE
        }, {
            paramId: 'MOC_CHWILOWA',
            unit: Unit.KW,
            step: 0.1
        }, {
            paramId: 'NAST_ZAWOR_1'
        }, {
            paramId: 'MOC_CALKOWITA',
            unit: Unit.KWH,
            step: 0.001
        }, {
            paramId: 'NAST_DELTA_TEMP'
        }, {
            paramId: 'NAST_POMPY_3'
        }, {
            paramId: 'NAST_TEMP_ZRZUT_START',
            unit: Unit.C
        }, {
            paramId: 'NAST_TEMP_ZRZUT_KONIEC',
            unit: Unit.C
        }]
    };

    scheduleBoard = null;
}
