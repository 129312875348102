import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {CORE_CONFIG, CoreConfig} from '../core.config';
import {CORE_API_URL} from '../core.constants';
import {EventMsg} from '../model/device';

@Injectable()
export class EventService {

  private readonly coreApiUrl: string = CORE_API_URL;

  private resourceUrl = this.coreApiUrl + '/v1/events';

  constructor(private http: HttpClient,
              @Optional() @Inject(CORE_CONFIG) config: CoreConfig,
  ) {
    if (config) {
      this.coreApiUrl = config.coreApiUrl;
    }
  }

  query(serialNo: string, option: { page: number, size: number, excludeLevel: Array<string> }): Observable<HttpResponse<EventMsg[]>> {
    let params = new HttpParams();
    if (option.page) {
      params = params.append('page', String(option.page));
    }
    if (option.size) {
      params = params.append('size', String(option.size));
    }
    if (option.excludeLevel) {
      params = params.append('excludeLevel', String(option.excludeLevel.join(',')));
    }
    return this.http.get<EventMsg[]>(this.resourceUrl + '/' + serialNo, {params, observe: 'response'});
  }
}
