import { NgModule } from '@angular/core';
import { TableComponent } from './table.component';
import {BalrogadminSharedModule} from '../../shared/shared.module';

@NgModule({
    imports: [
        BalrogadminSharedModule
    ],
    declarations: [
        TableComponent
    ],
    entryComponents: [
        TableComponent
    ],
    exports: [
        TableComponent
    ]
})
export class TableModule {}
