import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {BalrogadminSharedModule} from '../shared';

import {accountState, PasswordComponent, PasswordService, PasswordStrengthBarComponent, SettingsComponent} from './';
import {MessageComponent} from './message/message.component';
import {MessageService} from './message/message.service';

@NgModule({
    imports: [
        BalrogadminSharedModule,
        RouterModule.forChild(accountState)
    ],
    declarations: [
        PasswordComponent,
        PasswordStrengthBarComponent,
        MessageComponent,
        SettingsComponent
    ],
    providers: [
        PasswordService,
        MessageService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BalrogadminAccountModule {}
