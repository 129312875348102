import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CORE_API_URL} from '../../app.constants';
import {createRequestOption} from '../../shared';

import {DeviceSchema} from './device-schema.model';

type EntityResponseType = HttpResponse<DeviceSchema>;
type EntityArrayResponseType = HttpResponse<DeviceSchema[]>;

@Injectable()
export class DeviceSchemaService {

    private resourceUrl =  CORE_API_URL + '/v1/device-schemas';

    constructor(private http: HttpClient) { }

    create(deviceSchema: DeviceSchema): Observable<EntityResponseType> {
        const copy = this.convert(deviceSchema);
        return this.http.post(this.resourceUrl, copy, { observe: 'response' });
    }

    update(deviceSchema: DeviceSchema): Observable<EntityResponseType> {
        const copy = this.convert(deviceSchema);
        return this.http.put(this.resourceUrl, copy, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<DeviceSchema[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    /**
     * Convert a DeviceSchemaModel to a JSON which can be sent to the server.
     */
    private convert(deviceSchema: DeviceSchema): DeviceSchema {
        return deviceSchema;
    }
}
