import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from '../../app.constants';

import { Log } from './log.model';

@Injectable()
export class LogsService {
    serverName = '';

    constructor(private http: HttpClient) { }

    changeLevel(log: Log): Observable<HttpResponse<any>> {
        return this.http.put(SERVER_API_URL + (this.serverName ? 'api/' + this.serverName + '/' : '') + 'management/logs', log, {observe: 'response'});
    }

    setServer(serverName: string) {
        this.serverName = serverName;
    }

    findAll(): Observable<HttpResponse<Log[]>> {
        return this.http.get<Log[]>(SERVER_API_URL + (this.serverName ? 'api/' + this.serverName + '/' : '') + 'management/logs', {observe: 'response'});
    }
}
