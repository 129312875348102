import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Principal} from '@balrog/core';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *jhiHasAuthority="'ROLE_ADMIN'">...</some-element>
 *
 * ```
 */
@Directive({
    selector: '[jhiHasAuthority]'
})
export class HasAnyAuthorityDirective {

    private authority: string;

    constructor(private principal: Principal, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {
    }

    @Input()
    set jhiHasAuthority(value: string) {
        this.authority = value;
        this.updateView();
        // Get notified each time authentication state changes.
        this.principal.getAuthenticationState().subscribe((identity) => this.updateView());
    }

    private updateView(): void {
        this.viewContainerRef.clear();

        if (!this.authority || this.principal.hasHierarchyAccess(this.authority)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}
