import { Route } from '@angular/router';
import { WelcomeComponent } from './';
import { UserRouteAccessService } from '../shared/auth/user-route-access-service';

export const WELCOME_ROUTE: Route = {
    path: 'start',
    component: WelcomeComponent,
    data: {
        authorities: ['ROLE_ORG_USER'],
        pageTitle: 'welcome.title'
    },
    canActivate: [UserRouteAccessService]
};
