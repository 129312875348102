import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';

import {DeviceSchema} from './device-schema.model';
import {DeviceSchemaPopupService} from './device-schema-popup.service';
import {DeviceSchemaService} from './device-schema.service';
import {faBan, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-device-schema-delete-dialog',
    templateUrl: './device-schema-delete-dialog.component.html'
})
export class DeviceSchemaDeleteDialogComponent {
    faTimes = faTimes;
    faBan = faBan;
    deviceSchema: DeviceSchema;

    constructor(
        private deviceSchemaService: DeviceSchemaService,
        public activeModal: NgbActiveModal,
        private eventManager: JhiEventManager
    ) {
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    confirmDelete(id: number) {
        this.deviceSchemaService.delete(id).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'deviceSchemaListModification',
                content: 'Deleted an deviceSchema'
            });
            this.activeModal.dismiss(true);
        });
    }
}

@Component({
    selector: 'jhi-device-schema-delete-popup',
    template: ''
})
export class DeviceSchemaDeletePopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private deviceSchemaPopupService: DeviceSchemaPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            this.deviceSchemaPopupService
                .open(DeviceSchemaDeleteDialogComponent as Component, params['id']);
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
