import {Component, Input, OnInit} from '@angular/core';
import {ConfigContentInterface} from '@balrog/profile';

@Component({
    selector: 'jhi-list-board-level',
    templateUrl: './list-board-level.component.html',
    styleUrls: ['./device-configuration-list.component.scss'],
    providers: []
})
export class ListBoardLevelComponent implements OnInit {
    @Input() content: Array<ConfigContentInterface>;

    ngOnInit(): void {
    }

}
