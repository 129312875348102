import {Component, OnInit} from '@angular/core';

import {GatewayRoutesService} from './gateway-routes.service';
import {GatewayRoute} from './gateway-route.model';
import {faSync} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-gateway',
    templateUrl: './gateway.component.html',
    providers: [ GatewayRoutesService ]
})
export class JhiGatewayComponent implements OnInit {
    faRefresh = faSync;

    gatewayRoutes: GatewayRoute[];
    updatingRoutes: Boolean;

    constructor(
        private gatewayRoutesService: GatewayRoutesService
    ) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.updatingRoutes = true;
        this.gatewayRoutesService.findAll().subscribe((gatewayRoutes) => {
            this.gatewayRoutes = gatewayRoutes;
            this.updatingRoutes = false;
        });
    }
}
