import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Routes} from '@angular/router';
import {JhiPaginationUtil} from 'ng-jhipster';

import {UserRouteAccessService} from '../../shared';
import {DeviceComponent} from './device.component';
import {DeviceDetailComponent} from './device-detail.component';
import {DevicePopupComponent} from './device-dialog.component';
import {DeviceDeletePopupComponent} from './device-delete-dialog.component';
import {DeviceUsersPopupComponent} from './device-users.component';
import {DeviceService} from './device.service';

@Injectable()
export class DeviceResolvePagingParams implements Resolve<any> {

    constructor(private paginationUtil: JhiPaginationUtil) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const page = route.queryParams['page'] ? route.queryParams['page'] : '1';
        const searchText = route.queryParams['searchText'] ? route.queryParams['searchText'] : '';
        const sort = route.queryParams['sort'] ? route.queryParams['sort'] : 'id,asc';
        return {
            page: this.paginationUtil.parsePage(page),
            predicate: this.paginationUtil.parsePredicate(sort),
            ascending: this.paginationUtil.parseAscending(sort),
            searchText
      };
    }
}

@Injectable()
export class DeviceUsersResolver implements Resolve<any> {

    constructor(private deviceService: DeviceService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.deviceService.getUserAccessesByDeviceId(route.params['id']);
    }
}

export const deviceRoute: Routes = [
    {
        path: 'device',
        component: DeviceComponent,
        resolve: {
            'pagingParams': DeviceResolvePagingParams
        },
        data: {
            authorities: ['ROLE_ORG_USER'],
            pageTitle: 'balrogadminApp.device.home.title'
        },
        canActivate: [UserRouteAccessService]
    }, {
        path: 'device/:id',
        component: DeviceDetailComponent,
        data: {
            authorities: ['ROLE_ORG_USER'],
            pageTitle: 'balrogadminApp.device.home.title'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const devicePopupRoute: Routes = [
    {
        path: 'device-new',
        component: DevicePopupComponent,
        data: {
            authorities: ['ROLE_ORG_MANAGER'],
            pageTitle: 'balrogadminApp.device.home.title'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'device/:id/edit',
        component: DevicePopupComponent,
        data: {
            authorities: ['ROLE_ORG_MANAGER'],
            pageTitle: 'balrogadminApp.device.home.title'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'device/:id/delete',
        component: DeviceDeletePopupComponent,
        data: {
            authorities: ['ROLE_ORG_MANAGER'],
            pageTitle: 'balrogadminApp.device.home.title'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    },
    {
        path: 'device/:id/users',
        component: DeviceUsersPopupComponent,
        resolve: {
            'accessesHttp': DeviceUsersResolver
        },
        data: {
            authorities: ['ROLE_ORG_MANAGER'],
            pageTitle: 'balrogadminApp.device.users.title'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
