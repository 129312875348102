import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { BalrogadminDeviceSchemaModule } from './device-schema/device-schema.module';
import { BalrogadminDeviceAccessModule } from './device-access/device-access.module';
import { BalrogadminDeviceModule } from './device/device.module';
import { BalrogadminMessageModule } from './message/message.module';
import { BalrogadminCountryModule } from './country/country.module';
import { BalrogadminRegionModule } from './region/region.module';
import { BalrogadminGroupModule } from './group/group.module';
import { BalrogadminOrganizationModule } from './organization/organization.module';
import { BalrogadminDeviceTypeModule } from './device-type/device-type.module';
import { BalrogadminCityModule } from './city/city.module';

@NgModule({
    imports: [
        BalrogadminDeviceSchemaModule,
        BalrogadminDeviceAccessModule,
        BalrogadminDeviceModule,
        BalrogadminMessageModule,
        BalrogadminCountryModule,
        BalrogadminRegionModule,
        BalrogadminCityModule,
        BalrogadminGroupModule,
        BalrogadminOrganizationModule,
        BalrogadminDeviceTypeModule,
    ],
    declarations: [],
    entryComponents: [],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BalrogadminEntityModule {}
