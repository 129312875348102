import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {JhiEventManager} from 'ng-jhipster';

import {Region} from './region.model';
import {RegionService} from './region.service';
import {HttpResponse} from '@angular/common/http';
import {faArrowLeft, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'jhi-region-detail',
    templateUrl: './region-detail.component.html'
})
export class RegionDetailComponent implements OnInit, OnDestroy {
    faPencilAlt = faPencilAlt;
    faArrowLeft = faArrowLeft;
    region: Region;
    private subscription: Subscription;
    private eventSubscriber: Subscription;

    constructor(
        private eventManager: JhiEventManager,
        private regionService: RegionService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.subscription = this.route.params.subscribe((params) => {
            this.load(params['id']);
        });
        this.registerChangeInRegions();
    }

    load(id) {
        this.regionService.find(id).subscribe((res: HttpResponse<Region>) => {
            this.region = res.body;
        });
    }
    previousState() {
        window.history.back();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInRegions() {
        this.eventSubscriber = this.eventManager.subscribe(
            'regionListModification',
            (response) => this.load(this.region.id)
        );
    }
}
