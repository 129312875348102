export class SetpointChangeModel {
    constructor(
        public paramId: string,
        public title: string,
        public baseValue: string,
        public sentValue: string,
        public readValue?: string,
        public confirmed?: boolean,
        public error?: boolean,
        public status?: string,
        public msg?: string,
    ) { }
}
