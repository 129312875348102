import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Principal} from '@balrog/core';
import {faBan, faLock, faSave, faUnlock} from '@fortawesome/free-solid-svg-icons';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiAlertService, JhiEventManager} from 'ng-jhipster';

import {Observable} from 'rxjs';
import {Country, CountryService} from '../country';
import {DeviceType, DeviceTypeService} from '../device-type';
import {Organization, OrganizationService} from '../organization';
import {Region, RegionService} from '../region';
import {DevicePopupService} from './device-popup.service';

import {Device} from './device.model';
import {DeviceService} from './device.service';
import {DeviceState} from './device.state.enum';

@Component({
    selector: 'jhi-device-dialog',
    templateUrl: './device-dialog.component.html'
})
export class DeviceDialogComponent implements OnInit {
    faBan = faBan;
    faSave = faSave;
    faLock = faLock;
    faUnlock = faUnlock;

    device: Device;
    isSaving: boolean;

    devicetypes: DeviceType[];

    organizations: Organization[];

    countries: Country[];

    regions: Region[];

    formAdvancedMode: boolean;
    deviceStates: typeof DeviceState = DeviceState;
    createdDateDp: any;
    modifiedDateDp: any;

    constructor(
        public activeModal: NgbActiveModal,
        private jhiAlertService: JhiAlertService,
        private deviceService: DeviceService,
        private deviceTypeService: DeviceTypeService,
        private organizationService: OrganizationService,
        private countryService: CountryService,
        private regionService: RegionService,
        private eventManager: JhiEventManager,
        private principal: Principal
    ) {
    }

    ngOnInit() {
        this.isSaving = false;
        this.deviceTypeService.query()
            .subscribe((res: HttpResponse<any>) => { this.devicetypes = res.body; }, (res: HttpErrorResponse) => this.onError(res));
        this.countryService.query()
            .subscribe((res: HttpResponse<any>) => { this.countries = res.body; }, (res: HttpErrorResponse) => this.onError(res));
        this.regionService.query()
            .subscribe((res: HttpResponse<any>) => { this.regions = res.body; }, (res: HttpErrorResponse) => this.onError(res));

        if (this.principal.hasHierarchyAccess('ROLE_ADMIN')) {
            this.organizationService.query()
                .subscribe((res: HttpResponse<any>) => { this.organizations = res.body; }, (res: HttpErrorResponse) => this.onError(res));
        }
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        if (this.device.id !== undefined) {
            this.subscribeToSaveResponse(
                this.deviceService.update(this.device));
        } else {
            this.subscribeToSaveResponse(
                this.deviceService.create(this.device));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<Device>>) {
        result.subscribe((res: HttpResponse<Device>) =>
            this.onSaveSuccess(res.body), (res: HttpErrorResponse) => this.onSaveError());
    }

    private onSaveSuccess(result: Device) {
        this.eventManager.broadcast({ name: 'deviceListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError() {
        this.isSaving = false;
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null);
    }

    toggleFormAdvancedMode() {
        this.formAdvancedMode = this.principal.hasHierarchyAccess('ROLE_ADMIN') ?
            !this.formAdvancedMode :
            false;
    }

    trackDeviceTypeById(index: number, item: DeviceType) {
        return item.id;
    }

    trackOrganizationById(index: number, item: Organization) {
        return item.id;
    }

    trackCountryById(index: number, item: Country) {
        return item.id;
    }

    trackRegionById(index: number, item: Region) {
        return item.id;
    }
}

@Component({
    selector: 'jhi-device-popup',
    template: ''
})
export class DevicePopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private devicePopupService: DevicePopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if ( params['id'] ) {
                this.devicePopupService
                    .open(DeviceDialogComponent as Component, params['id']);
            } else {
                this.devicePopupService
                    .open(DeviceDialogComponent as Component);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
