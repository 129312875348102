import { BaseEntity } from './../../shared';

export class Message implements BaseEntity {
    constructor(
        public id?: number,
        public title?: string,
        public body?: any,
        public level?: any,
        public createdDate?: any,
        public createdBy?: string,
        public deliverys?: BaseEntity[],
    ) {
    }
}
