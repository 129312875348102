import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CORE_API_URL } from '../../app.constants';

import { Message } from './message.model';
import { createRequestOption } from '../../shared';

type EntityResponseType = HttpResponse<Message>;
type EntityArrayResponseType = HttpResponse<Message[]>;

@Injectable()
export class MessageService {

    private resourceUrl =  CORE_API_URL + '/v1/messages';

    constructor(private http: HttpClient) { }

    create(message: Message): Observable<EntityResponseType> {
        const copy = this.convert(message);
        return this.http.post(this.resourceUrl, copy, { observe: 'response' });
    }

    update(message: Message): Observable<EntityResponseType> {
        const copy = this.convert(message);
        return this.http.put(this.resourceUrl, copy, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<Message[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<Object> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    /**
     * Convert a Message to a JSON which can be sent to the server.
     */
    private convert(message: Message): Message {
        const copy: Message = Object.assign({}, message);
        copy.createdDate = null;
        return copy;
    }
}
