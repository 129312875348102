import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DEVICE_ACCESS_RESOURCE_URL} from '@balrog/core';
import {Observable} from 'rxjs';
import {CORE_API_URL} from '../../app.constants';
import {DeviceDiagnostic} from '../../partial-components/device/device-diagnostic/device-diagnostic.component';
import {DeviceLog} from '../../partial-components/device/device-events-log/device-events-log.component';
import {createRequestOption} from '../../shared';
import {DeviceAccess} from '../device-access/device-access.model';

import {Device} from './device.model';

type EntityResponseType = HttpResponse<Device>;
type EntityArrayResponseType = HttpResponse<Device[]>;
type DeviceAccessArrayResponseType = HttpResponse<DeviceAccess[]>;

@Injectable()
export class DeviceService {

    private resourceUrl =  CORE_API_URL + '/v1/devices';
    private eventsResourceUrl =  CORE_API_URL + '/v1/events';

    constructor(private http: HttpClient) { }

    create(device: Device): Observable<EntityResponseType> {
        const copy = this.convert(device);
        return this.http.post(this.resourceUrl, copy, { observe: 'response' });
    }

    update(device: Device): Observable<EntityResponseType> {
        const copy = this.convert(device);
        return this.http.put(this.resourceUrl, copy, { observe: 'response' });
    }

    deviceFirmwareUpdate(device: Device, dev: boolean): Observable<EntityResponseType> {
        if (dev) {
            return this.http.get(`${this.resourceUrl}/${device.serialNo}/firmware/update?build=dev`, { observe: 'response' });
        } else {
            return this.http.get(`${this.resourceUrl}/${device.serialNo}/firmware/update?build=stable`, { observe: 'response' });
        }
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    getUserAccessesByDeviceId(id: number): Observable<DeviceAccessArrayResponseType> {
        return this.http.get<DeviceAccess[]>(`${DEVICE_ACCESS_RESOURCE_URL}?deviceId=${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<Device[]>(this.resourceUrl, { params: options, observe: 'response' });
    }

    delete(id: number, force: boolean): Observable<Object> {
        let queryString = "";
        if (force) {
            queryString = "?force=true"
        }
        return this.http.delete(`${this.resourceUrl}/${id}${queryString}`);
    }

    getDeviceLogs(serialNo): Observable<HttpResponse<DeviceLog[]>> {
        return this.http.get<DeviceLog[]>(`${this.eventsResourceUrl}/${serialNo}`, { observe: 'response' });
    }

    getDeviceDiagnostic(serialNo): Observable<HttpResponse<DeviceDiagnostic>> {
        return this.http.get<DeviceDiagnostic>(`${this.resourceUrl}/${serialNo}/diagnose`, { observe: 'response' });
    }

    /**
     * Convert a Device to a JSON which can be sent to the server.
     */
    private convert(device: Device): Device {
        const copy: Device = Object.assign({}, device);
        return copy;
    }
}
