import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AccountService} from '@balrog/core';
import {JhiAlertService, JhiLanguageService} from 'ng-jhipster';
import {Country} from '../../entities/country/country.model';
import {CountryService} from '../../entities/country/country.service';
import {Region} from '../../entities/region/region.model';
import {RegionService} from '../../entities/region/region.service';
import {JhiLanguageHelper} from '../../shared';

@Component({
    selector: 'jhi-settings',
    templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
    error: string;
    success: string;
    settingsAccount: any;
    languages: any[];

    countries: Country[];
    regions: Region[];

    constructor(
        private account: AccountService,
        private alertService: JhiAlertService,
        private languageService: JhiLanguageService,
        private languageHelper: JhiLanguageHelper,
        private countryService: CountryService,
        private regionService: RegionService
    ) {
    }

    ngOnInit() {
        this.languageHelper.getAll().then((languages) => {
            this.languages = languages;
        });

        this.countryService.query()
            .subscribe((res: HttpResponse<Country[]>) => { this.countries = res.body; }, (res: HttpErrorResponse) => this.onError(res));
        this.regionService.query()
            .subscribe((res: HttpResponse<Region[]>) => { this.regions = res.body; }, (res: HttpErrorResponse) => this.onError(res));

        this.account.get().subscribe((data) => {
            this.settingsAccount = this.copyFormData(data);
        });
    }

    save() {
        this.account.save(this.settingsAccount).subscribe(() => {
            this.error = null;
            this.success = 'OK';
            this.languageService.getCurrent().then((current) => {
                if (this.settingsAccount.langKey !== current) {
                    this.languageService.changeLanguage(this.settingsAccount.langKey);
                }
            });
        }, () => {
            this.success = null;
            this.error = 'ERROR';
        });
    }

    trackCountryById(index: number, item: Country) {
        return item.id;
    }

    trackRegionById(index: number, item: Region) {
        return item.id;
    }

    private onError(error) {
        this.alertService.error(error.error, error.message, null);
    }

    copyFormData(data) {
        return {
            login: data.login,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            address: data.address,
            langKey: data.langKey,
            postal: data.postal,
            city: data.city,
            phone: data.phone,
            countryId: data.countryId,
            regionId: data.regionId
        }
    }

}
