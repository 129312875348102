import {Component, Input, OnInit} from '@angular/core';
import {Device} from '../../../entities/device/device.model';
import {DeviceService} from '../../../entities/device/device.service';
import {HttpResponse} from '@angular/common/http';

@Component({
    selector: 'jhi-device-diagnostic',
    templateUrl: './device-diagnostic.component.html',
    styleUrls: [
        './device-diagnostic.scss'
    ]
})
export class DeviceDiagnosticComponent implements OnInit {

    @Input() device: Device;

    eventSummary: Array<{type: string, weekTotal: number, monthTotal: number, yearTotal: number}>;

    constructor(private deviceService: DeviceService) {}

    ngOnInit() {
        this.loadLogs();
    }

    loadLogs() {
        this.deviceService.getDeviceDiagnostic(this.device.serialNo).subscribe((res: HttpResponse<DeviceDiagnostic>) => {
            this.eventSummary = Object.keys(res.body.totalEventSummary).map((key) => res.body.totalEventSummary[key]);
        });
    }
}

export class DeviceDiagnostic {
    constructor(
        public totalEventSummary?: { [key: string]: {type: string, weekTotal: number, monthTotal: number, yearTotal: number}; },
    ) {
    }
}
