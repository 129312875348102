import {BriefPanelName} from '@balrog/component/types';
import {ProfileInterface} from './interface/profile.interface';

export class SafeitHumProfile implements ProfileInterface {
    statuses = {};

    alarms = {};

    briefPanel = {
        name: BriefPanelName.DEFAULT
    };

    dashBoard = {

    };

    historyBoard = {
        title: 'Temperatura [°C]',
        content: [{
            paramId: 'TEMP_POWIETRZA'
        }, {
            paramId: 'WILGOTNOSC'
        }, {
            paramId: 'CISNIENIE'
        }]
    };

    listBoard = null;

    scheduleBoard = null;
}
