import {HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MqttAdapterEnum, ProfileEnum} from '@balrog/profile';
import {faBan, faSave} from '@fortawesome/free-solid-svg-icons';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from 'ng-jhipster';

import {Observable} from 'rxjs';
import {DeviceSchemaPopupService} from './device-schema-popup.service';

import {DeviceSchema} from './device-schema.model';
import {DeviceSchemaService} from './device-schema.service';

@Component({
    selector: 'jhi-device-schema-dialog',
    templateUrl: './device-schema-dialog.component.html'
})
export class DeviceSchemaDialogComponent implements OnInit {
    faBan = faBan;
    faSave = faSave;

    deviceSchema: DeviceSchema;
    isSaving: boolean;
    adapterOptions: Array<string>;
    profileOptions: Array<string>;

    constructor(
        public activeModal: NgbActiveModal,
        private deviceSchemaService: DeviceSchemaService,
        private eventManager: JhiEventManager
    ) {
    }

    ngOnInit() {
        this.isSaving = false;
        this.adapterOptions = MqttAdapterEnum.names();
        this.profileOptions = ProfileEnum.names();
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        if (this.deviceSchema.id !== undefined) {
            this.subscribeToSaveResponse(
                this.deviceSchemaService.update(this.deviceSchema));
        } else {
            this.subscribeToSaveResponse(
                this.deviceSchemaService.create(this.deviceSchema));
        }
    }

    private subscribeToSaveResponse(result: Observable<HttpResponse<DeviceSchema>>) {
        result.subscribe((res: HttpResponse<DeviceSchema>) =>
            this.onSaveSuccess(res.body), (res: HttpResponse<any>) => this.onSaveError());
    }

    private onSaveSuccess(result: DeviceSchema) {
        this.eventManager.broadcast({ name: 'deviceSchemaListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError() {
        this.isSaving = false;
    }
}

@Component({
    selector: 'jhi-device-schema-popup',
    template: ''
})
export class DeviceSchemaPopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private deviceSchemaPopupService: DeviceSchemaPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if ( params['id'] ) {
                this.deviceSchemaPopupService
                    .open(DeviceSchemaDialogComponent as Component, params['id']);
            } else {
                this.deviceSchemaPopupService
                    .open(DeviceSchemaDialogComponent as Component);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
